/* eslint-disable no-new */
import React from 'react';
import GoogleMapReact from 'google-map-react';

import envConfig from '../../../../config/env';

import { createMarker } from './utils';

// Types

type Props = {
  lat: number;
  lng: number;
};

const SectionItemShowroomMap = ({ lat, lng }: Props) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: envConfig.googleMapsApiKey }}
      defaultCenter={{ lat, lng }}
      defaultZoom={17}
      onGoogleApiLoaded={({ map, maps }: { map: any; maps: any }) => createMarker({ googleMapsInstance: maps, lat, lng, mapInstance: map })}
      options={{ scrollwheel: false }}
      yesIWantToUseGoogleMapApiInternals={true}
    />
  );
};

export default SectionItemShowroomMap;
