import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';

// Types
import { SectionWidth } from '../../../../types/sections';

type ContetnWrapperProps = {
  contentPositionX: string;
  contentPositionY: string;
};

export const ContentWrapper = styled.div<ContetnWrapperProps>`
  ${styleUtils.padding.horizontal.bothDouble};
  ${styleUtils.padding.vertical.bothDouble};

  /* Flex Container */
  align-items: ${props => props.contentPositionX};
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.contentPositionY};

  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  ${styleUtils.media.orientation.portrait} {
    display: none;
  }
`;

export const RugPortraitWrapper = styled.div`
  ${styleUtils.padding.vertical.bottomHalf};

  /* Flex Container */
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  ${styleUtils.media.orientation.landscape} {
    display: none;
  }
`;

type SectionProps = {
  backgroundColor: string | null;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  /* Flex Container */
  align-items: center;
  flex-direction: column;

  padding: 0 !important;
  position: relative;

  ${styleUtils.media.tablet.small} {
    /* Flex Container */
    flex-direction: row;
  }

  ${styleUtils.media.orientation.portrait} {
    min-height: auto;
  }
`;
