import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';
import { decidePaddingLeftLandscapeSmall, decidePaddingRightLandscape, removeTopMarginIfMultipleChildren } from '../utils';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`;

export const EventsPortraitList = styled.ul`
  width: 100%;

  ${styleUtils.media.orientation.landscape} {
    display: none;
  }
`;

type SectionProps = {
  backgroundImage: FluidImage | null;
  backgroundColor: string | null;
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${decidePaddingLeftLandscapeSmall};
  ${decidePaddingRightLandscape};
  ${removeTopMarginIfMultipleChildren};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.bothQuadruple};

    /* Flex Container */
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.bothDouble};

    /* Flex Container */
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    ${styleUtils.specificity.increaseSpecificity} {
      min-height: auto !important;
    }
  }
`;
