import React from 'react';

import removeNewlines from '../../../../utils/string/removeNewlines';
import splitNewlineToBreak from '../../../../utils/string/splitNewlineToBreak';

import * as S from './styles';

// Types
import { SectionWidth } from '../../../../../types/sections';

type Props = {
  contentPositionY: string;
  hasBackgroundImage: boolean;
  sectionWidth: SectionWidth;
  text: string;
  textAlign: string;
  textColor: string;
};

const SectionItemContentFramedHeadline = ({ contentPositionY, hasBackgroundImage, sectionWidth, text, textAlign, textColor }: Props) => {
  const inlineText = removeNewlines(text);
  const splitText = splitNewlineToBreak(text, textAlign === 'end');

  return (
    <S.Wrapper contentPositionY={contentPositionY} isSectionContentExtraWide={false} sectionWidth={sectionWidth}>
      <S.Text isPortraitVariant={true} hasBackgroundImage={hasBackgroundImage} textAlign={textAlign} textColor={textColor}>
        {inlineText}
      </S.Text>

      <S.Text hasBackgroundImage={hasBackgroundImage} textAlign={textAlign} textColor={textColor}>
        {splitText}
      </S.Text>
    </S.Wrapper>
  );
};

export default SectionItemContentFramedHeadline;
