import React from 'react';
import { graphql } from 'gatsby';

import SectionItemContentHeadlineContainer from '../SectionItems/SectionItemContentHeadline';
import SectionNewsletterPressSubscription from '../../../components/Section/SectionNewsletterPressSubscription/SectionNewsletterPressSubscription';
import { getBackgroundImage, normalizeContentPositionX, normalizeContentPositionY } from '../utils';

// Types
import { SectionNewsletterPressSubscriptionContainerFragment } from './__generated__/SectionNewsletterPressSubscriptionContainerFragment';
import { SectionWidth } from '../../../../types/sections';

export const fragment = graphql`
  fragment SectionNewsletterPressSubscriptionContainerFragment on CMS_Section {
    ...SectionItemContentHeadlineContainerFragment
    backgroundColor
    backgroundPattern
    contentPositionX
    contentPositionY
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
  }
`;

type Props = {
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  isSectionContentExtraWide: boolean;
  section: SectionNewsletterPressSubscriptionContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionNewsletterPressSubContainer = ({
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  section,
  sectionWidth,
}: Props) => {
  const backgroundImage = getBackgroundImage(section);

  const contentPositionX = normalizeContentPositionX(section.contentPositionX);
  const contentPositionY = normalizeContentPositionY(section.contentPositionY);

  const headline = (
    <SectionItemContentHeadlineContainer contentPositionY={contentPositionY} hasBackgroundImage={!!backgroundImage} section={section} />
  );

  return (
    <SectionNewsletterPressSubscription
      backgroundColor={section.backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      headline={headline}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={sectionWidth}
    />
  );
};

export default SectionNewsletterPressSubContainer;
