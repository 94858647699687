import React from 'react';
import { graphql } from 'gatsby';

import GlobalDistributionRegionAustraliaContainer from './GlobalDistributionRegions/GlobalDistributionRegionAustralia';
import GlobalDistributionRegionEastAsiaContainer from './GlobalDistributionRegions/GlobalDistributionRegionEastAsia';
import GlobalDistributionRegionEasternEuropeContainer from './GlobalDistributionRegions/GlobalDistributionRegionEasternEurope';
import GlobalDistributionRegionMiddleEastContainer from './GlobalDistributionRegions/GlobalDistributionRegionMiddleEast';
import GlobalDistributionRegionNorthAmericaContainer from './GlobalDistributionRegions/GlobalDistributionRegionNorthAmerica';
import GlobalDistributionRegionSouthAmericaContainer from './GlobalDistributionRegions/GlobalDistributionRegionSouthAmerica';
import GlobalDistributionRegionWesternEuropeContainer from './GlobalDistributionRegions/GlobalDistributionRegionWesternEurope';
import GlobalDistributionWorldMap from '../../../components/Section/SectionGlobalDistribution/GlobalDistributionItems/GlobalDistributionWorldMap';
import SectionShowroomGlobalDistribution from '../../../components/Section/SectionGlobalDistribution';

// Types
import { CMS_ENUM_SHOWROOM_REGION } from '../../../../types/global';
import { SectionShowroomGlobalDistributionContainerFragment } from './__generated__/SectionShowroomGlobalDistributionContainerFragment';

export const fragment = graphql`
  fragment SectionShowroomGlobalDistributionContainerFragment on CMS_Section {
    backgroundColor
    backgroundPattern
    headlineColor
    textColor
  }
`;

type Props = {
  index: number;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  section: SectionShowroomGlobalDistributionContainerFragment;
};

const SectionShowroomGlobalDistributionContainer = ({ index, isCritical, isHiddenOnMobile, isNeighborClipped, section }: Props) => {
  const [activeRegion, setActiveRegion] = React.useState<CMS_ENUM_SHOWROOM_REGION | null>(null);

  const handleMouseEnter = (nextActiveRegion: CMS_ENUM_SHOWROOM_REGION) => {
    setActiveRegion(nextActiveRegion);
  };

  const handleMouseLeave = () => {
    setActiveRegion(null);
  };

  const australia = (
    <GlobalDistributionRegionAustraliaContainer
      activeRegion={activeRegion}
      headlineColor={section.headlineColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      textColor={section.textColor}
    />
  );
  const eastAsia = (
    <GlobalDistributionRegionEastAsiaContainer
      activeRegion={activeRegion}
      headlineColor={section.headlineColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      textColor={section.textColor}
    />
  );
  const easternEurope = (
    <GlobalDistributionRegionEasternEuropeContainer
      activeRegion={activeRegion}
      headlineColor={section.headlineColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      textColor={section.textColor}
    />
  );
  const middleEast = (
    <GlobalDistributionRegionMiddleEastContainer
      activeRegion={activeRegion}
      headlineColor={section.headlineColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      textColor={section.textColor}
    />
  );
  const northAmerica = (
    <GlobalDistributionRegionNorthAmericaContainer
      activeRegion={activeRegion}
      headlineColor={section.headlineColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      textColor={section.textColor}
    />
  );
  const southAmerica = (
    <GlobalDistributionRegionSouthAmericaContainer
      activeRegion={activeRegion}
      headlineColor={section.headlineColor}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      textColor={section.textColor}
    />
  );
  const westernEurope = (
    <GlobalDistributionRegionWesternEuropeContainer
      activeRegion={activeRegion}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      headlineColor={section.headlineColor}
      textColor={section.textColor}
    />
  );

  const worldMap = (
    <GlobalDistributionWorldMap
      australia={australia}
      eastAsia={eastAsia}
      easternEurope={easternEurope}
      hasActiveRegion={!!activeRegion}
      middleEast={middleEast}
      northAmerica={northAmerica}
      southAmerica={southAmerica}
      westernEurope={westernEurope}
    />
  );

  return (
    <SectionShowroomGlobalDistribution
      backgroundColor={section.backgroundColor}
      backgroundPattern={section.backgroundPattern}
      headlineColor={section.headlineColor}
      index={index}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      textColor={section.textColor}
      worldMap={worldMap}
    />
  );
};

export default SectionShowroomGlobalDistributionContainer;
