import React from 'react';

import FormCheckbox from '../../../Form/FormCheckbox';
import NewsletterSubscribeButton from '../../SectionItems/NewsletterSubscribeButton';
import SectionNewsletterSubscriptionPrivacyText from '../SectionNewsletterSubscriptionPrivacyText';
import routes from '../../../../config/routes';
import useToggle from '../../../../utils/hooks/useToggle';

import * as S from './styles';

// Types
import { FormEvent } from 'react';

const SectionNewsletterPressSubscriptionFormInput = () => {
  const [email, setEmail] = React.useState('');
  const [isChecked, toggleIsChecked] = useToggle(false);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };

  return (
    <S.Form onSubmit={handleSubmit}>
      <S.Input onChange={handleChange} placeholder="Email *" type="email" value={email} />

      <SectionNewsletterSubscriptionPrivacyText />

      <FormCheckbox
        isChecked={isChecked}
        label={
          <>
            I agree to&nbsp;
            <S.StyledLink asExternal={true} url={routes.privacyPolicyDe}>
              Datenschutz
            </S.StyledLink>
          </>
        }
        onClick={toggleIsChecked}
        title="You have to agree to the privacy policy to sign up"
      />

      <NewsletterSubscribeButton buttonText="Subscribe" disabled={!isChecked} />
    </S.Form>
  );
};

export default SectionNewsletterPressSubscriptionFormInput;
