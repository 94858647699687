import React from 'react';

import * as S from './styles';

// Types

import { SectionWidth } from '../../../../../types/sections';

type Props = {
  address: string;
  addressColor: string;
  cell: string | null;
  className?: string;
  contentPositionY: string;
  email: string | null;
  fax: string | null;
  hasBackgroundImage: boolean;
  hours: string | null;
  phone: string | null;
  sectionWidth: SectionWidth;
  textAlign: string;
  textColor: string;
};

const SectionShowroomMapAddress = ({
  address,
  addressColor,
  cell,
  className,
  contentPositionY,
  hasBackgroundImage,
  email,
  fax,
  hours,
  phone,
  sectionWidth,
  textAlign,
  textColor,
}: Props) => {
  return (
    <S.Address
      address={address}
      addressColor={addressColor}
      cell={cell}
      className={className}
      contentPositionY={contentPositionY}
      email={email}
      fax={fax}
      hasBackgroundImage={hasBackgroundImage}
      hours={hours}
      phone={phone}
      sectionWidth={sectionWidth}
      shouldNotIncludeHours={false}
      textAlign={textAlign}
      textColor={textColor}
    />
  );
};

export default SectionShowroomMapAddress;
