import css from '@emotion/css';

import styled from '../../../../../utils/styling/styled';

import MapPin from '../../../../SVG/MapPin';

type PinProps = {
  pinColor: string;
};

export const Pin = styled(MapPin)<PinProps>`
  cursor: pointer;
  width: 100%;

  #logo {
    fill: ${props => props.pinColor};
  }
`;

type WrapperProps = {
  isActiveRegion: boolean;
  hasActiveRegion: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  left: 44%;
  opacity: ${props => (!props.hasActiveRegion || props.isActiveRegion ? 1 : 0.6)};
  position: absolute;
  top: 12%;
  transform-origin: bottom center;
  transform: ${props => (!props.isActiveRegion ? css`translate(0,0);` : css`translate(0, -0.5rem);`)};
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  width: 4%;
`;
