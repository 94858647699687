import styled from '../../../../utils/styling/styled';

import Heading from '../../../Heading';
import createOmitProps from '../../../../utils/styling/createOmitProps';
import styleUtils from '../../../../utils/styling/styleUtils';
import { setTextShadow } from '../../utils';

// Types

type HeadlineProps = {
  hasBackgroundImage: boolean;
  textAlign: string;
  textColor: string;
};

export const Headline = styled(Heading, { shouldForwardProp: createOmitProps(['hasBackgroundImage', 'textAlign', 'textColor']) })<
  HeadlineProps
>`
  ${setTextShadow};
  ${styleUtils.font.weight.bold};

  color: ${props => props.textColor};
  line-height: 1;
  text-align: ${props => props.textAlign};
  text-transform: uppercase;
  white-space: pre-wrap;

  font-size: 2rem;

  ${styleUtils.media.tablet.large} {
    font-size: 3rem;
  }

  ${styleUtils.media.desktop.small} {
    font-size: 4rem;
  }
`;

export const Wrapper = styled.div`
  ${styleUtils.margin.vertical.bottomHalf};

  width: 100%;
`;
