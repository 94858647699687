import React from 'react';

import formatEventDate from '../../../../utils/time/formatEventDate';
import splitNewlineToBreak from '../../../../utils/string/splitNewlineToBreak';

import * as S from './styles';

// Types

import { SectionWidth } from '../../../../../types/sections';

type Props = {
  address: string;
  booth: string | null;
  cell: string | null;
  className?: string;
  contentPositionY: string;
  email: string | null;
  endDate: string | null;
  fax: string | null;
  hasBackgroundImage: boolean;
  hours: string | null;
  phone: string | null;
  sectionWidth: SectionWidth;
  startDate: string | null;
  textAlign: string;
  textColor: string;
};

const SectionItemContentMapAddress = ({
  address,
  booth,
  cell,
  className,
  contentPositionY,
  email,
  endDate,
  fax,
  hasBackgroundImage,
  hours,
  phone,
  sectionWidth,
  startDate,
  textAlign,
  textColor,
}: Props) => {
  const splitAddress = splitNewlineToBreak(address);
  const splitHours = !!hours ? splitNewlineToBreak(hours) : null;
  const formattedEventDate = !!endDate && !!startDate ? formatEventDate({ endDate, startDate }) : null;

  return (
    <S.Wrapper className={className} contentPositionY={contentPositionY} isSectionContentExtraWide={false} sectionWidth={sectionWidth}>
      {(!!booth || !!formattedEventDate) && (
        <S.DateWrapper hasBackgroundImage={hasBackgroundImage} textColor={textColor}>
          {!!formattedEventDate && <S.ContactRow>{formattedEventDate}</S.ContactRow>}
        </S.DateWrapper>
      )}

      <S.Address hasBackgroundImage={hasBackgroundImage} textAlign={textAlign} textColor={textColor}>
        {splitAddress}

        {!!booth && <S.ContactRow>{booth}</S.ContactRow>}
      </S.Address>

      <S.SectionWrapper hasBackgroundImage={hasBackgroundImage} textColor={textColor}>
        {!!cell && <S.ContactRow>C: {cell}</S.ContactRow>}

        {!!phone && <S.ContactRow>T: {phone}</S.ContactRow>}

        {!!fax && <S.ContactRow>F: {fax}</S.ContactRow>}

        {!!email && <S.ContactRowLink url={`mailto:${email}`}>{email}</S.ContactRowLink>}
      </S.SectionWrapper>

      {!!splitHours && (
        <S.SectionWrapper hasBackgroundImage={hasBackgroundImage} textColor={textColor}>
          <S.ContactRow>Opening Hours:</S.ContactRow>
          <S.ContactRow>{splitHours}</S.ContactRow>
        </S.SectionWrapper>
      )}
    </S.Wrapper>
  );
};

export default SectionItemContentMapAddress;
