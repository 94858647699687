import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';

// Types
import { SectionWidth } from '../../../../../types/sections';

export const Name = styled.p<{ isActive: boolean }>`
  ${styleUtils.font.weight.bold};

  font-size: 1.8rem;
  line-height: 1.3;
  text-align: start;
  text-transform: uppercase;
  white-space: pre-wrap;
  cursor: pointer;

  color: ${props => (props.isActive ? props.theme.color.green : 'white')};

  &:hover {
    text-decoration: underline;
  }
`;

type WrapperProps = {
  contentPositionY: string;
  isSectionContentExtraWide: boolean;
  sectionWidth: SectionWidth;
};

export const Wrapper = styled.address<WrapperProps>`
  ${styleUtils.margin.vertical.top};

  z-index: 1;

  ${styleUtils.media.orientation.landscape} {
    width: 100%;
  }

  ${styleUtils.media.orientation.portrait} {
    width: 100%;
  }
`;
