import { addDays, isAfter, parseISO } from 'date-fns';

type FilterPastEventsEvent = {
  endDate: string;
};

export const filterPastEvents = (event: FilterPastEventsEvent) => {
  const parsedEndDate = parseISO(event.endDate);

  // If the event is ending the day we run a build, we still want to show it.
  return !isAfter(Date.now(), addDays(parsedEndDate, 1));
};
