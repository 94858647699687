import React from 'react';

import { NO_SCROLL_ID } from '../../../../config/constants';

import * as S from './styles';

type Props = {
  variant?: 'showroom' | 'partner';
};

const SectionStoreLocatorMapMarker = ({ variant = 'showroom' }: Props) => {
  switch (variant) {
    case 'partner': {
      return (
        <S.Marker id={NO_SCROLL_ID} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 581.997 829.241" variant={variant}>
          <path
            fill="#fff"
            d="M575.793 290.998c0 261.922-284.795 529.729-284.795 529.729S6.203 552.92 6.203 290.998C6.203 133.71 133.71 6.203 290.998 6.203S575.793 133.71 575.793 290.998Z"
          />
          <path
            d="m290.999 829.241-4.25-3.996c-.716-.673-72.46-68.514-143.196-167.557-41.594-58.24-74.749-115.762-98.547-170.965C15.141 417.442 0 351.592 0 290.999 0 130.541 130.541 0 290.999 0s290.999 130.541 290.999 290.999c0 60.593-15.141 126.443-45.005 195.725-23.798 55.203-56.953 112.725-98.547 170.965-70.736 99.043-142.48 166.884-143.196 167.557l-4.25 3.996Zm0-816.835c-153.617.001-278.592 124.976-278.592 278.593 0 133.588 76.733 269.117 141.105 359.287 60.212 84.343 121.132 145.808 137.487 161.816 16.355-16.008 77.275-77.473 137.487-161.816 64.372-90.17 141.105-225.699 141.105-359.287 0-153.616-124.975-278.592-278.592-278.592Z"
            fill="#e4003e"
          />
          <path d="M399.088 214.992h54.928v54.93h-54.928z" fill="#e4003e" />
          <path
            d="M454.207 270.113h-55.309v-55.312h55.309v55.312Zm-54.929-.382h54.549v-54.548h-54.549v54.548Zm-229.254-88.807v85.156c0 21.606-9.214 31.138-26.375 31.138-13.343 0-20.969-6.989-27.007-14.774l9.532-6.197c3.654 5.879 10.01 10.169 17.158 10.169 7.787 0 13.982-3.813 13.982-16.999v-88.493h12.711Zm15.724 114.706 35.272-114.706h18.906l35.269 114.706h-13.346l-10.802-35.906h-41.15l-10.802 35.906h-13.346Zm27.328-46.71h34.794l-16.523-59.1h-1.748l-16.523 59.1Zm79.114 46.71V180.924h18.265l50.043 88.334h.638v-88.334h11.437V295.63h-10.485l-58.147-102.314h-.318V295.63h-11.435ZM137.21 438.12h-12.711V323.415h12.711v59.576h.318l47.502-59.576h15.095l-35.59 43.213 39.879 71.492h-14.46l-34.155-61.006-18.589 22.561v38.445Zm74.667 0 35.272-114.705h18.906l35.269 114.705h-13.347l-10.802-35.904h-41.15l-10.802 35.904h-13.346Zm27.328-46.708h34.794l-16.523-59.1h-1.749l-16.522 59.1Zm98.732-57.195v103.902h-12.708V334.217h-34.322v-10.802h81.346v10.802h-34.316Zm127.418-10.802V438.12h-12.711v-54.65h-54.968v54.65h-12.711V323.415h12.711v49.251h54.968v-49.251h12.711Z"
            fill="#e4003e"
          />
        </S.Marker>
      );
    }

    case 'showroom': {
      return (
        <S.Marker id={NO_SCROLL_ID} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 581.997 829.241" variant={variant}>
          <path
            fill="#e4003e"
            d="M578.948 289.478c0 264.907-288.041 535.767-288.041 535.767S2.866 554.385 2.866 289.478c0-159.081 128.96-288.041 288.041-288.041s288.041 128.96 288.041 288.041Z"
          />
          <path d="M400.23 212.605h55.555v55.556H400.23z" fill="white" />
          <path
            d="M455.978 268.355h-55.941v-55.943h55.941v55.943Zm-55.554-.386h55.168v-55.17h-55.168v55.17Zm-231.87-89.82v86.127c0 21.853-9.319 31.493-26.676 31.493-13.495 0-21.208-7.069-27.315-14.943l9.64-6.267c3.696 5.946 10.124 10.285 17.354 10.285 7.875 0 14.141-3.857 14.141-17.193v-89.502h12.856Zm15.903 116.014 35.674-116.014h19.122l35.671 116.014h-13.499L250.5 257.848h-41.619l-10.926 36.315h-13.499Zm27.64-47.242h35.191l-16.711-59.774h-1.768l-16.711 59.774Zm80.015 47.242V178.149h18.473l50.614 89.341h.646v-89.341h11.568v116.014h-10.604l-58.81-103.481h-.321v103.481h-11.565ZM135.366 438.277H122.51V322.265h12.856v60.256h.321l48.044-60.256h15.267l-35.995 43.705 40.334 72.307h-14.625l-34.545-61.701-18.801 22.818v38.883Zm75.518 0 35.674-116.012h19.122l35.671 116.012h-13.499l-10.926-36.313h-41.619l-10.926 36.313h-13.499Zm27.64-47.24h35.191l-16.711-59.774h-1.769l-16.711 59.774Zm99.858-57.847v105.087h-12.853V333.19h-34.713v-10.926h82.273v10.926h-34.707Zm128.87-10.926v116.012h-12.856v-55.273h-55.594v55.273h-12.856V322.264h12.856v49.812h55.594v-49.812h12.856Z"
            fill="white"
          />
        </S.Marker>
      );
    }
  }
};

export default SectionStoreLocatorMapMarker;
