import React from 'react';

import SectionPressDownloadFlag from './SectionPressDownloadFlag';
import StaticPageText from '../../StaticPageItems/StaticPageText';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../../types/global';
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type Props = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  check: boolean;
  contentBackground: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  sectionWidth: SectionWidth;
  shouldHaveNoPadding?: boolean;
  textColor: string;
};

const SectionPressDownload = ({
  backgroundColor,
  backgroundImage,
  backgroundPattern,
  contentPositionX,
  contentPositionY,
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  sectionWidth,
}: Props) => {
  return (
    <>
      <S.Section
        backgroundColor={backgroundColor}
        backgroundImage={backgroundImage}
        backgroundPattern={backgroundPattern}
        contentPositionX={contentPositionX}
        contentPositionY={contentPositionY}
        index={index}
        isClipped={isClipped}
        isCritical={isCritical}
        isHiddenOnMobile={isHiddenOnMobile}
        isNeighborClipped={isNeighborClipped}
        sectionWidth={sectionWidth}
      >
        <S.Background />

        <S.Content>
          <S.Headline>Presseheft</S.Headline>

          <StaticPageText>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Tortor posuere ac ut consequat semper viverra nam libero. Bibendum ut tristique et egestas. Bibendum ut tristique et egestas
            quis ipsum suspendisse ultrices. Condimentum id venenatis a condimentum vitae sapien pellentesque habitant morbi. Id consectetur
            purus ut faucibus pulvinar. Orci dapibus ultrices in iaculis nunc sed. Ac turpis egestas maecenas pharetra convallis posuere
            morbi leo urna. Viverra maecenas accumsan lacus vel facilisis volutpat est velit egestas. Est lorem ipsum dolor sit amet
            consectetur adipiscing elit pellentesque. Blandit aliquam etiam erat velit scelerisque.
          </StaticPageText>

          <SectionPressDownloadFlag />
        </S.Content>
      </S.Section>
    </>
  );
};

export default SectionPressDownload;
