import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';
import { removeTopMarginIfMultipleChildren } from '../utils';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type SectionProps = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
  shouldHaveNoPadding?: boolean;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${removeTopMarginIfMultipleChildren};

  /* Flex Container */
  align-items: ${props => props.contentPositionX};
  justify-content: ${props => props.contentPositionY};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.vertical.topDouble};
    ${styleUtils.padding.vertical.bottom};

    ${styleUtils.specificity.increaseSpecificity} {
      ${props => (props.shouldHaveNoPadding ? `padding: 0;` : ` `)}
    }
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.both};
    ${styleUtils.padding.vertical.bottom};
    ${styleUtils.padding.vertical.topQuadruple};

    align-items: flex-start;
    flex-direction: column;

    ${styleUtils.media.tablet.small} {
      align-items: center;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.rightDouble};
    ${styleUtils.padding.vertical.bottomDouble};
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.vertical.bottom};
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: 0.75rem;

  ${styleUtils.media.orientation.landscape} {
  }

  ${styleUtils.media.orientation.portrait} {
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.vertical.topDouble};
    ${styleUtils.padding.vertical.bottom};
  }

  ${styleUtils.media.orientation.portrait} {
    align-items: flex-start;
    flex-direction: column;
  }
`;
