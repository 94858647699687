import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';
import { removeTopMarginIfMultipleChildren } from '../utils';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

export const Content = styled.div`
  margin-left: auto;
  margin-right: auto;

  width: 100%;

  ${styleUtils.media.orientation.landscape} {
    max-width: 50vw;

    ${styleUtils.media.desktop.large} {
      max-width: 40vw;
    }
  }

  ${styleUtils.media.orientation.portrait} {
    max-width: 90vw;
  }
`;

type SectionProps = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${removeTopMarginIfMultipleChildren};

  /* Flex Container */
  align-items: ${props => props.contentPositionX};
  flex-direction: column;
  justify-content: ${props => props.contentPositionY};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.vertical.topDouble};
    ${styleUtils.padding.vertical.bottom};
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.bothDouble};

    /* Flex Container */
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    ${styleUtils.media.tablet.small} {
      align-items: ${props => props.contentPositionX};
    }
  }
`;

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
`;

export const Title = styled.h1`
  color: white;
  font-size: 3rem;
  margin-bottom: -2rem;
  text-transform: uppercase;
`;
