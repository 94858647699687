import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import SectionRugFullHeightRug from '../../../../components/Section/SectionRugFullHeight/SectionRugFullHeightRug';
import buildCollectionOverviewRugLink from '../../../../utils/url/buildCollectionOverviewRugLink';

// Types
import { SectionRugFullHeightRugContainerFragment } from './__generated__/SectionRugFullHeightRugContainerFragment';

export const fragment = graphql`
  fragment SectionRugFullHeightRugContainerFragment on CMS_Rug {
    id
    name
    collection_jk {
      collectionPage {
        slug
      }
    }
    picture {
      ...SectionRugFullHeightRugPictureFragment
    }
  }
`;

type Props = {
  isClipped?: boolean | null;
  isCritical: boolean;
  isNeighborClipped?: boolean | null;
  rug: SectionRugFullHeightRugContainerFragment;
};

const SectionRugFullHeightRugContainer = ({ isClipped, isCritical, isNeighborClipped, rug }: Props) => {
  const picture = idx(rug, _ => _.picture.file.childImageSharp.fluid);
  const slug = idx(rug, _ => _.collection_jk.collectionPage.slug);

  if (!picture) {
    return null;
  }

  const url = !!slug ? buildCollectionOverviewRugLink({ rugId: rug.id, slug }) : null;

  return (
    <SectionRugFullHeightRug
      isClipped={isClipped}
      isCritical={isCritical}
      isNeighborClipped={isNeighborClipped}
      name={rug.name}
      picture={picture}
      url={url}
    />
  );
};

export default SectionRugFullHeightRugContainer;
