import React from 'react';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const FlagChina = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 30 30" {...props}>
      <defs>
        <path id="a" fill="#ffde00" d="M0-1L.588.809-.952-.309H.952L-.588.809 0-1z" />
      </defs>
      <path fill="none" d="M-1-1h3.986v3.986H-1z" />
      <g>
        <path fill="#de2910" stroke="null" d="M0 .081h30v29.863H0V.081z" />
        <g stroke="null">
          <use x="1.785" y="2.703" transform="matrix(4.30357 0 0 4.30357 3.355 2.795)" xlinkHref="#a" />
          <use x="6.642" y="9.367" transform="rotate(23.036 22.004 33.083) scale(1.43452)" xlinkHref="#a" />
          <use x="4.842" y="9.414" transform="rotate(45.87 14.618 29.948) scale(1.43452)" xlinkHref="#a" />
          <use x="5.018" y="10.144" transform="rotate(69.945 12.177 25.846) scale(1.43452)" xlinkHref="#a" />
          <use x="4.994" y="8.643" transform="rotate(20.66 -8.617 46.584) scale(1.43452)" xlinkHref="#a" />
        </g>
      </g>
    </svg>
  );
};

export default FlagChina;
