import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import SectionHomeCollection from '../../../components/Section/SectionHomeCollection';
import SectionItemCollectionLogoContainer from '../SectionItems/SectionItemCollectionLogo';
import buildCollectionPageUrl from '../../../utils/url/buildCollectionPageUrl';
import { getBackgroundImage, normalizeContentPositionX, normalizeContentPositionY } from '../utils';

// Types
import { SectionHomeCollectionContainerFragment } from './__generated__/SectionHomeCollectionContainerFragment';
import { SectionWidth } from '../../../../types/sections';

export const fragment = graphql`
  fragment SectionHomeCollectionContainerFragment on CMS_Section {
    ...SectionItemCollectionLogoContainerFragment
    backgroundColor
    backgroundPattern
    contentPositionX
    contentPositionY
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
    collectionPageLink {
      slug
      collection_jk {
        name
      }
    }
    contentPageLink {
      slug
    }
    textColor
  }
`;

type Props = {
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  isSectionContentExtraWide: boolean;
  section: SectionHomeCollectionContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionHomeCollectionContainer = ({
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  isSectionContentExtraWide,
  section,
  sectionWidth,
}: Props) => {
  const backgroundColor = section.backgroundColor;
  const backgroundImage = getBackgroundImage(section);
  const collectionName = idx(section, _ => _.collectionPageLink.collection_jk.name);
  const slug = idx(section, _ => _.collectionPageLink.slug) || idx(section, _ => _.contentPageLink.slug) || null;

  if ((!backgroundColor && !backgroundImage) || !slug) {
    return null;
  }

  const contentPositionX = normalizeContentPositionX(section.contentPositionX);
  const contentPositionY = normalizeContentPositionY(section.contentPositionY);
  const textColor = section.textColor;

  const url = !!collectionName ? buildCollectionPageUrl(slug) : `/${slug}`;

  const logo = (
    <SectionItemCollectionLogoContainer
      alt={!!collectionName ? `Logo of ${collectionName} collection` : ''}
      contentPositionY={contentPositionY}
      isCritical={isCritical}
      isSectionContentExtraWide={isSectionContentExtraWide}
      section={section}
      sectionWidth={sectionWidth}
    />
  );

  return (
    <SectionHomeCollection
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      logo={logo}
      name={collectionName}
      sectionWidth={sectionWidth}
      textColor={textColor}
      url={url}
    />
  );
};

export default SectionHomeCollectionContainer;
