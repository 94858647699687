import React from 'react';

import Heading from '../../../Heading';

import * as S from './styles';

// Types
import { FluidImage } from '../../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

type Props = {
  caption?: string | null;
  isClipped?: boolean | null;
  isCritical: boolean;
  isNeighborClipped?: boolean | null;
  name: string;
  picture: FluidImage;
  url: string | null;
};

const SectionRugFullHeightRug = ({ caption, isClipped, isCritical, isNeighborClipped, name, picture, url }: Props) => {
  return (
    <>
      <Heading isVisuallyHidden={true}>{name}</Heading>
      <S.Wrapper>
        <S.Picture
          alt={`Picture of a ${name} rug`}
          caption={caption}
          fadeIn={!isCritical}
          image={picture}
          isClipped={isClipped}
          loading={isCritical ? 'eager' : undefined}
        />

        {!!url && <S.Button isClipped={isClipped} isNeighborClipped={isNeighborClipped} url={url} />}
      </S.Wrapper>{' '}
    </>
  );
};

export default SectionRugFullHeightRug;
