import React from 'react';
import { graphql } from 'gatsby';

import SectionItemContentBackgroundContainer from '../SectionItems/SectionItemContentBackground';
import SectionItemContentHeadlineContainer from '../SectionItems/SectionItemContentHeadline';
import SectionItemContentTextContainer from '../SectionItems/SectionItemContentText';
import SectionPressDownload from '../../../components/Section/SectionPressDownload';
import { getBackgroundImage, normalizeContentPositionX, normalizeContentPositionY } from '../utils';

// Types
import { SectionPressDownloadContainerFragment } from './__generated__/SectionPressDownloadContainerFragment';
import { SectionWidth } from '../../../../types/sections';

export const fragment = graphql`
  fragment SectionPressDownloadContainerFragment on CMS_Section {
    ...SectionItemContentBackgroundContainerFragment
    ...SectionItemContentHeadlineContainerFragment
    ...SectionItemContentLogoContainerFragment
    ...SectionItemContentTextContainerFragment
    backgroundColor
    backgroundPattern
    contentPositionX
    contentPositionY
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
  }
`;

type Props = {
  check: boolean;
  headline: string;
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  isSectionContentExtraWide: boolean;
  section: SectionPressDownloadContainerFragment;
  sectionWidth: SectionWidth;
  text: string;
  textColor: string;
};

const SectionPressDownloadContainer = ({
  check,
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  section,
  sectionWidth,
  textColor,
}: Props) => {
  const backgroundImage = getBackgroundImage(section);

  const contentPositionX = normalizeContentPositionX(section.contentPositionX);
  const contentPositionY = normalizeContentPositionY(section.contentPositionY);

  const contentBackground = <SectionItemContentBackgroundContainer section={section} sectionWidth={sectionWidth} />;

  return (
    <SectionPressDownload
      backgroundColor={section.backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      check={check}
      contentBackground={contentBackground}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={sectionWidth}
      textColor={textColor}
    />
  );
};

export default SectionPressDownloadContainer;
