import React from 'react';

import Section from '../../Heading/Section';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../../types/global';
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';

type Props = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  contentBackground: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  headline: ReactNode;
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  rug: ReactNode;
  rugPortrait: ReactNode;
  text: ReactNode;
};

const SectionRugFullHeight = ({
  backgroundColor,
  backgroundImage,
  backgroundPattern,
  contentBackground,
  contentPositionX,
  contentPositionY,
  headline,
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  rug,
  rugPortrait,
  text,
}: Props) => {
  const renderContent = () => {
    if (contentPositionY === 'flex-end') {
      return (
        <>
          {text}
          {headline}
        </>
      );
    }

    return (
      <>
        {headline}
        {text}
      </>
    );
  };

  return (
    <S.Section
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={50}
      shouldNotAutoHeight={true}
      shouldNotMakeWiderIfNeighborIsClipped={true}
    >
      <Section hasNoTag={true}>
        {rug}

        <S.RugPortraitWrapper>{rugPortrait}</S.RugPortraitWrapper>

        <S.ContentWrapper contentPositionX={contentPositionX} contentPositionY={contentPositionY}>
          {contentBackground}

          {renderContent()}
        </S.ContentWrapper>
      </Section>
    </S.Section>
  );
};

export default SectionRugFullHeight;
