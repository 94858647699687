import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';

type NoEventsMessageProps = {
  textColor: string;
};

export const NoEventsMessage = styled.p<NoEventsMessageProps>`
  ${styleUtils.font.weight.bold};

  color: ${props => props.textColor};
  text-transform: uppercase;
  font-size: 1.4rem;
  text-align: center;
`;

export const NoEventsMessageWrapper = styled.div`
  width: 50%;
`;

type TableProps = {
  isClipped: boolean;
  isNeighborClipped: boolean;
  textColor: string;
};

export const Table = styled.table<TableProps>`
  color: ${props => props.textColor};
  text-transform: uppercase;
  width: ${props => (props.isClipped || props.isNeighborClipped ? '100%' : '75%')};

  ${styleUtils.media.orientation.portrait} {
    display: none;
  }
`;

export const TableBody = styled.tbody``;

export const TableHeader = styled.thead``;

export const TableHeaderCell = styled.th`
  ${styleUtils.font.weight.bold};

  font-size: 1.2rem;
  text-align: center;

  &:first-of-type {
    text-align: start;
  }

  &:last-of-type {
    text-align: end;
  }
`;

export const TableHeaderRow = styled.tr`
  border-bottom: 1px solid ${props => props.theme.color.white40};
`;
