import React from 'react';
import { compareAsc, parseISO } from 'date-fns';
import { graphql, useStaticQuery } from 'gatsby';

import SectionEventsEvents from '../../../components/Section/SectionEventsEvents';
import SectionEventsEventsEventContainer from './SectionEventsEventsEvent';
import SectionEventsEventsHeadlineContainer from './SectionEventsEventsHeadline';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';
import { filterPastEvents } from './utils';
import { getBackgroundImage, normalizeContentPositionX, normalizeContentPositionY } from '../utils';

// Types
import { SectionEventsEventsContainerFragment } from './__generated__/SectionEventsEventsContainerFragment';
import { SectionWidth } from '../../../../types/sections';
import { ShowsEventsPageContainerEventsQuery } from './__generated__/ShowsEventsPageContainerEventsQuery';

export const fragment = graphql`
  fragment SectionEventsEventsContainerFragment on CMS_Section {
    ...SectionEventsEventsHeadlineContainerFragment
    backgroundColor
    backgroundPattern
    contentPositionX
    contentPositionY
    textColor
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
  }
`;

type Props = {
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  section: SectionEventsEventsContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionEventsEventsContainer = ({
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  section,
  sectionWidth,
}: Props) => {
  const data = useStaticQuery<ShowsEventsPageContainerEventsQuery>(graphql`
    query ShowsEventsPageContainerEventsQuery {
      cms {
        events {
          ...SectionEventsEventsEventContainerFragment
          id
          startDate
        }
      }
    }
  `);

  const backgroundImage = getBackgroundImage(section);

  if (!data || !data.cms.events || (!section.backgroundColor && !backgroundImage)) {
    return null;
  }

  const contentPositionX = normalizeContentPositionX(section.contentPositionX);
  const contentPositionY = normalizeContentPositionY(section.contentPositionY);

  const filteredEvents = data.cms.events
    .filter(notNullOrUndefined)
    .filter(filterPastEvents)
    .sort((a, b) => compareAsc(parseISO(a.startDate), parseISO(b.startDate)));

  const events = filteredEvents.map(event => <SectionEventsEventsEventContainer event={event} key={event.id} />);
  const eventsPortrait = filteredEvents.map(event => (
    <SectionEventsEventsEventContainer event={event} isPortraitVariant={true} key={event.id} />
  ));

  const headline = <SectionEventsEventsHeadlineContainer hasBackgroundImage={!!backgroundImage} section={section} />;

  return (
    <SectionEventsEvents
      backgroundColor={section.backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      events={events}
      eventsPortrait={eventsPortrait}
      headline={headline}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={sectionWidth}
      textColor={section.textColor}
    />
  );
};

export default SectionEventsEventsContainer;
