import styled from '../../../../../../../utils/styling/styled';

import Link from '../../../../../../Link';
import styleUtils from '../../../../../../../utils/styling/styleUtils';
import createOmitProps from '../../../../../../../utils/styling/createOmitProps';

type NameProps = {
  textColor: string;
};

export const Name = styled.span<NameProps>`
  ${styleUtils.font.shadow.default};
  ${styleUtils.font.weight.bold};

  color: ${props => props.textColor};
  white-space: nowrap;
`;

type NameLinkProps = {
  textColor: string;
};

export const NameLink = styled(Link, { shouldForwardProp: createOmitProps(['textColor']) })<NameLinkProps>`
  ${styleUtils.font.shadow.default};
  ${styleUtils.font.weight.bold};

  color: ${props => props.textColor};
  white-space: nowrap;
`;

export const Store = styled.li`
  ${styleUtils.margin.horizontal.rightHalf};
  ${styleUtils.margin.vertical.bottomQuarter};
`;
