import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';
import { decidePaddingRightLandscape } from '../utils';

export const TextWrapper = styled.div`
  ${styleUtils.media.orientation.landscape} {
    width: 20%;
  }
`;

type SectionProps = {
  backgroundColor: string;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isNeighborClipped?: boolean;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${decidePaddingRightLandscape};

  /* Flex Container */
  align-items: center;
  flex-direction: column;
  justify-content: space-around;

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.both};

    flex-direction: row;
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.bothDouble};

    align-items: flex-start;
  }
`;
