import styled from '../../../../utils/styling/styled';
import styleUtils from '../../../../utils/styling/styleUtils';

export const TableCell = styled.td`
  &:not(:first-of-type) {
    ${styleUtils.padding.vertical.bothHalf};
  }

  font-size: 1rem;
  text-align: center;

  &:first-of-type {
    text-align: start;
  }

  &:last-of-type {
    text-align: end;
  }
`;

export const TableRow = styled.tr`
  ${styleUtils.font.weight.regular};

  border-bottom: 1px solid ${props => props.theme.color.white40};
`;
