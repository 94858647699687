import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import SectionItemShowroomAddress from '../../../../components/Section/SectionItems/SectionItemShowroomAddress';
import { normalizeTextAlign } from '../../utils';

// Types
import { SectionItemShowroomAddressContainerFragment } from './__generated__/SectionItemShowroomAddressContainerFragment';

export const fragment = graphql`
  fragment SectionItemShowroomAddressContainerFragment on CMS_Section {
    headlineColor
    text
    textAlign
    textColor
    showroomPage {
      showroom {
        address
        cell
        email
        fax
        hours
        phone
      }
    }
  }
`;

type Props = {
  contentPositionY: string;
  hasBackgroundImage: boolean;
  section: SectionItemShowroomAddressContainerFragment;
  shouldNotIncludeHours?: boolean;
  textAlign?: string;
};

const SectionItemShowroomAddressContainer = ({
  contentPositionY,
  hasBackgroundImage,
  section,
  shouldNotIncludeHours = false,
  textAlign,
}: Props) => {
  const address = idx(section, _ => _.showroomPage.showroom.address);

  if (typeof address !== 'string') {
    return null;
  }

  const cell = idx(section, _ => _.showroomPage.showroom.cell) || null;
  const email = idx(section, _ => _.showroomPage.showroom.email) || null;
  const fax = idx(section, _ => _.showroomPage.showroom.fax) || null;
  const hours = idx(section, _ => _.showroomPage.showroom.hours) || null;
  const phone = idx(section, _ => _.showroomPage.showroom.phone) || null;

  return (
    <SectionItemShowroomAddress
      address={address}
      addressColor={section.headlineColor}
      cell={cell}
      contentPositionY={contentPositionY}
      email={email}
      fax={fax}
      hasBackgroundImage={hasBackgroundImage}
      hours={hours}
      phone={phone}
      sectionWidth={100}
      shouldNotIncludeHours={shouldNotIncludeHours}
      textAlign={textAlign || normalizeTextAlign(section.textAlign)}
      textColor={section.textColor}
    />
  );
};

export default SectionItemShowroomAddressContainer;
