import styled from '../../../utils/styling/styled';

import Link from '../../Link';
import NavigationCollectionChevron from '../../SVG/NavigationCollectionChevron';
import ScrollSection from '../../ScrollSection';
import animation from '../../../utils/styling/animation';
import createOmitProps from '../../../utils/styling/createOmitProps';
import styleUtils from '../../../utils/styling/styleUtils';
import { decidePaddingLeftLandscape, decidePaddingRightLandscape, removeTopMarginIfMultipleChildren } from '../utils';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type SectionProps = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${removeTopMarginIfMultipleChildren};
  ${decidePaddingLeftLandscape};
  ${decidePaddingRightLandscape};

  /* Flex Container */
  align-items: ${props => props.contentPositionX};
  flex-direction: column;
  justify-content: ${props => props.contentPositionY};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.bothQuadruple};
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.both};
  }
`;

export const ViewCollectionChevron = styled(NavigationCollectionChevron)`
  width: 0.6rem;
`;

type ViewCollectionLinkProps = {
  textColor: string;
};

export const ViewCollectionLink = styled(Link, { shouldForwardProp: createOmitProps(['textColor']) })<ViewCollectionLinkProps>`
  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: row;

  color: ${props => props.textColor};

  /* This animates the wrapped SVG, so we animate on hover of the whole thing, not just the icon. */
  ${animation.viewCollectionChevron}
`;

export const ViewCollectionText = styled.span`
  ${styleUtils.font.weight.bold};

  font-size: 1.2rem;
  margin-right: 0.5rem;
  text-transform: uppercase;
`;
