import React from 'react';
import { graphql } from 'gatsby';

import SectionCarouselCollectionsContainer from './SectionCarouselCollections';
import SectionCarouselShowroomsContainer from './SectionCarouselShowrooms';
import SectionCollectionTextIntroContainer from './SectionCollectionTextIntro';
import SectionContentDefaultContainer from './SectionContentDefault';
import SectionContentFramedContainer from './SectionContentFramed';
import SectionContentMapContainer from './SectionContentMap';
import SectionEventContactContainer from './SectionEventContact';
import SectionEventsEventsContainer from './SectionEventsEvents';
import SectionFooterContactContainer from './SectionFooterContact';
import SectionFooterShowroomsContainer from './SectionFooterShowrooms';
import SectionFullScreenWalkthroughContainer from './SectionFullScreenWalkthrough';
import SectionHomeCollectionContainer from './SectionHomeCollection';
import SectionHomeContentPageTeaserContainer from './SectionHomeContentPageTeaser';
import SectionHomeEventTeaserContainer from './SectionHomeEventTeaser';
import SectionHomeShowroomsContainer from './SectionHomeShowrooms';
import SectionNewsletterPressSubContainer from './SectionNewsletterPressSubscription';
import SectionPressContactContainer from './SectionPressContact';
import SectionPressDownloadContainer from './SectionPressDownload';
import SectionProductContainer from './SectionProduct';
import SectionRugFullHeightContainer from './SectionRugFullHeight';
import SectionRugListContainer from './SectionRugList';
import SectionShowroomGlobalDistributionContainer from './SectionShowroomGlobalDistribution';
import SectionShowroomIntroContainer from './SectionShowroomIntro';
import SectionShowroomMapContainer from './SectionShowroomMap';
import SectionStoreLocatorContainer from './SectionStoreLocator';
import SectionVideoFullScreenContainer from './SectionVideoFullScreen';
import SectionVideoFullScreenVimeoContainer from './SectionVideoFullScreenVimeo';
import { normalizeSectionWidth } from './utils';

// Types
import { CMS_ENUM_SECTION_TYPE } from '../../../types/global';
import { SectionContainerFragment } from './__generated__/SectionContainerFragment';

export const fragment = graphql`
  fragment SectionContainerFragment on CMS_Section {
    ...SectionCollectionTextIntroContainerFragment
    ...SectionContentDefaultContainerFragment
    ...SectionContentFramedContainerFragment
    ...SectionContentMapContainerFragment
    ...SectionEventContactContainerFragment
    ...SectionEventsEventsContainerFragment
    ...SectionFooterContactContainerFragment
    ...SectionFooterShowroomsContainerFragment
    ...SectionFullScreenWalkthroughContainerFragment
    ...SectionHomeCollectionContainerFragment
    ...SectionHomeContentPageTeaserContainerFragment
    ...SectionHomeShowroomsContainerFragment
    ...SectionNewsletterPressSubscriptionContainerFragment
    ...SectionPressContactContainerFragment
    ...SectionPressDownloadContainerFragment
    ...SectionProductContainerFragment
    ...SectionRugFullHeightContainerFragment
    ...SectionRugListContainerFragment
    ...SectionShowroomIntroContainerFragment
    ...SectionShowroomMapContainerFragment
    ...SectionStoreLocatorContainerFragment
    ...SectionVideoFullScreenContainerFragment
    ...SectionVideoFullScreenVimeoContainerFragment
    contentPositionY
    isClipped
    isContentExtraWide
    isHiddenOnMobile
    type
    width
  }
`;

type Props = {
  check: boolean;
  headline: string;
  index: number;
  isCritical: boolean;
  isNeighborClipped: boolean;
  section: SectionContainerFragment;
  text: string;
  textColor: string;
};

const SectionContainer = ({ check, headline, index, isCritical, isNeighborClipped, section, text, textColor }: Props) => {
  const isClipped = section.isClipped || false;
  const isHiddenOnMobile = section.isHiddenOnMobile || false;
  const isSectionContentExtraWide = section.isContentExtraWide || false;

  const sectionWidth = normalizeSectionWidth(section.width);

  switch (section.type) {
    case CMS_ENUM_SECTION_TYPE.CAROUSEL_COLLECTIONS:
      return <SectionCarouselCollectionsContainer isHiddenOnMobile={isHiddenOnMobile} />;

    case CMS_ENUM_SECTION_TYPE.CAROUSEL_SHOWROOMS:
      return <SectionCarouselShowroomsContainer isHiddenOnMobile={isHiddenOnMobile} />;

    case CMS_ENUM_SECTION_TYPE.COLLECTION_TEXT_INTRO:
      return (
        <SectionCollectionTextIntroContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
        />
      );

    case CMS_ENUM_SECTION_TYPE.CONTENT_DEFAULT:
      return (
        <SectionContentDefaultContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          isSectionContentExtraWide={isSectionContentExtraWide}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.CONTENT_MAP:
      return (
        <SectionContentMapContainer
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.CONTENT_FRAMED:
      return (
        <SectionContentFramedContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.EVENTS_CONTACT:
      return (
        <SectionEventContactContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          isSectionContentExtraWide={isSectionContentExtraWide}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.EVENTS_EVENTS:
      return (
        <SectionEventsEventsContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.NEWSLETTER_SUBSCRIPTION_PRESS:
      return (
        <SectionNewsletterPressSubContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          isSectionContentExtraWide={isSectionContentExtraWide}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.FOOTER_CONTACT:
      return <SectionFooterContactContainer isHiddenOnMobile={isHiddenOnMobile} section={section} />;

    case CMS_ENUM_SECTION_TYPE.FOOTER_SHOWROOMS:
      return <SectionFooterShowroomsContainer isHiddenOnMobile={isHiddenOnMobile} section={section} />;

    case CMS_ENUM_SECTION_TYPE.FULL_SCREEN_WALKTHROUGH:
      return (
        <SectionFullScreenWalkthroughContainer
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
        />
      );

    case CMS_ENUM_SECTION_TYPE.HOME_COLLECTION:
      return (
        <SectionHomeCollectionContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          isSectionContentExtraWide={isSectionContentExtraWide}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.HOME_CONTENT_PAGE_TEASER:
      return (
        <SectionHomeContentPageTeaserContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.HOME_EVENT_TEASER:
      return (
        <SectionHomeEventTeaserContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          isSectionContentExtraWide={isSectionContentExtraWide}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.HOME_SHOWROOMS:
      return (
        <SectionHomeShowroomsContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.PRESS_CONTACT:
      return (
        <SectionPressContactContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          isSectionContentExtraWide={isSectionContentExtraWide}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.PRESS_DOWNLOAD_COMPANY_INFO:
      return (
        <SectionPressDownloadContainer
          check={check}
          headline={headline}
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          isSectionContentExtraWide={isSectionContentExtraWide}
          section={section}
          sectionWidth={sectionWidth}
          text={text}
          textColor={textColor}
        />
      );

    case CMS_ENUM_SECTION_TYPE.PRODUCT:
      return (
        <SectionProductContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          isSectionContentExtraWide={isSectionContentExtraWide}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.RUG_FULL_HEIGHT:
      return (
        <SectionRugFullHeightContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
        />
      );

    case CMS_ENUM_SECTION_TYPE.RUG_LIST:
      return (
        <SectionRugListContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.SHOWROOM_GLOBAL_DISTRIBUTION:
      return (
        <SectionShowroomGlobalDistributionContainer
          index={index}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
        />
      );

    case CMS_ENUM_SECTION_TYPE.SHOWROOM_INTRO:
      return (
        <SectionShowroomIntroContainer
          index={index}
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.SHOWROOM_MAP:
      return (
        <SectionShowroomMapContainer
          isClipped={isClipped}
          isCritical={isCritical}
          isHiddenOnMobile={isHiddenOnMobile}
          isNeighborClipped={isNeighborClipped}
          section={section}
          sectionWidth={sectionWidth}
        />
      );

    case CMS_ENUM_SECTION_TYPE.STORE_LOCATOR:
      return <SectionStoreLocatorContainer section={section} />;

    case CMS_ENUM_SECTION_TYPE.VIDEO_FULL_SCREEN:
      return (
        <SectionVideoFullScreenContainer isHiddenOnMobile={isHiddenOnMobile} isNeighborClipped={isNeighborClipped} section={section} />
      );

    case CMS_ENUM_SECTION_TYPE.VIDEO_FULL_SCREEN_VIMEO:
      return (
        <SectionVideoFullScreenVimeoContainer isHiddenOnMobile={isHiddenOnMobile} isNeighborClipped={isNeighborClipped} section={section} />
      );
  }

  return null;
};

export default SectionContainer;
