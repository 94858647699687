import React from 'react';
import { graphql } from 'gatsby';

import SectionItemContentBackground from '../../../../components/Section/SectionItems/SectionItemContentBackground';
import { normalizeContentPositionX } from '../../utils';

// Types
import { SectionItemContentBackgroundContainerFragment } from './__generated__/SectionItemContentBackgroundContainerFragment';
import { SectionWidth } from '../../../../../types/sections';

export const fragment = graphql`
  fragment SectionItemContentBackgroundContainerFragment on CMS_Section {
    contentPositionX
    headline
    text
    backgroundImage {
      id
    }
  }
`;

type Props = {
  section: SectionItemContentBackgroundContainerFragment;
  sectionWidth: SectionWidth;
  shouldRenderWithoutBackground?: boolean;
};

const SectionItemContentBackgroundContainer = ({ section, sectionWidth, shouldRenderWithoutBackground = false }: Props) => {
  const contentPositionX = normalizeContentPositionX(section.contentPositionX);

  return (
    <SectionItemContentBackground
      contentPositionX={contentPositionX}
      hasBackgroundImage={shouldRenderWithoutBackground || !!section.backgroundImage}
      hasContent={!!section.headline || !!section.text}
      sectionWidth={sectionWidth}
    />
  );
};

export default SectionItemContentBackgroundContainer;
