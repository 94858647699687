import React from 'react';

const useVideo = () => {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  // We want to hide the title and never show it again after it was started once.
  // After first starting, the control button is used for play / pause.const[hasStarted, setHasStarted] = React.useState<boolean>(false)
  const [hasStarted, setHasStarted] = React.useState<boolean>(false);

  const [isVideoPlaying, setIsVideoPlaying] = React.useState<boolean>(false);

  const handlePlayPause = () => {
    if (isVideoPlaying) {
      setIsVideoPlaying(false);
    } else {
      if (!hasStarted) {
        setHasStarted(true);
      }

      setIsVideoPlaying(true);
    }
  };

  return { handlePlayPause, hasStarted, isVideoPlaying, videoRef };
};

export default useVideo;
