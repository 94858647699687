import React from 'react';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const FlagFrance = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 900" {...props}>
      <path fill="none" d="M-1-1h902v902H-1z" />
      <g>
        <path fill="#ED2939" stroke="null" d="M0 0h900v900H0z" />
        <path fill="#fff" stroke="null" d="M0 0h600v900H0z" />
        <path fill="#002395" d="M0 0h300v900H0z" />
      </g>
    </svg>
  );
};

export default FlagFrance;
