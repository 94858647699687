import React from 'react';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const FlagGermany = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" {...props}>
      <path fill="none" d="M-1-1h802v602H-1z" />
      <g>
        <g stroke="null">
          <path d="M0 0h9.975v9.985H0V0z" />
          <path fill="#D00" d="M0 3.328h9.975v6.657H0V3.328z" />
          <path fill="#FFCE00" d="M0 6.657h9.975v3.328H0V6.657z" />
        </g>
      </g>
    </svg>
  );
};

export default FlagGermany;
