import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';
import { decidePaddingLeftLandscapeSmall, decidePaddingRightLandscape } from '../utils';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

export const ContentWrapper = styled.div`
  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.vertical.bothQuadruple};

    width: 30%;
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.margin.vertical.bottomHalf};

    /* Flex Container */
    display: flex;
    flex-direction: row;
    justify-content: center;

    height: 100%;
    width: 100%;
  }
`;

export const MapWrapper = styled.div`
  overflow: hidden;

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.margin.horizontal.leftQuadruple};

    height: 100%;
    max-height: 100vh;
    width: 50%;
  }

  ${styleUtils.media.orientation.portrait} {
    height: 100%;
    max-width: 100vw;
    min-height: 400px;
    width: 100%;
  }
`;

type SectionProps = {
  backgroundImage: FluidImage | null;
  backgroundColor: string | null;
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${decidePaddingLeftLandscapeSmall};
  ${decidePaddingRightLandscape};

  /* Flex Container */
  align-items: ${props => props.contentPositionX};
  flex-direction: column;
  justify-content: ${props => props.contentPositionY};

  overflow: hidden;

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.both};

    /* Flex Container */
    display: flex;
    flex-direction: row;
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.none};
    ${styleUtils.padding.vertical.both};

    /* Flex Container */
    justify-content: center;
    flex-direction: column;

    width: 100vw;

    ${styleUtils.media.tablet.small} {
      /* Flex Container */
      justify-content: ${props => props.contentPositionY};
    }
  }
`;
