import styled from '../../../../../utils/styling/styled';

import styleUtils from '../../../../../utils/styling/styleUtils';

type WorldMapProps = {
  hasActiveRegion: boolean;
};

export const WorldMap = styled.img<WorldMapProps>`
  opacity: ${props => (!props.hasActiveRegion ? 1 : 0.5)};
  transition: opacity 300ms ease-in-out;

  ${styleUtils.media.orientation.landscape} {
    height: 100%;
  }

  ${styleUtils.media.orientation.portrait} {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const WrapperWrapper = styled.div`
  ${styleUtils.media.orientation.landscape} {
    /* Flex Container */
    align-items: center;
    display: flex;
    justify-content: center;

    height: 35vh;
    max-width: 1400px;
  }

  ${styleUtils.media.orientation.portrait} {
    display: none;
  }
`;
