import idx from 'idx';

import buildShowroomPageUrl from '../../../../utils/url/buildShowroomPageUrl';

type GetShowroomUrlInput = {
  showroomPage?: { slug?: string | null } | null;
};

export const getShowroomPageUrl = (showroom: GetShowroomUrlInput) => {
  const slug = idx(showroom, _ => _.showroomPage.slug) || null;

  if (!slug) {
    return null;
  }

  return buildShowroomPageUrl(slug);
};
