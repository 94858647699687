import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import SectionItemProductBuyButton from '../../../../components/Section/SectionItems/SectionItemProductBuyButton';

// Types
import { SectionItemProductBuyButtonContainerFragment } from './__generated__/SectionItemProductBuyButtonContainerFragment';

export const fragment = graphql`
  fragment SectionItemProductBuyButtonContainerFragment on CMS_Section {
    contentPageLink {
      slug
    }
    productLink
  }
`;

type Props = {
  section: SectionItemProductBuyButtonContainerFragment;
};

const SectionItemProductBuyButtonContainer = ({ section }: Props) => {
  const productLink = section.productLink;
  const slug = idx(section, _ => _.contentPageLink.slug) || null;

  if (typeof productLink !== 'string' && typeof slug !== 'string') {
    return null;
  }

  return <SectionItemProductBuyButton productLink={productLink} slug={slug} />;
};

export default SectionItemProductBuyButtonContainer;
