import styled from '../../../../../utils/styling/styled';

import Link from '../../../../Link';
import styleUtils from '../../../../../utils/styling/styleUtils';

type Text1Props = {
  textColor: string;
};

export const Text1 = styled.p<Text1Props>`
  ${styleUtils.font.weight.bold};

  color: ${props => props.textColor};
  font-size: 1rem;
  line-height: 1.3;
  text-align: start;
  text-transform: uppercase;
  white-space: pre-wrap;
`;

type Text2Props = {
  textColor: string;
};

export const Text2 = styled.p<Text2Props>`
  ${styleUtils.margin.vertical.topHalf}

  color: ${props => props.textColor};
  font-size: 1rem;
  line-height: 1.3;
  text-align: start;
  white-space: pre-wrap;
`;

export const TextLink = styled(Link)`
  ${styleUtils.animation.navigationListItem};

  white-space: nowrap;
`;

export const Wrapper = styled.div`
  ${styleUtils.margin.vertical.top}
  ${styleUtils.misc.frame}

  ${styleUtils.media.orientation.landscape} {
    width: 100%;
  }

  ${styleUtils.media.orientation.portrait} {
    width: 70%;
  }
`;
