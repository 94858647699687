import React from 'react';
import { graphql } from 'gatsby';

import SectionItemContentHeadline from '../../../../components/Section/SectionItems/SectionItemContentHeadline';
import { normalizeContentPositionY, normalizeTextAlign } from '../../utils';

// Types
import { SectionItemContentHeadlineContainerFragment } from './__generated__/SectionItemContentHeadlineContainerFragment';

export const fragment = graphql`
  fragment SectionItemContentHeadlineContainerFragment on CMS_Section {
    contentPositionY
    headline
    headlineColor
    textAlign
  }
`;

type Props = {
  contentPositionY?: string;
  hasBackgroundImage: boolean;
  section: SectionItemContentHeadlineContainerFragment;
  textAlign?: string;
};

const SectionItemContentHeadlineContainer = ({ contentPositionY, hasBackgroundImage, section, textAlign }: Props) => {
  const headline = section.headline;

  if (typeof headline !== 'string') {
    return null;
  }

  const headlineColor = section.headlineColor;

  return (
    <SectionItemContentHeadline
      contentPositionY={contentPositionY || normalizeContentPositionY(section.contentPositionY)}
      hasBackgroundImage={hasBackgroundImage}
      text={headline}
      textAlign={textAlign || normalizeTextAlign(section.textAlign)}
      textColor={headlineColor}
    />
  );
};

export default SectionItemContentHeadlineContainer;
