import styled from '../../../utils/styling/styled';

import Heading from '../../Heading';
import MediaImageFluid from '../../MediaImage/MediaImageFluid';
import NavigationCollectionChevron from '../../SVG/NavigationCollectionChevron';
import ScrollSection from '../../ScrollSection';
import animation from '../../../utils/styling/animation';
import styleUtils from '../../../utils/styling/styleUtils';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { SectionWidth } from '../../../../types/sections';

type ContentProps = {
  hasStarted: boolean;
  textColor: string;
};

export const Content = styled.div<ContentProps>`
  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: column;

  color: ${props => props.textColor};
  left: 50%;
  opacity: ${props => (!props.hasStarted ? 1 : 0)};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 1000ms ease-in-out;
`;

type ControlButtonProps = {
  hasStarted: boolean;
  textColor: string;
};

export const ControlButton = styled.button<ControlButtonProps>`
  bottom: 2rem;
  color: ${props => props.textColor};
  left: 3rem;
  opacity: ${props => (!props.hasStarted ? 0 : 1)};
  position: absolute;
  transition: opacity 1000ms ease-in-out;
  z-index: 2;
`;

export const Poster = styled(MediaImageFluid)`
  height: 100%;
  position: absolute;
  width: 100%;

  ${styleUtils.media.orientation.landscape} {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${styleUtils.media.orientation.portrait} {
    left: 0;
    min-height: 56.25vw !important;
    top: 0;
  }
`;

type SectionProps = {
  backgroundImage?: FluidImage | null;
  isNeighborClipped: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  /* Flex Container */
  align-items: center;
  flex-direction: column;
  justify-content: center;

  position: relative;

  ${styleUtils.media.orientation.landscape} {
    height: 100%;
    max-height: none;
    overflow: hidden;
    padding: 0 !important;
    width: 100%;
  }

  ${styleUtils.media.orientation.portrait} {
    && {
      width: 100%;
      min-height: 56.25vw !important;
      overflow: hidden;
    }
  }
`;

export const Title = styled(Heading)`
  ${styleUtils.font.weight.bold}

  text-transform: uppercase;

  font-size: 3rem;

  ${styleUtils.media.tablet.small} {
    font-size: 4rem;
  }
`;

export const Video = styled.video`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  ${styleUtils.media.orientation.landscape} {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
  }

  ${styleUtils.media.orientation.portrait} {
    min-height: 100%;
    min-width: 100%;
    height: auto;
    width: auto;
  }
`;

export const ViewVideoChevron = styled(NavigationCollectionChevron)`
  width: 0.6rem;
`;

export const ViewVideoButton = styled.button`
  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: row;

  /* This animates the wrapped SVG, so we animate on hover of the whole thing, not just the icon. */
  ${animation.viewCollectionChevron}
`;

export const ViewVideoText = styled.span`
  ${styleUtils.font.weight.bold};

  font-size: 1.2rem;
  margin-right: 0.5rem;
  text-transform: uppercase;
`;
