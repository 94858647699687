import styled from '../../../../utils/styling/styled';

import SectionItemContentAddress from '../../SectionItems/SectionItemContentMapAddress';
import styleUtils from '../../../../utils/styling/styleUtils';

// Types

export const Address = styled(SectionItemContentAddress)`
  ${styleUtils.media.orientation.landscape} {
    width: 100%;
  }

  ${styleUtils.media.orientation.portrait} {
    width: 100%;
  }
`;
