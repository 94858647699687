import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

import useVideo from '../../../utils/hooks/useVideo';

import * as S from './styles';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

type Props = {
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  textColor: string;
  title?: string | null;
  videoPoster: FluidImage | null;
  videoVimeoId: string;
};

const SectionVideoFullScreenVimeo = ({ isHiddenOnMobile, isNeighborClipped, textColor, title, videoPoster, videoVimeoId }: Props) => {
  const { handlePlayPause, hasStarted, isVideoPlaying } = useVideo();

  return (
    <S.Section
      contentPositionY="center"
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      isNotClipable={true}
      sectionWidth={100}
    >
      <S.Video
        //
        controls={false}
        height="100%"
        loop={true}
        muted={false}
        paused={!isVideoPlaying}
        responsive={true}
        showByline={false}
        showPortrait={false}
        showTitle={false}
        style={{ width: '100vw', height: '100vh' }}
        video={videoVimeoId}
        volume={1}
        width="100%"
      />

      <S.ControlButton hasStarted={hasStarted} onClick={hasStarted ? handlePlayPause : undefined} textColor={textColor} type="button">
        {!isVideoPlaying ? <FontAwesomeIcon icon={faPlay} size="2x" /> : <FontAwesomeIcon icon={faPause} size="2x" />}
      </S.ControlButton>

      {!!videoPoster && !hasStarted && <S.Poster image={videoPoster} loading="eager" />}

      <S.Content hasStarted={hasStarted} textColor={textColor}>
        <S.Title>{title}</S.Title>

        <S.ViewVideoButton onClick={handlePlayPause} type="button">
          <S.ViewVideoText>Watch Video</S.ViewVideoText>

          <S.ViewVideoChevron id="svg" />
        </S.ViewVideoButton>
      </S.Content>
    </S.Section>
  );
};

export default SectionVideoFullScreenVimeo;
