import React from 'react';
import { graphql } from 'gatsby';

import SectionItemContentFramedText from '../../../../components/Section/SectionItems/SectionItemContentFramedText';
import { normalizeTextAlign } from '../../utils';

// Types
import { SectionItemContentFramedTextContainerFragment } from './__generated__/SectionItemContentFramedTextContainerFragment';
import { SectionWidth } from '../../../../../types/sections';

export const fragment = graphql`
  fragment SectionItemContentFramedTextContainerFragment on CMS_Section {
    text
    textAlign
    textColor
  }
`;

type Props = {
  contentPositionY: string;
  hasBackgroundImage: boolean;
  section: SectionItemContentFramedTextContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionItemContentFramedTextContainer = ({ contentPositionY, hasBackgroundImage, section, sectionWidth }: Props) => {
  const text = section.text;

  if (typeof text !== 'string') {
    return null;
  }

  const textAlign = normalizeTextAlign(section.textAlign);
  const textColor = section.textColor;

  return (
    <SectionItemContentFramedText
      contentPositionY={contentPositionY}
      hasBackgroundImage={hasBackgroundImage}
      sectionWidth={sectionWidth}
      text={text}
      textAlign={textAlign}
      textColor={textColor}
    />
  );
};

export default SectionItemContentFramedTextContainer;
