import styled from '../../../../utils/styling/styled';

import SectionItemShowroomAddress from '../../SectionItems/SectionItemShowroomAddress';
import styleUtils from '../../../../utils/styling/styleUtils';

// Types

export const Address = styled(SectionItemShowroomAddress)`
  ${styleUtils.media.orientation.landscape} {
    width: 100%;
  }

  ${styleUtils.media.orientation.portrait} {
    width: 100%;
  }
`;
