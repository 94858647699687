import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import GlobalDistributionOverlay from '../../../../../components/Section/SectionGlobalDistribution/GlobalDistributionItems/GlobalDistributionOverlay';
import GlobalDistributionOverlayStoreList from '../../../../../components/Section/SectionGlobalDistribution/GlobalDistributionItems/GlobalDistributionOverlay/OverlayStoreList';
import GlobalDistributionOverlayStoreListStore from '../../../../../components/Section/SectionGlobalDistribution/GlobalDistributionItems/GlobalDistributionOverlay/OverlayStoreList/OverlayStoreListStore';
import GlobalDistributionRegionNorthAmerica from '../../../../../components/Section/SectionGlobalDistribution/GlobalDistributionRegions/GlobalDistributionRegionNorthAmerica';
import notNullOrUndefined from '../../../../../utils/misc/notNullOrUndefined';
import { getShowroomPageUrl } from '../utils';

// Types
import { CMS_ENUM_SHOWROOM_REGION, CMS_ENUM_SHOWROOM_TYPE } from '../../../../../../types/global';
import { GlobalDistributionRegionNorthAmericaContainerQuery } from './__generated__/GlobalDistributionRegionNorthAmericaContainerQuery';

type Props = {
  activeRegion: CMS_ENUM_SHOWROOM_REGION | null;
  headlineColor: string;
  onMouseEnter: (nextActiveRegion: CMS_ENUM_SHOWROOM_REGION) => void;
  onMouseLeave: () => void;
  textColor: string;
};

const GlobalDistributionRegionNorthAmericaContainer = ({ activeRegion, headlineColor, onMouseEnter, onMouseLeave, textColor }: Props) => {
  const data = useStaticQuery<GlobalDistributionRegionNorthAmericaContainerQuery>(graphql`
    query GlobalDistributionRegionNorthAmericaContainerQuery {
      cms {
        showrooms(sort: "order") {
          id
          name
          region
          type
          showroomPage {
            slug
          }
        }
      }
    }
  `);

  if (!data.cms.showrooms) {
    throw Error('Something went wrong.');
  }

  const filteredShowrooms = data.cms.showrooms
    .filter(notNullOrUndefined)
    .filter(showroom => showroom.region === CMS_ENUM_SHOWROOM_REGION.NORTH_AMERICA);

  const typeShowroomStore = filteredShowrooms
    .filter(showroom => showroom.type === CMS_ENUM_SHOWROOM_TYPE.SHOWROOM || showroom.type === CMS_ENUM_SHOWROOM_TYPE.STORE)
    .map(showroom => {
      const url = getShowroomPageUrl(showroom);

      return <GlobalDistributionOverlayStoreListStore key={showroom.id} name={showroom.name} textColor={headlineColor} url={url} />;
    });

  const typeExclusivePartner = filteredShowrooms
    .filter(showroom => showroom.type === CMS_ENUM_SHOWROOM_TYPE.EXCLUSIVE_PARTNER)
    .map(showroom => {
      const url = getShowroomPageUrl(showroom);

      return <GlobalDistributionOverlayStoreListStore key={showroom.id} name={showroom.name} textColor={headlineColor} url={url} />;
    });

  const showroomsAndStores = (
    <GlobalDistributionOverlayStoreList stores={typeShowroomStore} storesType="Showrooms & Stores" textColor={textColor} />
  );
  const exclusivePartners = (
    <GlobalDistributionOverlayStoreList stores={typeExclusivePartner} storesType="Exclusive Partners" textColor={textColor} />
  );

  const storeLists = (
    <>
      {typeShowroomStore.length > 0 && showroomsAndStores}
      {typeExclusivePartner.length > 0 && exclusivePartners}
    </>
  );

  const overlay = (
    <GlobalDistributionOverlay
      isVisible={activeRegion === CMS_ENUM_SHOWROOM_REGION.NORTH_AMERICA}
      regionName="North America"
      storeLists={storeLists}
    />
  );

  return (
    <GlobalDistributionRegionNorthAmerica
      hasActiveRegion={!!activeRegion}
      isActiveRegion={activeRegion === CMS_ENUM_SHOWROOM_REGION.NORTH_AMERICA}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      overlay={overlay}
      pinColor={headlineColor}
    />
  );
};

// TODO: Store // Showroom. Naming.

export default GlobalDistributionRegionNorthAmericaContainer;
