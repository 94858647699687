import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { SectionWidth } from '../../../../types/sections';

type SectionProps = {
  backgroundImage?: FluidImage | null;
  isNeighborClipped: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  /* Flex Container */
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 0 !important;
  position: relative;

  ${styleUtils.media.orientation.landscape} {
    height: 100%;
    max-height: none;
    min-width: 100vw;
    overflow: hidden;
    padding: 0 !important;
    width: 100%;
  }

  ${styleUtils.media.orientation.portrait} {
    && {
      width: 100%;
      min-height: 100vw !important;
      overflow: hidden;
    }
  }
`;

export const Walkthrough = styled.iframe`
  border: none;
  height: 100%;
  width: 100vw;
`;
