import React from 'react';

import * as S from './styles';

// Types
import { SectionWidth } from '../../../../../types/sections';

type Props = {
  contentPositionX: string;
  hasBackgroundImage: boolean;
  hasContent: boolean;
  sectionWidth: SectionWidth;
};

const SectionItemContentBackground = ({ contentPositionX, hasBackgroundImage, hasContent, sectionWidth }: Props) => {
  if (!hasBackgroundImage || !hasContent) {
    return null;
  }

  return <S.ContentBackground contentPositionX={contentPositionX} sectionWidth={sectionWidth} />;
};

export default SectionItemContentBackground;
