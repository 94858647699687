import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

import useVideo from '../../../utils/hooks/useVideo';

import * as S from './styles';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

type Props = {
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  textColor: string;
  title: string;
  videoPoster: FluidImage | null;
  videoUrl: string;
};

const SectionVideoFullScreen = ({ isHiddenOnMobile, isNeighborClipped, textColor, title, videoPoster, videoUrl }: Props) => {
  const { handlePlayPause, hasStarted, isVideoPlaying, videoRef } = useVideo();

  return (
    <S.Section
      contentPositionY="center"
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      isNotClipable={true}
      sectionWidth={100}
    >
      <S.Video loop={true} ref={videoRef}>
        <source src={videoUrl} type="video/mp4" />
      </S.Video>

      <S.ControlButton hasStarted={hasStarted} onClick={hasStarted ? handlePlayPause : undefined} textColor={textColor} type="button">
        {!isVideoPlaying ? <FontAwesomeIcon icon={faPlay} size="2x" /> : <FontAwesomeIcon icon={faPause} size="2x" />}
      </S.ControlButton>

      {!!videoPoster && !hasStarted && <S.Poster image={videoPoster} loading="eager" />}

      <S.Content hasStarted={hasStarted} textColor={textColor}>
        <S.Title>{title}</S.Title>

        <S.ViewVideoButton onClick={handlePlayPause} type="button">
          <S.ViewVideoText>Watch Video</S.ViewVideoText>

          <S.ViewVideoChevron id="svg" />
        </S.ViewVideoButton>
      </S.Content>
    </S.Section>
  );
};

export default SectionVideoFullScreen;
