import routes from '../../config/routes';

type BuildCollectionOverviewRugLinkInput = {
  rugId: number | string;
  slug: string;
};

const buildCollectionOverviewRugLink = ({ rugId, slug }: BuildCollectionOverviewRugLinkInput) => {
  return `${routes.collection}/${slug}/overview#${rugId}`;
};

export default buildCollectionOverviewRugLink;
