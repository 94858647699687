import React from 'react';
import { useInView } from 'react-intersection-observer';

import MediaImageFluid from '../../../MediaImage/MediaImageFluid';
import RugItemButton from '../../../Rug/RugItems/RugItemButton';

import * as S from './styles';

// Types
import { CMS_ENUM_RUG_AWARD } from '../../../../../types/global';
import { FluidImage } from '../../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

type Props = {
  award?: CMS_ENUM_RUG_AWARD | null;
  caption?: string | null;
  isCritical: boolean;
  name: string;
  picture: FluidImage;
  textColor: string;
  url: string | null;
};

const SectionRugListRug = ({ award, caption, isCritical, name, picture, textColor, url }: Props) => {
  const [ref, isInView] = useInView({ threshold: 0, triggerOnce: true });

  return (
    <S.Wrapper isInView={isInView} ref={ref}>
      <MediaImageFluid
        alt={`Picture of a ${name} rug`}
        caption={caption}
        fadeIn={!isCritical}
        loading={isCritical ? 'eager' : undefined}
        image={picture}
        vh={70}
      />

      <S.Award award={award} />

      {!!url && <RugItemButton url={url} />}

      <S.Name textColor={textColor}>{name}</S.Name>
    </S.Wrapper>
  );
};

export default SectionRugListRug;
