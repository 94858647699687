import React from 'react';
import idx from 'idx';
import { graphql, useStaticQuery } from 'gatsby';

import SectionHomeShowrooms from '../../../components/Section/SectionHomeShowrooms';
import filterOutExclusivePartners from '../../../utils/showroom/filterOutExclusivePartners';
import splitNewlineToBreak from '../../../utils/string/splitNewlineToBreak';
import { getBackgroundImage, normalizeContentPositionX, normalizeContentPositionY } from '../utils';

// Types
import { SectionHomeShowroomsContainerFragment } from './__generated__/SectionHomeShowroomsContainerFragment';
import { SectionHomeShowroomsContainerQuery } from './__generated__/SectionHomeShowroomsContainerQuery';
import { SectionWidth } from '../../../../types/sections';

export const fragment = graphql`
  fragment SectionHomeShowroomsContainerFragment on CMS_Section {
    backgroundColor
    backgroundPattern
    contentPositionX
    contentPositionY
    headline
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
    headlineColor
    textColor
  }
`;

type Props = {
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  section: SectionHomeShowroomsContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionHomeShowroomsContainer = ({
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  section,
  sectionWidth,
}: Props) => {
  const data = useStaticQuery<SectionHomeShowroomsContainerQuery>(graphql`
    query SectionHomeShowroomsContainerQuery {
      cms {
        showrooms(sort: "order") {
          name
          nameInNavigation
          type
        }
      }
    }
  `);

  const backgroundColor = section.backgroundColor;
  const backgroundImage = getBackgroundImage(section);
  const headline = section.headline;
  const showrooms = (idx(data, _ => _.cms.showrooms) || [])
    .filter(showroom => !!showroom.name)
    .filter(filterOutExclusivePartners)
    .map(showroom => showroom.nameInNavigation || showroom.name)
    .join(' | ');

  if ((!backgroundColor && !backgroundImage) || !headline) {
    return null;
  }

  const contentPositionX = normalizeContentPositionX(section.contentPositionX);
  const contentPositionY = normalizeContentPositionY(section.contentPositionY);
  const headlineTextColor = section.headlineColor;
  const textColor = section.textColor;

  const splitHeadline = splitNewlineToBreak(headline);

  return (
    <SectionHomeShowrooms
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      headline={splitHeadline}
      headlineTextColor={headlineTextColor}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={sectionWidth}
      showrooms={showrooms}
      textColor={textColor}
    />
  );
};

export default SectionHomeShowroomsContainer;
