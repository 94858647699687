import React from 'react';

import Section from '../../Heading/Section';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../../types/global';
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type Props = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  rugs: ReactNode;
  sectionWidth: SectionWidth;
};

const SectionRugList = ({
  backgroundColor,
  backgroundImage,
  backgroundPattern,
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  rugs,
  sectionWidth,
}: Props) => {
  return (
    <S.Section
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={backgroundPattern}
      contentPositionX="center"
      contentPositionY="center"
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={sectionWidth}
      shouldNotAutoHeight={true}
      shouldNotMakeWiderIfNeighborIsClipped={true}
    >
      <Section hasNoTag={true}>{rugs}</Section>
    </S.Section>
  );
};

export default SectionRugList;
