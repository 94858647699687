import css from '@emotion/css';

import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';
import { setTextShadow } from '../../utils';

// Types
import { SectionWidth } from '../../../../../types/sections';

const decideWidth = (props: WrapperProps) => {
  switch (props.sectionWidth) {
    case 25:
      return '80%';

    case 50:
      return '80%';

    case 75:
      if (props.isSectionContentExtraWide) {
        return '50%';
      }

      return '30%';

    case 100:
      if (props.isSectionContentExtraWide) {
        return '40%';
      }

      return '30%';
  }
};

type TextProps = {
  isPortraitVariant?: boolean;
  hasBackgroundImage: boolean;
  textAlign: string;
  textColor: string;
};

export const Text = styled.p<TextProps>`
  ${setTextShadow};

  color: ${props => props.textColor};
  font-size: 1rem;
  line-height: 1.3;
  text-align: ${props => props.textAlign};
  white-space: pre-wrap;

  ${styleUtils.media.orientation.landscape} {
    display: ${props => (!props.isPortraitVariant ? 'block' : 'none')};
  }

  ${styleUtils.media.orientation.portrait} {
    display: ${props => (!props.isPortraitVariant ? 'none' : 'block')};
    text-align: start;

    ${styleUtils.media.tablet.small} {
      text-align: ${props => props.textAlign};
    }
  }
`;

type WrapperProps = {
  contentPositionY: string;
  isSectionContentExtraWide: boolean;
  sectionWidth: SectionWidth;
};

export const Wrapper = styled.div<WrapperProps>`
  ${props =>
    props.contentPositionY === 'flex-end'
      ? css`
          ${styleUtils.margin.vertical.bottomHalf};
        `
      : css`
          ${styleUtils.margin.vertical.topHalf}
        `};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.margin.vertical.top};

    width: ${decideWidth};
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.margin.vertical.topDouble};
    ${styleUtils.margin.vertical.bottomDouble}

    width: 100%;
  }
`;
