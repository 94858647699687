import React from 'react';
import { graphql } from 'gatsby';

import SectionItemCollectionLogo from '../../../../components/Section/SectionItems/SectionItemCollectionLogo';
import { getLogo } from '../../utils';

// Types
import { SectionItemCollectionLogoContainerFragment } from './__generated__/SectionItemCollectionLogoContainerFragment';
import { SectionWidth } from '../../../../../types/sections';

export const fragment = graphql`
  fragment SectionItemCollectionLogoContainerFragment on CMS_Section {
    logo {
      ...SectionLogoPictureFragment
    }
  }
`;

type Props = {
  alt: string;
  contentPositionY: string;
  isCritical: boolean;
  isSectionContentExtraWide: boolean;
  section: SectionItemCollectionLogoContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionItemCollectionLogoContainer = ({
  alt,
  contentPositionY,
  isCritical,
  isSectionContentExtraWide,
  section,
  sectionWidth,
}: Props) => {
  const logo = getLogo(section);

  if (!logo) {
    return null;
  }

  return (
    <SectionItemCollectionLogo
      alt={alt}
      contentPositionY={contentPositionY}
      isCritical={isCritical}
      isSectionContentExtraWide={isSectionContentExtraWide}
      logo={logo}
      sectionWidth={sectionWidth}
    />
  );
};

export default SectionItemCollectionLogoContainer;
