import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import SectionContentMapAddress from '../../../../components/Section/SectionContentMap/SectionContentMapAddress';
import { normalizeTextAlign } from '../../utils';

// Types
import { SectionContentMapAddressContainerFragment } from './__generated__/SectionContentMapAddressContainerFragment';

export const fragment = graphql`
  fragment SectionContentMapAddressContainerFragment on CMS_Section {
    headlineColor
    text
    textAlign
    textColor
    mapEvent {
      address
      booth
      cell
      email
      endDate
      fax
      hours
      phone
      startDate
    }
  }
`;

type Props = {
  contentPositionY: string;
  hasBackgroundImage: boolean;
  section: SectionContentMapAddressContainerFragment;
  textAlign?: string;
};

const SectionContentMapAddressContainer = ({ contentPositionY, hasBackgroundImage, section, textAlign }: Props) => {
  const address = idx(section, _ => _.mapEvent.address);

  if (typeof address !== 'string') {
    return null;
  }

  const booth = idx(section, _ => _.mapEvent.booth) || null;
  const cell = idx(section, _ => _.mapEvent.cell) || null;
  const email = idx(section, _ => _.mapEvent.email) || null;
  const endDate = idx(section, _ => _.mapEvent.endDate) || null;
  const fax = idx(section, _ => _.mapEvent.fax) || null;
  const hours = idx(section, _ => _.mapEvent.hours) || null;
  const phone = idx(section, _ => _.mapEvent.phone) || null;
  const startDate = idx(section, _ => _.mapEvent.startDate) || null;

  return (
    <SectionContentMapAddress
      address={address}
      booth={booth}
      cell={cell}
      contentPositionY={contentPositionY}
      email={email}
      endDate={!!endDate ? endDate.toString() : null}
      fax={fax}
      hasBackgroundImage={hasBackgroundImage}
      hours={hours}
      phone={phone}
      sectionWidth={100}
      startDate={!!startDate ? startDate.toString() : null}
      textAlign={textAlign || normalizeTextAlign(section.textAlign)}
      textColor={section.textColor}
    />
  );
};

export default SectionContentMapAddressContainer;
