import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';
import { decidePaddingLeftLandscapeSmall, decidePaddingRightLandscape } from '../utils';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type SectionProps = {
  backgroundImage: FluidImage | null;
  backgroundColor: string | null;
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${decidePaddingLeftLandscapeSmall};
  ${decidePaddingRightLandscape};

  /* Flex Container */
  align-items: ${props => props.contentPositionX};
  flex-direction: column;
  justify-content: ${props => props.contentPositionY};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.bothQuadruple};
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.both};

    /* Flex Container */
    justify-content: center;

    ${styleUtils.media.tablet.small} {
      /* Flex Container */
      justify-content: ${props => props.contentPositionY};
    }
  }
`;
