import React from 'react';

import * as S from './styles';

// Types
import { CMS_ENUM_SHOWROOM_REGION } from '../../../../../../types/global';
import { ReactNode } from 'react';

type Props = {
  hasActiveRegion: boolean;
  isActiveRegion: boolean;
  onMouseEnter: (nextActiveRegion: CMS_ENUM_SHOWROOM_REGION) => void;
  onMouseLeave: () => void;
  overlay: ReactNode;
  pinColor: string;
};

const GlobalDistributionRegionMiddleEast = ({ hasActiveRegion, isActiveRegion, onMouseEnter, onMouseLeave, overlay, pinColor }: Props) => {
  const handleMouseEnter = () => {
    onMouseEnter(CMS_ENUM_SHOWROOM_REGION.MIDDLE_EAST);
  };

  return (
    <S.Pin
      hasActiveRegion={hasActiveRegion}
      isActiveRegion={isActiveRegion}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
      overlay={overlay}
      pinColor={pinColor}
    />
  );
};

export default GlobalDistributionRegionMiddleEast;
