import React from 'react';

import SectionEventsEventsTable from './SectionEventsEventsTable';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../../types/global';
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type Props = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  contentPositionX: string;
  contentPositionY: string;
  events: ReactNode;
  eventsPortrait: ReactNode;
  headline: ReactNode;
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  sectionWidth: SectionWidth;
  textColor: string;
};

const SectionEventsEvents = ({
  backgroundColor,
  backgroundImage,
  backgroundPattern,
  contentPositionX,
  contentPositionY,
  events,
  eventsPortrait,
  headline,
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  sectionWidth,
  textColor,
}: Props) => {
  return (
    <S.Section
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={sectionWidth}
    >
      {headline}

      <S.EventsPortraitList>{eventsPortrait}</S.EventsPortraitList>

      <SectionEventsEventsTable events={events} isClipped={isClipped} isNeighborClipped={isNeighborClipped} textColor={textColor} />

      <S.Background />
    </S.Section>
  );
};

export default SectionEventsEvents;
