import css from '@emotion/css';

import styled from '../../../../utils/styling/styled';

// Types
import { SectionWidth } from '../../../../../types/sections';
import styleUtils from '../../../../utils/styling/styleUtils';

type WrapperProps = {
  contentPositionY: string;
  isInView: boolean;
  isSectionContentExtraWide: boolean;
  sectionWidth: SectionWidth;
};

const decideWidth = ({ isSectionContentExtraWide }: WrapperProps) => {
  if (isSectionContentExtraWide) {
    return css`
      width: 20rem;

      ${styleUtils.media.desktop.small} {
        width: 35rem;
      }
    `;
  }

  return css`
    width: 10rem;

    ${styleUtils.media.desktop.small} {
      width: 18rem;
    }
  `;
};

export const Wrapper = styled.div<WrapperProps>`
  ${styleUtils.animation.slideIn};
  ${decideWidth};

  margin-bottom: 1rem;
  z-index: 0;
`;
