// import { withPrefix } from 'gatsby';

// TODO: Verify
import mapPin from './assets/map_pin.svg';

export const createIcon = (googleMapsInstance: any) => {
  return {
    anchor: new googleMapsInstance.Point(24, 110),
    origin: new googleMapsInstance.Point(0, 0),
    url: mapPin,
  };
};

type CreateMarkerInput = {
  googleMapsInstance: any;
  lat: number;
  lng: number;
  mapInstance: any;
};

export const createMarker = ({ googleMapsInstance, lat, lng, mapInstance }: CreateMarkerInput) => {
  const icon = createIcon(googleMapsInstance);
  const position = new googleMapsInstance.LatLng(lat, lng);

  // eslint-disable-next-line no-new
  new googleMapsInstance.Marker({
    animation: googleMapsInstance.Animation.DROP,
    cursor: 'hand',
    draggable: false,
    icon,
    map: mapInstance,
    optimized: true,
    position,
    raiseOnDrag: false,
  });
};
