import styled from '../../../../utils/styling/styled';

import StaticPageText from '../../../StaticPageItems/StaticPageText';
import styleUtils from '../../../../utils/styling/styleUtils';

export const PrivacyText = styled(StaticPageText)`
  border: 10px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 0.5%;
  padding-top: 3%;

  text-align: center;

  ${styleUtils.media.phone.small} {
    width: 80vw;
    text-align: justify;
  }

  ${styleUtils.media.tablet.small} {
    width: 60vw;
    text-align: center;
  }

  ${styleUtils.media.desktop.small} {
    width: 50vw;
  }
`;
