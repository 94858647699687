import React from 'react';

import * as S from './styles';

// Types

type Props = {
  headlineColor: string;
  textColor: string;
};

const GlobalDistributionIntroText = ({ headlineColor, textColor }: Props) => {
  return (
    <S.Wrapper>
      <S.Text1 textColor={headlineColor}>Jan Kath is to be found all over the world on 6 continents and in 54 countries</S.Text1>

      <S.Text2 textColor={textColor}>
        Beside our showrooms and flagshipstores Jan Kath is globally represented by a fine selection of exclusive partnerships.
        <br />
        <br />
        For detailed information please email to <S.TextLink url="mailto:info@jan-kath.com">info@jan-kath.com</S.TextLink>
      </S.Text2>
    </S.Wrapper>
  );
};

export default GlobalDistributionIntroText;
