import React from 'react';

import Heading from '../../../../Heading';
import removeNewlines from '../../../../../utils/string/removeNewlines';
import splitNewlineToBreak from '../../../../../utils/string/splitNewlineToBreak';

import * as S from './styles';

// Types

type Props = {
  text: string;
  textColor: string;
};

const GlobalDistributionIntroHeadline = ({ text, textColor }: Props) => {
  const splitText = splitNewlineToBreak(text);
  const textWithoutNewlines = removeNewlines(text);

  return (
    <S.Wrapper>
      {!!textWithoutNewlines && <Heading isVisuallyHidden={true}>{textWithoutNewlines}</Heading>}

      <S.Headline aria-hidden={true} textColor={textColor}>
        {splitText}
      </S.Headline>
    </S.Wrapper>
  );
};

export default GlobalDistributionIntroHeadline;
