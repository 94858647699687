import React from 'react';

/**
 * To make sure that we break where is defined in the CMS, we need to convert the `\n`s to `<br/>`.
 */
const splitNewlineToBreak = (input: string | null, trimSpaces?: boolean) => {
  const splitText = (input || '').split('\n').map((textFragment, index, arr) => {
    return (
      <React.Fragment key={textFragment}>
        {!trimSpaces ? textFragment : textFragment.trim()}

        {index < arr.length - 1 && (
          <>
            <br />
          </>
        )}
      </React.Fragment>
    );
  });

  return splitText;
};

export default splitNewlineToBreak;
