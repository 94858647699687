import styled from '../../../../utils/styling/styled';

import { animated } from '../../../../utils/styling/spring';

import UnstyledFlagChina from '../../../SVG/Flag/FlagChina';
import UnstyledFlagFrance from '../../../SVG/Flag/FlagFrance';
import UnstyledFlagGermany from '../../../SVG/Flag/FlagGermany';
import UnstyledFlagUK from '../../../SVG/Flag/FlagUK';
import styleUtils from '../../../../utils/styling/styleUtils';

export const Flag = styled(animated.svg)`
  border-radius: 50%;
  fill: white;
  width: 1.5rem;
`;

export const FlagChina = styled(UnstyledFlagChina)`
  border-radius: 50%;
  width: 1.5rem;
`;

export const FlagFrance = styled(UnstyledFlagFrance)`
  border-radius: 50%;
  width: 1.5rem;
`;

export const FlagGermany = styled(UnstyledFlagGermany)`
  border-radius: 50%;
  width: 1.5rem;
`;

export const FlagUK = styled(UnstyledFlagUK)`
  border-radius: 50%;
  width: 1.5rem;
`;

export const Language = styled.li`
  ${styleUtils.animation.navigationListItem};
  ${styleUtils.margin.horizontal.bothQuarter};
  ${styleUtils.padding.vertical.bothQuarter};

  /* Flex Container */
  display: flex;
`;

export const Text = styled.p`
  color: white;
  margin-left: 0.9rem;
`;

export const Wrapper = styled.div`
  /* Flex Container */
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  color: ${props => props.theme.color.brand};
`;
