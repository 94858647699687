import React from 'react';

import { NO_SCROLL_ID } from '../../../../config/constants';

import * as S from './styles';

// Types

type Props = {
  className?: string;
  isActive?: boolean;
  id: string;
  name: string;
  onClick?: () => void;
};

const SectionItemStoreLocatorName = ({ className, id, isActive = false, name, onClick }: Props) => {
  return (
    <S.Wrapper className={className} contentPositionY="left" id={id} isSectionContentExtraWide={false} sectionWidth={100}>
      <S.Name id={NO_SCROLL_ID} isActive={isActive} onClick={onClick}>
        {name}
      </S.Name>
    </S.Wrapper>
  );
};

export default SectionItemStoreLocatorName;
