import styled from '../../../utils/styling/styled';

import Link from '../../Link';
import ScrollSection from '../../ScrollSection';
import createOmitProps from '../../../utils/styling/createOmitProps';
import styleUtils from '../../../utils/styling/styleUtils';
import { decidePaddingLeftLandscape, decidePaddingRightLandscape, removeTopMarginIfMultipleChildren } from '../utils';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type ContentProps = {
  headlineTextColor: string;
  textColor: string;
};

export const Content = styled.p<ContentProps>`
  ${styleUtils.margin.vertical.top};
  ${styleUtils.misc.frame};

  color: ${props => props.textColor};
  width: 70%;

  &::after {
    ${styleUtils.font.weight.bold};

    color: ${props => props.headlineTextColor};
    content: ' >>';
  }
`;

export const ContentLink = styled(Link, { shouldForwardProp: createOmitProps(['headlineTextColor', 'textColor']) })<ContentProps>`
  color: ${props => props.textColor};

  &::after {
    ${styleUtils.font.weight.bold};

    color: ${props => props.headlineTextColor};
    content: ' >>';
  }
`;

// When using the linked version of the teaser, we need to set the arrows as the after element.
// But then we can't use our frame. So if we have an url, we'll use the wrapper.
export const ContentWrapper = styled.div`
  ${styleUtils.margin.vertical.top};
  ${styleUtils.misc.frame};

  width: 70%;
`;

type HeadlineProps = {
  textColor: string;
};

export const Headline = styled.p<HeadlineProps>`
  ${styleUtils.font.weight.bold};

  color: ${props => props.textColor};
  line-height: 0.9;
  margin-right: 0.5rem;
  text-transform: uppercase;
  word-break: break-word;

  font-size: 4rem;

  ${styleUtils.media.tablet.small} {
    font-size: 6rem;
  }
  ${styleUtils.media.desktop.small} {
    font-size: 8rem;
  }
`;

type SectionProps = {
  backgroundImage: FluidImage | null;
  backgroundColor: string | null;
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped: boolean;
  isNeighborClipped: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${decidePaddingRightLandscape};
  ${props => props.isClipped && decidePaddingLeftLandscape}
  ${removeTopMarginIfMultipleChildren};

  /* Flex Container */
  align-items: ${props => props.contentPositionX};
  flex-direction: column;
  justify-content: ${props => props.contentPositionY};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.bothQuadruple};
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.bothDouble};
  }
`;
