import React from 'react';
import { useInView } from 'react-intersection-observer';

import * as S from './styles';

// Types
import { FluidImage } from '../../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { HTMLAttributes } from 'react';
import { SectionWidth } from '../../../../../types/sections';

type Props = {
  alt?: string;
  contentPositionY: string;
  isCritical: boolean;
  isSectionContentExtraWide: boolean;
  logo: FluidImage;
  sectionWidth: SectionWidth;
} & HTMLAttributes<HTMLDivElement>;

const SectionItemCollectionLogo = ({
  alt,
  contentPositionY,
  isCritical,
  isSectionContentExtraWide,
  logo,
  sectionWidth,
  ...rest
}: Props) => {
  const [ref, isInView] = useInView({ threshold: 0, triggerOnce: true });

  return (
    <S.CollectionLogo
      alt={alt}
      contentPositionY={contentPositionY}
      isCritical={isCritical}
      isInView={isInView}
      isSectionContentExtraWide={isSectionContentExtraWide}
      logo={logo}
      ref={ref}
      sectionWidth={sectionWidth}
      {...rest}
    />
  );
};

export default SectionItemCollectionLogo;
