import React from 'react';

import GlobalDistributionIntroHeadline from './GlobalDistributionItems/GlobalDistributionIntroHeadline';
import GlobalDistributionIntroText from './GlobalDistributionItems/GlobalDistributionIntroText';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../../types/global';
import { ReactNode } from 'react';

type Props = {
  backgroundColor: string;
  backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  headlineColor: string;
  index: number;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  textColor: string;
  worldMap: ReactNode;
};

const SectionShowroomGlobalDistribution = ({
  backgroundColor,
  backgroundPattern,
  headlineColor,
  index,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  textColor,
  worldMap,
}: Props) => {
  return (
    <S.Section
      backgroundColor={backgroundColor}
      backgroundPattern={backgroundPattern}
      contentPositionX="center"
      contentPositionY="center"
      index={index}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      isNotClipable={true}
      sectionWidth={100}
    >
      <S.TextWrapper>
        <GlobalDistributionIntroHeadline text={`Global \nDistribu\ntion`} textColor={headlineColor} />

        <GlobalDistributionIntroText headlineColor={headlineColor} textColor={textColor} />
      </S.TextWrapper>

      {worldMap}
    </S.Section>
  );
};

export default SectionShowroomGlobalDistribution;
