import React from 'react';
import { graphql } from 'gatsby';

import SectionContentFramed from '../../../components/Section/SectionContentFramed';
import SectionItemContentBackgroundContainer from '../SectionItems/SectionItemContentBackground';
import SectionItemContentFramedHeadlineContainer from '../SectionItems/SectionItemContentFramedHeadline';
import SectionItemContentFramedTextContainer from '../SectionItems/SectionItemContentFramedText';
import { getBackgroundImage, normalizeContentPositionX, normalizeContentPositionY } from '../utils';

// Types
import { SectionContentFramedContainerFragment } from './__generated__/SectionContentFramedContainerFragment';
import { SectionWidth } from '../../../../types/sections';

export const fragment = graphql`
  fragment SectionContentFramedContainerFragment on CMS_Section {
    ...SectionItemContentBackgroundContainerFragment
    ...SectionItemContentFramedHeadlineContainerFragment
    ...SectionItemContentFramedTextContainerFragment
    backgroundColor
    backgroundPattern
    contentPositionX
    contentPositionY
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
  }
`;

type Props = {
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  section: SectionContentFramedContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionContentFramedContainer = ({
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  section,
  sectionWidth,
}: Props) => {
  const backgroundImage = getBackgroundImage(section);

  const contentPositionX = normalizeContentPositionX(section.contentPositionX);
  const contentPositionY = normalizeContentPositionY(section.contentPositionY);

  const contentBackground = <SectionItemContentBackgroundContainer section={section} sectionWidth={sectionWidth} />;

  const textFramed = (
    <SectionItemContentFramedHeadlineContainer
      contentPositionY={contentPositionY}
      hasBackgroundImage={!!backgroundImage}
      section={section}
      sectionWidth={sectionWidth}
    />
  );

  const text = (
    <SectionItemContentFramedTextContainer
      contentPositionY={contentPositionY}
      hasBackgroundImage={!!backgroundImage}
      section={section}
      sectionWidth={sectionWidth}
    />
  );

  return (
    <SectionContentFramed
      backgroundColor={section.backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      contentBackground={contentBackground}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      headline={textFramed}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={sectionWidth}
      text={text}
    />
  );
};

export default SectionContentFramedContainer;
