import styled from '../../../../utils/styling/styled';

import Heading from '../../../Heading';
import RugAward from '../../../Rug/RugItems/RugAward';
import createOmitProps from '../../../../utils/styling/createOmitProps';
import styleUtils from '../../../../utils/styling/styleUtils';

export const Award = styled(RugAward)`
  position: absolute;
  right: 0px;
  z-index: 1;

  top: -30px;

  ${styleUtils.media.tablet.large} {
    top: -30px;
  }

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.media.desktop.huge} {
      top: -70px;
    }
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.media.desktop.huge} {
      top: -50px;
    }
  }
`;

type NameProps = {
  textColor: string;
};

export const Name = styled(Heading, { shouldForwardProp: createOmitProps(['textColor']) })<NameProps>`
  ${styleUtils.margin.vertical.topQuarter};
  ${styleUtils.margin.horizontal.leftHalf};

  color: ${props => props.textColor};
  text-transform: uppercase;
`;

type WrapperProps = {
  isInView: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  ${styleUtils.animation.slideIn};

  /* Flex Container */
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  ${styleUtils.media.orientation.portrait} {
    width: 90vw;
  }

  ${styleUtils.media.orientation.landscape} {
    /* flex: 1 1 auto; */
    margin-right: 5rem;

    &:last-of-type {
      margin-right: 0rem;
    }
  }
`;
