import styleUtils from '../../utils/styling/styleUtils';
import styled from '../../utils/styling/styled';

export const Background = styled.div`
  background-color: black;
  height: 100%;
  opacity: 0.8;
  width: 100%;
  position: absolute;
  pointer-events: none;
`;

export const Button = styled.button`
  ${styleUtils.font.weight.bold};
  ${styleUtils.padding.horizontal.bothHalf};

  color: ${props => props.theme.color.brand};

  position: absolute;
  top: -10%;
  right: -8%;

  ${styleUtils.media.tablet.large} {
    margin-left: 0;
    margin-top: 0;
  }

  &:focus {
    outline: 0;
  }

  ${styleUtils.media.orientation.landscape} {
    font-size: 5vh;
  }

  ${styleUtils.media.orientation.portrait} {
    font-size: 5vw;
  }
`;

export const Text = styled.p`
  color: ${props => props.theme.color.brand};

  font-weight: ${styleUtils.font.weight.bold};
  line-height: 80%;
  text-align: center;
  text-transform: uppercase;

  ${styleUtils.media.orientation.landscape} {
    font-size: 23vh;
  }

  ${styleUtils.media.orientation.portrait} {
    font-size: 18vw;
  }
`;

export const TextWrapper = styled.div`
  position: relative;
  z-index: 3;
`;

export const Wrapper = styled.div`
  /* Flex Container */
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  position: absolute;
  width: 100vw;
  z-index: 2;
  max-height: 100vh;
  max-width: 100vw;
`;
