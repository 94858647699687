import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SectionFooterShowrooms from '../../../components/Section/SectionFooterShowrooms';
import SectionFooterShowroomsShowroomContainer from './SectionFooterShowroomsShowroom';
import SectionItemFooterSocialContainer from '../SectionItems/SectionItemFooterSocial';
import filterOutExclusivePartners from '../../../utils/showroom/filterOutExclusivePartners';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';
import { getBackgroundImage } from '../utils';

// Types
import { SectionFooterShowroomsContainerQuery } from './__generated__/SectionFooterShowroomsContainerQuery';
import { SectionFooterShowroomsContainerFragment } from './__generated__/SectionFooterShowroomsContainerFragment';

export const fragment = graphql`
  fragment SectionFooterShowroomsContainerFragment on CMS_Section {
    backgroundColor
    backgroundPattern
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
    textColor
  }
`;

type Props = {
  isHiddenOnMobile: boolean;
  section: SectionFooterShowroomsContainerFragment;
};

const SectionFooterShowroomsContainer = ({ isHiddenOnMobile, section }: Props) => {
  const data = useStaticQuery<SectionFooterShowroomsContainerQuery>(graphql`
    query SectionFooterShowroomsContainerQuery {
      cms {
        showrooms(sort: "order") {
          ...SectionFooterShowroomsShowroomContainerFragment
          id
          type
        }
      }
    }
  `);

  if (!data.cms.showrooms) {
    throw Error('Something went wrong.');
  }

  const backgroundColor = section.backgroundColor;
  const backgroundImage = getBackgroundImage(section);

  const textColor = section.textColor;

  const showrooms = data.cms.showrooms
    .filter(notNullOrUndefined)
    .filter(filterOutExclusivePartners)
    .map(showroom => <SectionFooterShowroomsShowroomContainer key={showroom.id} showroom={showroom} />);

  const social = <SectionItemFooterSocialContainer textColor={textColor} />;

  return (
    <SectionFooterShowrooms
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      isHiddenOnMobile={isHiddenOnMobile}
      showrooms={showrooms}
      social={social}
      textColor={textColor}
    />
  );
};

export default SectionFooterShowroomsContainer;
