import React from 'react';
import { graphql } from 'gatsby';

import SectionEventsEventsHeadline from '../../../../components/Section/SectionEventsEvents/SectionEventsEventsHeadline';

// Types
import { SectionEventsEventsHeadlineContainerFragment } from './__generated__/SectionEventsEventsHeadlineContainerFragment';

export const fragment = graphql`
  fragment SectionEventsEventsHeadlineContainerFragment on CMS_Section {
    contentPositionY
    headline
    headlineColor
    textAlign
  }
`;

type Props = {
  hasBackgroundImage: boolean;
  section: SectionEventsEventsHeadlineContainerFragment;
};

const SectionEventsEventsHeadlineContainer = ({ hasBackgroundImage, section }: Props) => {
  const headline = section.headline;

  if (typeof headline !== 'string') {
    return null;
  }

  const headlineColor = section.headlineColor;

  return <SectionEventsEventsHeadline hasBackgroundImage={hasBackgroundImage} text={headline} textColor={headlineColor} />;
};

export default SectionEventsEventsHeadlineContainer;
