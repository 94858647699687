import React from 'react';

import * as S from './styles';
import usePermanentToggle from '../../utils/hooks/usePermanentToggle';

const ComingSoonBanner = () => {
  const [showComingSoonBanner, hideIsComingSoonBanner] = usePermanentToggle(true);

  if (!showComingSoonBanner) {
    return null;
  }

  return (
    <S.Wrapper>
      <S.TextWrapper>
        <S.Text>
          Coming
          <br /> Soon
        </S.Text>

        <S.Button onClick={hideIsComingSoonBanner}>X</S.Button>
      </S.TextWrapper>

      <S.Background />
    </S.Wrapper>
  );
};

export default ComingSoonBanner;
