import React from 'react';
import { graphql } from 'gatsby';

import SectionVideoFullScreen from '../../../components/Section/SectionVideoFullScreen';
import { getVideoUrl, getVideoPoster } from '../utils';

// Types
import { SectionVideoFullScreenContainerFragment } from './__generated__/SectionVideoFullScreenContainerFragment';

export const fragment = graphql`
  fragment SectionVideoFullScreenContainerFragment on CMS_Section {
    headline
    headlineColor
    video {
      ...CMSVideoAsset
    }
    videoPoster {
      ...SectionBackgroundImageFragment
    }
  }
`;

type Props = {
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  section: SectionVideoFullScreenContainerFragment;
};

const SectionVideoFullScreenContainer = ({ isHiddenOnMobile, isNeighborClipped, section }: Props) => {
  const textColor = section.headlineColor;
  const title = section.headline || null;
  const videoPoster = getVideoPoster(section);
  const videoUrl = getVideoUrl(section);

  if (!title || !videoUrl) {
    return null;
  }

  return (
    <SectionVideoFullScreen
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      textColor={textColor}
      title={title}
      videoPoster={videoPoster}
      videoUrl={videoUrl}
    />
  );
};

export default SectionVideoFullScreenContainer;
