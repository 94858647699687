import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import CarouselItem from '../../../../components/Carousel/CarouselItem';
import SectionCarouselItem from '../../../../components/Section/SectionCarousel/SectionCarouselItem';
import buildShowroomPageUrl from '../../../../utils/url/buildShowroomPageUrl';

// Types
import { SectionCarouselShowroomsShowroomContainerFragment } from './__generated__/SectionCarouselShowroomsShowroomContainerFragment';

export const fragment = graphql`
  fragment SectionCarouselShowroomsShowroomContainerFragment on CMS_Showroom {
    name
    showroomPage {
      slug
    }
    picture {
      ...CarouselPictureFragment
    }
  }
`;

type Props = {
  showroom: SectionCarouselShowroomsShowroomContainerFragment;
};

const SectionCarouselShowroomsShowroomContainer = ({ showroom }: Props) => {
  const picture = idx(showroom, _ => _.picture.file.childImageSharp.fluid);
  const slug = idx(showroom, _ => _.showroomPage.slug);

  if (!picture || !showroom.name || !slug) {
    return null;
  }

  const url = buildShowroomPageUrl(slug);

  return (
    <CarouselItem>
      <SectionCarouselItem name={showroom.name} picture={picture} url={url} />
    </CarouselItem>
  );
};

export default SectionCarouselShowroomsShowroomContainer;
