import React from 'react';

import * as S from './styles';
import envConfig from '../../../../config/env';

// Types

type Props = {};

const SectionPressDownloadFlag = () => {
  return (
    <>
      {/* UK */}
      <S.Wrapper>
        <S.Language>
          <S.FlagUK />

          <a href={`${envConfig.appUrl}/pdf/jk_showrooms_en_2020-07.pdf`} rel="noopener noreferrer" target="_blank">
            <S.Text> View Complete Text </S.Text>
          </a>
        </S.Language>

        {/* Germany */}
        <S.Language>
          <S.FlagGermany />

          <a href={`${envConfig.appUrl}/pdf/jk_showrooms_de_2020-07.pdf`} rel="noopener noreferrer" target="_blank">
            <S.Text> Kompletten Text ansehen </S.Text>
          </a>
        </S.Language>

        {/* France */}
        <S.Language>
          <S.FlagFrance />

          <a href={`${envConfig.appUrl}/pdf/jk_showrooms_fr_2020-07.pdf`} rel="noopener noreferrer" target="_blank">
            <S.Text> Voir le texte intégral </S.Text>
          </a>
        </S.Language>

        {/* China */}
        <S.Language>
          <S.FlagChina />

          <a href={`${envConfig.appUrl}/pdf/jk_showrooms_cn_2020-07.pdf`} rel="noopener noreferrer" target="_blank">
            <S.Text> 查看全文 </S.Text>
          </a>
        </S.Language>
      </S.Wrapper>
    </>
  );
};

export default SectionPressDownloadFlag;
