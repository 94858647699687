import React from 'react';
import { useFormik } from 'formik';

import FormCheckbox from '../../../Form/FormCheckbox';
import FormInput from '../../../Form/FormInput';
import NewsletterSubscribeButton from '../../SectionItems/NewsletterSubscribeButton';
import routes from '../../../../config/routes';
import useToggle from '../../../../utils/hooks/useToggle';

import * as S from './styles';

const SectionPressContactForm = () => {
  const [isChecked, toggleIsChecked] = useToggle(false);

  const { handleBlur, handleChange, handleSubmit, values } = useFormik({
    initialValues: { name: '', email: '', company: '', concern: '' },
    onSubmit: (filledOutForm: any) => {
      console.log(filledOutForm);
    },
  });

  return (
    <>
      <S.Wrapper>
        <S.Form onSubmit={handleSubmit}>
          <FormInput
            name="Name"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Name *"
            required={true}
            type="text"
            value={values.name}
          />

          <FormInput
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Email *"
            required={true}
            type="email"
            value={values.email}
          />

          <FormInput
            name="company"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Company (optional)"
            type="text"
            value={values.company}
          />

          <S.Textarea name="concern" onBlur={handleBlur} onChange={handleChange} placeholder="Send us a message *" value={values.concern} />

          <S.Margin>
            <FormCheckbox
              title="please provide your information to send us a message"
              label={
                <>
                  I agree to&nbsp;
                  <S.StyledLink href={routes.privacyPolicyEn} target="_blank">
                    Privacy Policy
                  </S.StyledLink>
                  &nbsp;/&nbsp;
                  <S.StyledLink href={routes.privacyPolicyDe} target="_blank">
                    Datenschutz
                  </S.StyledLink>
                </>
              }
              isChecked={isChecked}
              onClick={toggleIsChecked}
            />

            <NewsletterSubscribeButton buttonText="Send" disabled={!isChecked} />
          </S.Margin>
        </S.Form>
      </S.Wrapper>
    </>
  );
};

export default SectionPressContactForm;
