import React from 'react';

import * as S from './styles';

// Types
import { SectionWidth } from '../../../../../types/sections';

type Props = {
  address: string;
  booth: string | null;
  cell: string | null;
  className?: string;
  contentPositionY: string;
  email: string | null;
  endDate: string | null;
  fax: string | null;
  hasBackgroundImage: boolean;
  hours: string | null;
  phone: string | null;
  sectionWidth: SectionWidth;
  startDate: string | null;
  textAlign: string;
  textColor: string;
};

const SectionContentMapAddress = ({
  address,
  cell,
  booth,
  className,
  contentPositionY,
  email,
  endDate,
  fax,
  hasBackgroundImage,
  hours,
  phone,
  sectionWidth,
  startDate,
  textAlign,
  textColor,
}: Props) => {
  return (
    <S.Address
      address={address}
      booth={booth}
      cell={cell}
      className={className}
      contentPositionY={contentPositionY}
      email={email}
      endDate={endDate}
      fax={fax}
      hasBackgroundImage={hasBackgroundImage}
      hours={hours}
      phone={phone}
      sectionWidth={sectionWidth}
      startDate={startDate}
      textAlign={textAlign}
      textColor={textColor}
    />
  );
};

export default SectionContentMapAddress;
