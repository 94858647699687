import idx from 'idx';

import notNullOrUndefined from '../../utils/misc/notNullOrUndefined';

// Types
import { FluidImage } from '../../components/MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import {
  CMS_ENUM_SECTION_CONTENTPOSITIONX,
  CMS_ENUM_SECTION_CONTENTPOSITIONY,
  CMS_ENUM_SECTION_TEXTALIGN,
  CMS_ENUM_SECTION_WIDTH,
} from '../../../types/global';

type GetBackgroundImageInput = {
  backgroundImage?: {
    file?: {
      childImageSharp?: {
        fluid?: FluidImage | null;
      } | null;
    } | null;
  } | null;
};

export const getBackgroundImage = (input: GetBackgroundImageInput) => {
  const backgroundImage = idx(input, _ => _.backgroundImage.file.childImageSharp.fluid);

  if (!backgroundImage) {
    return null;
  }

  return backgroundImage;
};

type GetLogoInput = {
  logo?: {
    file?: {
      childImageSharp?: {
        fluid?: FluidImage | null;
      } | null;
    } | null;
  } | null;
};

export const getLogo = (input: GetLogoInput) => {
  const logo = idx(input, _ => _.logo.file.childImageSharp.fluid);

  if (!logo) {
    return null;
  }

  return logo;
};

type isNeighborSectionClippedInput = {
  index: number;
  sections?: { isClipped?: boolean | null }[];
};

export const isNeighborClipped = ({ index, sections }: isNeighborSectionClippedInput) => {
  if (notNullOrUndefined(sections)) {
    // To make sure the section even can have a neighbor and we're not at the end of the array.
    if (sections.length > index + 1) {
      // Get the next section in the array.
      const nextNeighbor = sections[index + 1];

      // Return `true` if this section is actually clipped.
      if (nextNeighbor.isClipped) {
        return true;
      }
    }
  }

  return false;
};

export const normalizeContentPositionX = (contentPositionX: CMS_ENUM_SECTION_CONTENTPOSITIONX) => {
  switch (contentPositionX) {
    case CMS_ENUM_SECTION_CONTENTPOSITIONX.ELEMENT_POSITION_X_CENTER:
      return 'center';

    case CMS_ENUM_SECTION_CONTENTPOSITIONX.ELEMENT_POSITION_X_LEFT:
      return 'flex-start';

    case CMS_ENUM_SECTION_CONTENTPOSITIONX.ELEMENT_POSITION_X_RIGHT:
      return 'flex-end';

    default:
      throw Error(`Did not expect '${contentPositionX}' as 'contentPositionX'.`);
  }
};

export const normalizeContentPositionY = (contentPositionY: CMS_ENUM_SECTION_CONTENTPOSITIONY) => {
  switch (contentPositionY) {
    case CMS_ENUM_SECTION_CONTENTPOSITIONY.ELEMENT_POSITION_Y_BOTTOM:
      return 'flex-end';

    case CMS_ENUM_SECTION_CONTENTPOSITIONY.ELEMENT_POSITION_Y_MIDDLE:
      return 'center';

    case CMS_ENUM_SECTION_CONTENTPOSITIONY.ELEMENT_POSITION_Y_TOP:
      return 'flex-start';

    default:
      throw Error(`Did not expect '${contentPositionY}' as 'contentPositionY'.`);
  }
};

export const normalizeTextAlign = (textAlign: CMS_ENUM_SECTION_TEXTALIGN) => {
  switch (textAlign) {
    case CMS_ENUM_SECTION_TEXTALIGN.TEXT_ALIGN_CENTER:
      return 'center';

    case CMS_ENUM_SECTION_TEXTALIGN.TEXT_ALIGN_JUSTIFIED:
      return 'justify';

    case CMS_ENUM_SECTION_TEXTALIGN.TEXT_ALIGN_LEFT:
      return 'start';

    case CMS_ENUM_SECTION_TEXTALIGN.TEXT_ALIGN_RIGHT:
      return 'end';

    default:
      throw Error(`Did not expect '${textAlign}' as 'textAlign'.`);
  }
};

export const normalizeSectionWidth = (sectionWidth: CMS_ENUM_SECTION_WIDTH) => {
  switch (sectionWidth) {
    case CMS_ENUM_SECTION_WIDTH.SECTION_WIDTH_100:
      return 100;

    case CMS_ENUM_SECTION_WIDTH.SECTION_WIDTH_75:
      return 75;

    case CMS_ENUM_SECTION_WIDTH.SECTION_WIDTH_50:
      return 50;

    case CMS_ENUM_SECTION_WIDTH.SECTION_WIDTH_25:
      return 25;

    default:
      throw Error(`Did not expect '${sectionWidth}' as 'sectionWidth'.`);
  }
};

type GetVideoUrlInput = {
  video?: {
    file?: {
      publicURL?: string | null;
    } | null;
  } | null;
};

export const getVideoUrl = (input: GetVideoUrlInput) => {
  const videoUrl = idx(input, _ => _.video.file.publicURL);

  if (!videoUrl) {
    return null;
  }

  return videoUrl;
};

type GetVideoPosterInput = {
  videoPoster?: {
    file?: {
      childImageSharp?: {
        fluid?: FluidImage | null;
      } | null;
    } | null;
  } | null;
};

export const getVideoPoster = (input: GetVideoPosterInput) => {
  const videoPoster = idx(input, _ => _.videoPoster.file.childImageSharp.fluid);

  if (!videoPoster) {
    return null;
  }

  return videoPoster;
};
