import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';

export const Title = styled.span`
  ${styleUtils.font.weight.bold};
`;

export const ListItem = styled.li`
  color: ${props => props.theme.color.white};
  font-size: 1rem;
  text-align: start;

  ${styleUtils.font.shadow.default}

  ${styleUtils.media.tablet.small} {
    ${styleUtils.font.shadow.none}
  }

  &:not(:first-of-type) {
    ${styleUtils.padding.vertical.topHalf};
  }
`;
