import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyframes } from '@emotion/core';

import styled from '../../../../utils/styling/styled';

import Link from '../../../Link';

const pulsate = keyframes`
  0% {
   transform: scale(1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
`;

export const Circle = styled(FontAwesomeIcon)`
  color: ${props => props.theme.color.white40};
`;

export const StyledLink = styled(Link)`
  bottom: 6rem;
  height: 32px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  width: 40px;
`;

export const Plus = styled(FontAwesomeIcon)`
  color: ${props => props.theme.color.black80};
`;

export const Pulse = styled.span`
  animation: ${pulsate} 3s ease infinite;
  border-radius: 50%;
  border: solid ${props => props.theme.color.white};
  min-height: 42px;
  min-width: 42px;
`;
