import styled from '../../../../utils/styling/styled';

import SectionItemLogo from '../SectionItemContentLogo';
import styleUtils from '../../../../utils/styling/styleUtils';

export const CollectionLogo = styled(SectionItemLogo)`
  ${styleUtils.media.orientation.landscape} {
    width: 25rem;
  }

  ${styleUtils.media.orientation.portrait} {
    width: 10rem;

    ${styleUtils.media.tablet.small} {
      width: 20rem;
    }
  }
`;
