import React from 'react';
import { graphql } from 'gatsby';

import SectionContentDefault from '../../../components/Section/SectionContentDefault';
import SectionItemContentBackgroundContainer from '../SectionItems/SectionItemContentBackground';
import SectionItemContentHeadlineContainer from '../SectionItems/SectionItemContentHeadline';
import SectionItemContentLogoContainer from '../SectionItems/SectionItemContentLogo';
import SectionItemContentTextContainer from '../SectionItems/SectionItemContentText';
import { getBackgroundImage, normalizeContentPositionX, normalizeContentPositionY } from '../utils';

// Types
import { SectionContentDefaultContainerFragment } from './__generated__/SectionContentDefaultContainerFragment';
import { SectionWidth } from '../../../../types/sections';

export const fragment = graphql`
  fragment SectionContentDefaultContainerFragment on CMS_Section {
    ...SectionItemContentBackgroundContainerFragment
    ...SectionItemContentHeadlineContainerFragment
    ...SectionItemContentLogoContainerFragment
    ...SectionItemContentTextContainerFragment
    backgroundColor
    backgroundPattern
    contentPositionX
    contentPositionY
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
  }
`;

type Props = {
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  isSectionContentExtraWide: boolean;
  section: SectionContentDefaultContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionContentDefaultContainer = ({
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  isSectionContentExtraWide,
  section,
  sectionWidth,
}: Props) => {
  const backgroundImage = getBackgroundImage(section);

  const contentPositionX = normalizeContentPositionX(section.contentPositionX);
  const contentPositionY = normalizeContentPositionY(section.contentPositionY);

  const contentBackground = <SectionItemContentBackgroundContainer section={section} sectionWidth={sectionWidth} />;

  const headline = (
    <SectionItemContentHeadlineContainer contentPositionY={contentPositionY} hasBackgroundImage={!!backgroundImage} section={section} />
  );

  const logo = (
    <SectionItemContentLogoContainer
      contentPositionY={contentPositionY}
      isCritical={isCritical}
      isSectionContentExtraWide={isSectionContentExtraWide}
      section={section}
      sectionWidth={sectionWidth}
    />
  );

  const text = (
    <SectionItemContentTextContainer
      contentPositionY={contentPositionY}
      hasBackgroundImage={!!backgroundImage}
      section={section}
      sectionWidth={sectionWidth}
    />
  );

  const shouldHaveNoPadding = !section.headline && !section.logo && !!section.text && contentPositionY === 'center';

  return (
    <SectionContentDefault
      backgroundColor={section.backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      contentBackground={contentBackground}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      headline={headline}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      logo={logo}
      sectionWidth={sectionWidth}
      shouldHaveNoPadding={shouldHaveNoPadding}
      text={text}
    />
  );
};

export default SectionContentDefaultContainer;
