import css from '@emotion/css';

import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';
import { setTextShadow } from '../../utils';

// Types
import { SectionWidth } from '../../../../../types/sections';

type TextProps = {
  isPortraitVariant?: boolean;
  hasBackgroundImage: boolean;
  textAlign: string;
  textColor: string;
};

export const Text = styled.p<TextProps>`
  ${setTextShadow};

  color: ${props => props.textColor};
  font-size: 1.5rem;
  line-height: 1.1;
  text-transform: uppercase;
  white-space: pre-wrap;

  text-align: ${props => props.textAlign};

  ${styleUtils.media.orientation.landscape} {
    display: ${props => (!props.isPortraitVariant ? 'block' : 'none')};

    ${styleUtils.media.desktop.xsmall} {
      font-size: 1rem;
    }

    ${styleUtils.media.desktop.small} {
      font-size: 1.5rem;
    }
  }

  ${styleUtils.media.orientation.portrait} {
    display: ${props => (!props.isPortraitVariant ? 'none' : 'block')};
    text-align: start;

    ${styleUtils.media.tablet.small} {
      display: ${props => (!props.isPortraitVariant ? 'block' : 'none')};
      text-align: ${props => props.textAlign};
    }
  }
`;

type WrapperProps = {
  contentPositionY: string;
  isSectionContentExtraWide: boolean;
  sectionWidth: SectionWidth;
};

export const Wrapper = styled.div<WrapperProps>`
  z-index: 0;

  ${props =>
    props.contentPositionY === 'flex-end'
      ? css`
          ${styleUtils.margin.vertical.bottomHalf};
        `
      : css`
          ${styleUtils.margin.vertical.topHalf}
        `};
`;
