import React from 'react';
import Map, { MapRef, Marker, NavigationControl } from 'react-map-gl';
import { Loader } from '@googlemaps/js-api-loader';
import { useFormik } from 'formik';

import FormInput from '../../Form/FormInput/FormInput';
import SectionItemStoreLocatorAddress from '../SectionItems/SectionItemStoreLocatorAddress/SectionItemStoreLocatorAddress';
import SectionItemStoreLocatorName from '../SectionItems/SectionItemStoreLocatorName/SectionItemStoreLocatorName';
import envConfig from '../../../config/env';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';
import { NO_SCROLL_ID } from '../../../config/constants';

import SectionStoreLocatorMapMarker from './SectionStoreLocatorMapMarker/SectionStoreLocatorMapMarker';

import { getCountry, getOnlyShowrooms, sortStores } from './utils';
import * as S from './styles';

// Types
import { SectionStoreLocatorContainerQuery_cms_stores } from '../../../containers/Section/SectionStoreLocator/__generated__/SectionStoreLocatorContainerQuery';
import { CMS_ENUM_STORE_TYPE } from '../../../../types/global';

export const HOME_COORDS = { lat: 51.465817, lng: 7.208589 }; // Home
const DEFAULT_ZOOM = 16;

const LOADER = new Loader({
  apiKey: envConfig.googleMapsApiKey,
  libraries: ['geometry'],
  version: 'beta',
});

export type StoreWithDistance = SectionStoreLocatorContainerQuery_cms_stores & { distance: number };
type StoreWithMaybeDistance = SectionStoreLocatorContainerQuery_cms_stores & { distance?: number };

type Props = {
  stores: SectionStoreLocatorContainerQuery_cms_stores[];
};

const SectionStoreLocator = ({ stores }: Props) => {
  const [, /* { latitude, longitude, zoom } */ setViewState] = React.useState({
    longitude: HOME_COORDS.lng,
    latitude: HOME_COORDS.lng,
    zoom: DEFAULT_ZOOM,
  });

  const [sortedStores, setSortedStores] = React.useState<StoreWithMaybeDistance[]>(getOnlyShowrooms(stores));
  const gmaps = React.useRef<typeof google.maps | null>(null);
  const mapRef = React.useRef<MapRef>();
  const [selectedStore, setSelectedStore] = React.useState<StoreWithMaybeDistance | null>(null);

  const loadGmaps = async () => {
    gmaps.current = await LOADER.load().then(google => google.maps);
  };

  const flyTo = ({ lat, lng }: { lat: number; lng: number }, zoom = DEFAULT_ZOOM) => {
    if (!notNullOrUndefined(mapRef.current)) {
      return;
    }

    mapRef.current.flyTo({ center: [lng, lat], duration: 3500, zoom });
  };

  const createHandleSelectStore = (store: StoreWithMaybeDistance) => () => {
    flyTo({ lat: store.lat, lng: store.lng });

    setSelectedStore(store);
  };

  const createHandleStoreMarkerSelect = (store: StoreWithMaybeDistance) => (_event: any) => {
    setSelectedStore(store);

    const element = document.getElementById(`store-${store.id}`);

    if (!notNullOrUndefined(element)) {
      return;
    }

    element.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSortStores = async (coordinates = HOME_COORDS, country: string | null) => {
    if (!notNullOrUndefined(gmaps.current)) {
      return;
    }

    const mappedStores = stores.map(store => {
      return {
        ...store,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        distance: gmaps.current!.geometry.spherical.computeDistanceBetween(coordinates, { lat: store.lat, lng: store.lng }),
      };
    });

    const { filteredStores, nextSortedStores } = sortStores(mappedStores, country);

    if (filteredStores.length === 0) {
      setSortedStores(nextSortedStores.slice(0, 10));
    } else {
      setSortedStores(filteredStores);
    }
  };

  const { handleBlur, handleChange, handleSubmit, values } = useFormik({
    initialValues: { search: '' },
    onSubmit: async formValues => {
      if (!notNullOrUndefined(gmaps.current)) {
        return;
      }

      const geocoder = new gmaps.current.Geocoder();

      geocoder.geocode({ address: formValues.search }, result => {
        if (!notNullOrUndefined(result) || result.length === 0) {
          return;
        }

        const [firstResult] = result;

        const coordinates = firstResult.geometry.location.toJSON();

        // const country =

        const searchedCountry = getCountry(firstResult);

        handleSortStores(coordinates, searchedCountry);
        flyTo(coordinates, 8);
      });
    },
  });

  React.useEffect(() => {
    loadGmaps();
  }, []);

  const finalStores = sortedStores.length > 0 ? sortedStores : stores;
  const mapStores = stores.slice();

  return (
    <S.Section
      backgroundColor="#606060"
      contentPositionX="middle"
      contentPositionY="center"
      isHiddenOnMobile={false}
      isNeighborClipped={false}
      isNotClipable={true}
      sectionWidth={100}
    >
      <S.FormWrapper isMobileVersion={true}>
        <S.Headline>Store Locator</S.Headline>

        <S.Form noValidate={true} onSubmit={handleSubmit}>
          <FormInput
            disabled={false}
            name="search"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Enter your address, city, or postal code."
            required={true}
            type="text"
            value={values.search}
          />
        </S.Form>
      </S.FormWrapper>

      <S.ListingsWrapper isMobileVersion={false}>
        <S.FormWrapper isMobileVersion={false}>
          <S.Headline>Store Locator</S.Headline>

          <S.Form noValidate={true} onSubmit={handleSubmit}>
            <FormInput
              disabled={false}
              name="search"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="Enter your address, city, or postal code."
              required={true}
              type="text"
              value={values.search}
            />
          </S.Form>
        </S.FormWrapper>

        <S.ListWrapper>
          {/* @ts-ignore */}
          <S.StoreList id={NO_SCROLL_ID}>
            {finalStores.map(store => {
              return (
                <S.StoreItem id={NO_SCROLL_ID} key={store.id}>
                  <SectionItemStoreLocatorName
                    id={`store-${store.id}`}
                    isActive={(selectedStore || {}).id === store.id}
                    name={store.nameDisplay}
                    onClick={createHandleSelectStore(store)}
                  />

                  <SectionItemStoreLocatorAddress
                    address={store.address}
                    continent={store.continent}
                    country={store.country}
                    email={store.email}
                    isAppointmentOnly={store.appointmentOnly || false}
                    phone={store.phone}
                    website={store.website}
                  />
                </S.StoreItem>
              );
            })}
          </S.StoreList>
        </S.ListWrapper>
      </S.ListingsWrapper>

      <S.MapWrapper id={NO_SCROLL_ID}>
        <Map
          cooperativeGestures={true}
          initialViewState={{ longitude: HOME_COORDS.lng, latitude: HOME_COORDS.lat, zoom: DEFAULT_ZOOM }}
          mapStyle="mapbox://styles/mapbox/streets-v12"
          mapboxAccessToken={envConfig.mapboxApiKey}
          onMove={evt => {
            setViewState(evt.viewState);
          }}
          // @ts-ignore
          ref={mapRef}
          style={{ position: 'relative' }}
        >
          {mapStores.map(store => {
            return (
              <Marker
                anchor="bottom"
                key={store.id}
                latitude={store.lat}
                longitude={store.lng}
                onClick={createHandleStoreMarkerSelect(store)}
                pitchAlignment="map"
              >
                <SectionStoreLocatorMapMarker variant={store.type === CMS_ENUM_STORE_TYPE.SHOWROOM_OR_STORE ? 'showroom' : 'partner'} />
              </Marker>
            );
          })}

          <NavigationControl />
        </Map>
      </S.MapWrapper>
    </S.Section>
  );
};

export default SectionStoreLocator;
