import React from 'react';

import Heading from '../../Heading';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../../types/global';
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type Props = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  logo: ReactNode;
  sectionWidth: SectionWidth;
  splitHeadline: ReactNode;
  splitText: ReactNode;
  textColor: string;
  url: string | null;
};

const SectionHomeEventTeaser = ({
  backgroundColor,
  backgroundImage,
  backgroundPattern,
  contentPositionX,
  contentPositionY,
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  logo,
  sectionWidth,
  textColor,
  url,
}: Props) => {
  return (
    <S.Section
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={sectionWidth}
    >
      <Heading isVisuallyHidden={true}>Family Affair</Heading>

      {logo}

      <S.ViewEventLink textColor={textColor} title="view event" url={url}>
        <S.ViewEventText>View Event</S.ViewEventText>

        <S.ViewEventChevron id="svg" />
      </S.ViewEventLink>
    </S.Section>
  );
};

export default SectionHomeEventTeaser;
