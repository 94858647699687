import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import SectionItemMap from '../../../../components/Section/SectionItems/SectionItemMap';

// Types
import { SectionItemContentMapContainerFragment } from './__generated__/SectionItemContentMapContainerFragment';

export const fragment = graphql`
  fragment SectionItemContentMapContainerFragment on CMS_Section {
    mapEvent {
      lat
      lng
    }
  }
`;

type Props = {
  section: SectionItemContentMapContainerFragment;
};

const SectionItemContentMapContainer = ({ section }: Props) => {
  const lat = idx(section, _ => _.mapEvent.lat);
  const lng = idx(section, _ => _.mapEvent.lng);

  if (typeof lat !== 'number' || typeof lng !== 'number') {
    return null;
  }

  return <SectionItemMap lat={lat} lng={lng} />;
};

export default SectionItemContentMapContainer;
