import React from 'react';

import splitNewlineToBreak from '../../../utils/string/splitNewlineToBreak';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../../types/global';
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';

type Props = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  hasExtraMarginTop: boolean;
  headline: string | null;
  headlineColor: string;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  text: string | null;
  textColor: string;
};

const SectionCollectionTextIntro = ({
  backgroundColor,
  backgroundImage,
  backgroundPattern,
  hasExtraMarginTop,
  headline,
  headlineColor,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  text,
  textColor,
}: Props) => {
  const splitHeadline = splitNewlineToBreak(headline);

  return (
    <S.Section
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={backgroundPattern}
      contentPositionX="flex-start"
      contentPositionY="flex-start"
      hasExtraMarginTop={hasExtraMarginTop}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={isClipped || isNeighborClipped ? 50 : 25}
      shouldNotMakeWiderIfNeighborIsClipped={true}
    >
      <S.Headline textColor={headlineColor}>{splitHeadline}</S.Headline>
      <S.Content textColor={textColor}>{text}</S.Content>
    </S.Section>
  );
};

export default SectionCollectionTextIntro;
