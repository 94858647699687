import React from 'react';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../../types/global';
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type Props = {
  address: ReactNode;
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  contentBackground: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  headline: ReactNode;
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  sectionWidth: SectionWidth;
};

const SectionShowroomIntro = ({
  address,
  backgroundColor,
  backgroundImage,
  backgroundPattern,
  contentBackground,
  contentPositionX,
  contentPositionY,
  headline,
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  sectionWidth,
}: Props) => {
  return (
    <S.Section
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={sectionWidth}
    >
      {contentBackground}

      {headline}

      {address}
    </S.Section>
  );
};

export default SectionShowroomIntro;
