import React from 'react';
import { graphql } from 'gatsby';

import SectionRugList from '../../../components/Section/SectionRugList';
import SectionRugListRugContainer from './SectionRugListRug';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';
import { getBackgroundImage } from '../utils';

// Types
import { SectionRugListContainerFragment } from './__generated__/SectionRugListContainerFragment';
import { SectionWidth } from '../../../../types/sections';

export const fragment = graphql`
  fragment SectionRugListContainerFragment on CMS_Section {
    backgroundColor
    backgroundPattern
    textColor
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
    rugList(sort: "order") {
      ...SectionRugListRugContainerFragment
      id
    }
  }
`;

type Props = {
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  section: SectionRugListContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionRugListContainer = ({ index, isClipped, isCritical, isHiddenOnMobile, isNeighborClipped, section, sectionWidth }: Props) => {
  const backgroundColor = section.backgroundColor;
  const backgroundImage = getBackgroundImage(section);

  const mappedRugs = (section.rugList || [])
    .filter(notNullOrUndefined)
    .map(rug => <SectionRugListRugContainer isCritical={isCritical} key={rug.id} rug={rug} textColor={section.textColor} />);

  return (
    <SectionRugList
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      rugs={mappedRugs}
      sectionWidth={sectionWidth}
    />
  );
};

export default SectionRugListContainer;
