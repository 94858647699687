import React from 'react';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  className?: string;
  isVisible: boolean;
  regionName: string;
  storeLists: ReactNode;
};

const GlobalDistributionOverlay = ({ className, isVisible, regionName, storeLists }: Props) => {
  return (
    <S.Wrapper className={className} isVisible={isVisible}>
      <S.RegionName>{regionName}</S.RegionName>

      <S.StoreLists>{storeLists}</S.StoreLists>
    </S.Wrapper>
  );
};

export default GlobalDistributionOverlay;
