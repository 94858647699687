import React from 'react';
import { graphql } from 'gatsby';

import SectionCollectionTextIntro from '../../../components/Section/SectionCollectionTextIntro';
import { getBackgroundImage } from '../utils';

// Types
import { SectionCollectionTextIntroContainerFragment } from './__generated__/SectionCollectionTextIntroContainerFragment';

export const fragment = graphql`
  fragment SectionCollectionTextIntroContainerFragment on CMS_Section {
    backgroundColor
    headline
    headlineColor
    text
    textColor
    backgroundPattern
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
  }
`;

type Props = {
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  section: SectionCollectionTextIntroContainerFragment;
};

const SectionCollectionTextIntroContainer = ({ index, isClipped, isCritical, isHiddenOnMobile, isNeighborClipped, section }: Props) => {
  const backgroundImage = getBackgroundImage(section);

  return (
    <SectionCollectionTextIntro
      backgroundColor={section.backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      hasExtraMarginTop={index === 0}
      headline={section.headline}
      headlineColor={section.headlineColor}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      text={section.text || ''}
      textColor={section.textColor}
    />
  );
};

export default SectionCollectionTextIntroContainer;
