import styled from '../../../../../utils/styling/styled';

import styleUtils from '../../../../../utils/styling/styleUtils';

type HeadlineProps = {
  textColor: string;
};

export const Headline = styled.p<HeadlineProps>`
  ${styleUtils.font.weight.bold};

  color: ${props => props.textColor};
  line-height: 1;
  text-align: start;
  text-transform: uppercase;
  white-space: pre-wrap;

  font-size: 3rem;

  ${styleUtils.media.tablet.large} {
    font-size: 4rem;
  }

  ${styleUtils.media.desktop.small} {
    font-size: 6rem;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;
