import css from '@emotion/css';

import styled from '../../../../utils/styling/styled';

import MediaImageFluid from '../../../MediaImage/MediaImageFluid';
import RugItemButton from '../../../Rug/RugItems/RugItemButton';
import createOmitProps from '../../../../utils/styling/createOmitProps';
import styleUtils from '../../../../utils/styling/styleUtils';
import { CLIPPED_ANGLE } from '../../../../config/constants';

type ButtonProps = {
  isClipped?: boolean | null;
  isNeighborClipped?: boolean | null;
};

export const Button = styled(RugItemButton, { shouldForwardProp: createOmitProps(['isClipped', 'isNeighborClipped']) })<ButtonProps>`
  z-index: 1;

  ${props =>
    props.isClipped &&
    !props.isNeighborClipped &&
    css`
      ${styleUtils.specificity.increaseSpecificity} {
        padding-left: ${CLIPPED_ANGLE}rem;
      }
    `}

  ${props =>
    !props.isClipped &&
    props.isNeighborClipped &&
    css`
      ${styleUtils.specificity.increaseSpecificity} {
        left: auto;
        padding-right: ${CLIPPED_ANGLE}rem;
        right: 50%;
      }
    `}
`;

type PictureProps = {
  isClipped?: boolean | null;
};

export const Picture = styled(MediaImageFluid)<PictureProps>`
  width: 100%;

  ${styleUtils.media.orientation.landscape} {
    flex: 1 1 auto;
    height: 100vh;
  }

  ${styleUtils.media.orientation.portrait} {
    transform: ${props => (!props.isClipped ? 'scale(1.5)' : 'scale(1.8)')};
  }
`;

export const Name = styled.p`
  ${styleUtils.margin.vertical.topQuarter};
  ${styleUtils.margin.horizontal.leftHalf};

  color: white;
  text-transform: uppercase;
`;

export const Wrapper = styled.div`
  /* Flex Container */
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
  position: relative;

  ${styleUtils.media.orientation.portrait} {
    display: none;
    height: 30vh;
    width: 100vw;
  }

  ${styleUtils.media.orientation.landscape} {
    flex: 1 1 auto;
    max-height: 100vh;
  }
`;
