import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Carousel from '../../../components/Carousel/Carousel';
import SectionCarousel from '../../../components/Section/SectionCarousel';
import SectionCarouselShowroomsShowroomContainer from './SectionCarouselShowroomsShowroom';
import filterOutExclusivePartners from '../../../utils/showroom/filterOutExclusivePartners';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';

// Types
import { SectionCarouselShowroomsContainerQuery } from './__generated__/SectionCarouselShowroomsContainerQuery';

type Props = {
  isHiddenOnMobile: boolean;
};

const SectionCarouselShowroomsContainer = ({ isHiddenOnMobile }: Props) => {
  const data = useStaticQuery<SectionCarouselShowroomsContainerQuery>(graphql`
    query SectionCarouselShowroomsContainerQuery {
      cms {
        showrooms(sort: "order") {
          ...SectionCarouselShowroomsShowroomContainerFragment
          id
          type
        }
      }
    }
  `);

  if (!data.cms.showrooms) {
    throw Error('Something went wrong.');
  }

  const filteredShowrooms = data.cms.showrooms.filter(notNullOrUndefined).filter(filterOutExclusivePartners);

  const carousel = (
    <Carousel
      renderItem={(item, index) => <SectionCarouselShowroomsShowroomContainer key={`${item.id}-${index}`} showroom={item} />}
      slides={filteredShowrooms}
    />
  );

  return <SectionCarousel carousel={carousel} isHiddenOnMobile={isHiddenOnMobile} title="Showrooms" />;
};

export default SectionCarouselShowroomsContainer;
