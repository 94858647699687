import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';
import { removeTopMarginIfMultipleChildren } from '../utils';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type SectionProps = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
  shouldHaveNoPadding?: boolean;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${removeTopMarginIfMultipleChildren};

  /* Flex Container */
  align-items: ${props => props.contentPositionX};
  flex-direction: column;
  justify-content: ${props => props.contentPositionY};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.vertical.topDouble};
    ${styleUtils.padding.vertical.bottom};

    ${styleUtils.specificity.increaseSpecificity} {
      ${props => (props.shouldHaveNoPadding ? `padding: 0;` : ` `)}
    }
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.both};

    align-items: flex-start;

    ${styleUtils.media.tablet.small} {
      align-items: ${props => props.contentPositionX};
    }
  }
`;
