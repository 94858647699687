import React from 'react';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  className?: string;
  stores: ReactNode;
  storesType: string;
  textColor: string;
};

const GlobalDistributionOverlayStoreList = ({ className, stores, storesType, textColor }: Props) => {
  return (
    <S.Wrapper className={className}>
      <S.Type textColor={textColor}>{storesType}</S.Type>

      <S.StoreList>{stores}</S.StoreList>
    </S.Wrapper>
  );
};

export default GlobalDistributionOverlayStoreList;
