import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ComingSoonBanner from '../../components/ComingSoonBanner/ComingSoonBanner';
import Heading from '../../components/Heading';
import HomePageBreadcrumb from '../../utils/seo/HomePageBreadcrumb';
import Section from '../../components/Heading/Section';
import SectionContainer from '../Section';
import notNullOrUndefined from '../../utils/misc/notNullOrUndefined';
import usePermanentToggle from '../../utils/hooks/usePermanentToggle';
import { isNeighborClipped } from '../Section/utils';

// Types
import { HomePageContainerQuery } from './__generated__/HomePageContainerQuery';

type Props = {};

const HomePageContainer = (_props: Props) => {
  const [showComingSoonBanner, showIsComingSoonBanner] = usePermanentToggle(false);

  const data = useStaticQuery<HomePageContainerQuery>(graphql`
    query HomePageContainerQuery {
      cms {
        contentpage(id: "1") {
          id
          title
          sections(sort: "order") {
            ...SectionContainerFragment
            id
          }
        }
      }
    }
  `);

  const homePage = data.cms.contentpage;

  if (!homePage) {
    throw Error(`No content page with id '1' found. This needs to exist.`);
  }

  const mappedSections = (homePage.sections || []).filter(notNullOrUndefined).map((section, index, sections) => (
    <SectionContainer
      index={index}
      // All sections should load eagerly.
      isCritical={true}
      isNeighborClipped={isNeighborClipped({ index, sections })}
      key={section.id}
      section={section}
    />
  ));

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);

      const comingSoon = params.get('cs');

      if (comingSoon === 'true') {
        showIsComingSoonBanner();
      }
    }
  }, []);

  return (
    <>
      {/* SEO */}
      <HomePageBreadcrumb />

      <Heading isVisuallyHidden={true}>Homepage</Heading>

      {showComingSoonBanner && <ComingSoonBanner />}

      <Section hasNoTag={true}>{mappedSections}</Section>
    </>
  );
};

export default HomePageContainer;
