import css from '@emotion/css';

import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';
import { CLIPPED_ANGLE } from '../../../../config/constants';

// Types
import { SectionWidth } from '../../../../../types/sections';

type ContentBackgroundProps = {
  contentPositionX: string;
  sectionWidth: SectionWidth;
};

const setDisplay = ({ contentPositionX }: ContentBackgroundProps) => {
  if (contentPositionX === 'flex-start' || contentPositionX === 'flex-end') {
    return '';
  }

  return css`
    display: none;
  `;
};

const setPositionX = ({ contentPositionX }: ContentBackgroundProps) => {
  if (contentPositionX === 'flex-start') {
    return css`
      left: 0;
    `;
  }

  if (contentPositionX === 'flex-end') {
    return css`
      right: 0;
    `;
  }

  return '';
};

const setClipPath = ({ contentPositionX }: ContentBackgroundProps) => {
  if (contentPositionX === 'flex-start') {
    return css`
      clip-path: polygon(0 0, 100% 0, ${CLIPPED_ANGLE * 1.5}rem 100%, 0% 100%);

      ${styleUtils.media.ie11} {
        clip-path: none;
      }

      ${styleUtils.media.edge} {
        clip-path: none;
      }
    `;
  }

  if (contentPositionX === 'flex-end') {
    return css`
      clip-path: polygon(${CLIPPED_ANGLE}rem 0, 100% 0, 100% 100%, 0% 100%);

      ${styleUtils.media.ie11} {
        clip-path: none;
      }

      ${styleUtils.media.edge} {
        clip-path: none;
      }
    `;
  }

  return '';
};

const setWidth = ({ sectionWidth }: ContentBackgroundProps) => {
  switch (sectionWidth) {
    case 25:
      return css`
        width: 75%;
      `;

    case 50:
    case 75:
      return css`
        width: 50%;
      `;

    case 100:
      return css`
        width: 30%;
      `;
  }
};

export const ContentBackground = styled.div<ContentBackgroundProps>`
  height: 100%;
  position: absolute;
  top: 0;

  ${styleUtils.media.orientation.landscape} {
    ${setClipPath};
    ${setDisplay};
    ${setPositionX};
    ${setWidth};

    background-color: ${props => props.theme.color.trueBlack40};
  }

  ${styleUtils.media.orientation.portrait} {
    background-color: ${props => props.theme.color.trueBlack30};
    right: 0;
    width: 100%;
  }
`;
