import React from 'react';
import { useFormik } from 'formik';
import axios from 'axios';

import FormCheckbox from '../../../Form/FormCheckbox';
import FormInput from '../../../Form/FormInput';
import NewsletterSubscribeButton from '../../SectionItems/NewsletterSubscribeButton';
import routes from '../../../../config/routes';
import useToggle from '../../../../utils/hooks/useToggle';

import * as S from './styles';

const SectionEventContactForm = () => {
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [isChecked, toggleIsChecked] = useToggle(false);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { handleBlur, handleChange, handleSubmit, values } = useFormik({
    initialValues: { company: '', concern: '', email: '', name: '' },
    onSubmit: async (filledOutForm, { resetForm }) => {
      setIsSubmitting(true);

      try {
        const formData = new FormData();
        formData.append('Name', filledOutForm.name);
        formData.append('company', filledOutForm.company);
        formData.append('message', filledOutForm.concern);
        formData.append('email', filledOutForm.email);

        await axios.post('https://mailform.jan-kath.com/sendMail.php', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setIsSubmitting(false);

        setHasSubmitted(true);
        resetForm();
        toggleIsChecked();
      } catch (err) {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <S.Wrapper>
      {hasSubmitted && <S.Sent>Your request has been sent. We&apos;ll contact you shortly.</S.Sent>}

      {!hasSubmitted && (
        <S.Form onSubmit={handleSubmit}>
          <FormInput
            disabled={isSubmitting}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Name *"
            required={true}
            type="text"
            value={values.name}
          />

          <FormInput
            disabled={isSubmitting}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Email *"
            required={true}
            type="email"
            value={values.email}
          />

          <FormInput
            disabled={isSubmitting}
            name="company"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Company (optional)"
            type="text"
            value={values.company}
          />

          <S.Textarea
            disabled={isSubmitting}
            name="concern"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder="Send us a message *"
            value={values.concern}
          />

          <S.Margin>
            <FormCheckbox
              title="please provide your information to send us a message"
              label={
                <>
                  I agree to&nbsp;
                  <S.StyledLink href={routes.privacyPolicyEn} target="_blank">
                    Privacy Policy
                  </S.StyledLink>
                  &nbsp;/&nbsp;
                  <S.StyledLink href={routes.privacyPolicyDe} target="_blank">
                    Datenschutz
                  </S.StyledLink>
                </>
              }
              isChecked={isChecked}
              onClick={toggleIsChecked}
            />

            <NewsletterSubscribeButton buttonText="Send" disabled={!isChecked || isSubmitting} />
          </S.Margin>
        </S.Form>
      )}
    </S.Wrapper>
  );
};

export default SectionEventContactForm;
