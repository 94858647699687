import React from 'react';

import formatEventDate from '../../../../utils/time/formatEventDate';
import Link from '../../../Link';

import * as S from './styles';

type Props = {
  booth: string;
  city: string;
  country: string;
  endDate: string;
  name: string;
  startDate: string;
  website: string | null;
};

const SectionEventsEventsEvent = ({ booth, city, country, endDate, name, startDate, website }: Props) => {
  const formattedEventDate = formatEventDate({ endDate, startDate });

  return (
    <S.TableRow>
      <S.TableCell>
        <Link asExternal={true} url={website}>
          {name}
        </Link>
      </S.TableCell>
      <S.TableCell>{city}</S.TableCell>
      <S.TableCell>{country}</S.TableCell>
      <S.TableCell>{booth}</S.TableCell>
      <S.TableCell>{formattedEventDate}</S.TableCell>
    </S.TableRow>
  );
};

export default SectionEventsEventsEvent;
