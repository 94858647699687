import React from 'react';

import HomePageContainer from '../containers/HomePage';
import LayoutContainer from '../containers/Layout';

const IndexPage = () => {
  return (
    <LayoutContainer>
      <HomePageContainer />
    </LayoutContainer>
  );
};

export default IndexPage;
