import styled from '../../../../utils/styling/styled';

import FormInput from '../../../Form/FormInput';
import Link from '../../../Link';
import styleUtils from '../../../../utils/styling/styleUtils';

export const Button = styled.button`
  ${styleUtils.animation.searchButtonHover};
  ${styleUtils.margin.vertical.topQuarter};

  background-color: ${props => props.theme.color.brand};
  border-left: 0;
  border: 1px solid ${props => props.theme.color.brand};
  padding: 0.5rem 1rem;
  text-align: center;
`;

export const Form = styled.form`
  align-self: center;
  margin-bottom: 10px;
  text-align: center;
`;

export const Input = styled(FormInput)`
  padding: 0.5rem 0.75rem;
  width: 25rem;
`;

export const StyledLink = styled(Link)`
  &:hover {
    text-decoration: underline;
  }
`;
