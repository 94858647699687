import React from 'react';

// Types
import { SVGAttributes } from 'react';

type Props = {} & SVGAttributes<SVGElement>;

const FlagUK = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600" {...props}>
      <path fill="none" d="M-1-1h602v602H-1z" />
      <g>
        <g clipPath="url(#SVGID_2_)">
          <path fill="#012169" d="M-300 0v600H900V0H-300z" />
          <path stroke="#fff" strokeMiterlimit="80" strokeWidth="120" d="M-300 0L900 600m0-600L-300 600" />
          <path stroke="#c8102e" strokeMiterlimit="80" strokeWidth="80" d="M-300 0L900 600m0-600L-300 600" clipPath="url(#SVGID_4_)" />
          <path stroke="#fff" strokeMiterlimit="80" strokeWidth="200" d="M300 0v600m-600-300H900" />
          <path stroke="#c8102e" strokeMiterlimit="80" strokeWidth="120" d="M300 0v600m-600-300H900" />
        </g>
      </g>
    </svg>
  );
};

export default FlagUK;
