import styled from '../../../../../../utils/styling/styled';

import styleUtils from '../../../../../../utils/styling/styleUtils';

type TypeProps = {
  textColor: string;
};

export const Type = styled.span<TypeProps>`
  ${styleUtils.font.shadow.default};

  color: ${props => props.textColor};
  white-space: nowrap;
`;

export const StoreList = styled.ul`
  /* Flex Container */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Wrapper = styled.div`
  /* Flex Container */
  display: flex;
  flex-direction: column;

  margin-right: 1rem;
`;
