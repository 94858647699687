import styleUtils from '../../../../utils/styling/styleUtils';
import styled from '../../../../utils/styling/styled';

export const Button = styled.a`
  ${styleUtils.font.weight.bold};

  padding-bottom: 0.2rem;
  padding-top: 0.3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;

  background-color: ${props => props.theme.color.brand};
  color: white;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  white-space: pre-wrap;
`;
