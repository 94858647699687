import React from 'react';
import idx from 'idx';
import { graphql, useStaticQuery } from 'gatsby';

import SectionFooterContact from '../../../components/Section/SectionFooterContact';
import SectionFooterContactContact from '../../../components/Section/SectionFooterContact/SectionFooterContactContact';
import SectionItemFooterSocialContainer from '../SectionItems/SectionItemFooterSocial';
import { getBackgroundImage } from '../utils';

// Types
import { SectionFooterContactContainerQuery } from './__generated__/SectionFooterContactContainerQuery';
import { SectionFooterContactContainerFragment } from './__generated__/SectionFooterContactContainerFragment';

export const fragment = graphql`
  fragment SectionFooterContactContainerFragment on CMS_Section {
    backgroundColor
    backgroundPattern
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
    textColor
  }
`;

type Props = {
  isHiddenOnMobile: boolean;
  section: SectionFooterContactContainerFragment;
};

const SectionFooterContactContainer = ({ isHiddenOnMobile, section }: Props) => {
  const data = useStaticQuery<SectionFooterContactContainerQuery>(graphql`
    query SectionFooterContactContainerQuery {
      site {
        siteMetadata {
          contact {
            hq {
              cell
              city
              country
              email
              fax
              name
              phone
              postalCode
              street
              streetNumber
            }
            press {
              cell
              city
              country
              email
              fax
              name
              phone
              postalCode
              street
              streetNumber
            }
          }
        }
      }
    }
  `);

  const backgroundColor = section.backgroundColor;
  const backgroundImage = getBackgroundImage(section);

  const hqContactData = idx(data, _ => _.site.siteMetadata.contact.hq) || null;
  const pressContactData = idx(data, _ => _.site.siteMetadata.contact.press) || null;

  if ((!backgroundColor && !backgroundImage) || (!hqContactData && !pressContactData)) {
    return null;
  }

  const textColor = section.textColor || '#FFFFFF';

  const social = <SectionItemFooterSocialContainer textColor={textColor} />;

  const hqContact = !!hqContactData ? (
    <SectionFooterContactContact
      cell={hqContactData.cell}
      city={hqContactData.city}
      country={hqContactData.country}
      email={hqContactData.email}
      fax={hqContactData.fax}
      headline={hqContactData.name}
      name={null}
      phone={hqContactData.phone}
      postalCode={hqContactData.postalCode}
      street={hqContactData.street}
      streetNumber={hqContactData.streetNumber}
      textColor={textColor}
    />
  ) : null;

  const pressContact = !!pressContactData ? (
    <SectionFooterContactContact
      cell={pressContactData.cell}
      city={pressContactData.city}
      country={pressContactData.country}
      email={pressContactData.email}
      fax={pressContactData.fax}
      headline="Press Contact"
      name={pressContactData.name}
      phone={pressContactData.phone}
      postalCode={pressContactData.postalCode}
      street={pressContactData.street}
      streetNumber={pressContactData.streetNumber}
      textColor={textColor}
    />
  ) : null;

  return (
    <SectionFooterContact
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      contact={
        <>
          {hqContact}
          {pressContact}
        </>
      }
      isHiddenOnMobile={isHiddenOnMobile}
      social={social}
      textColor={textColor}
    />
  );
};

export default SectionFooterContactContainer;
