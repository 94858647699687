import css from '@emotion/css';

import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';
import { CLIPPED_ANGLE } from '../../../config/constants';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type HeadlineProps = {
  textColor: string;
};

export const Headline = styled.p<HeadlineProps>`
  ${styleUtils.font.weight.bold};

  color: ${props => props.textColor};
  line-height: 0.9;
  margin-right: 0.5rem;
  text-transform: uppercase;
  word-break: keep-all;

  font-size: 4rem;

  ${styleUtils.media.tablet.small} {
    font-size: 6rem;
  }

  ${styleUtils.media.desktop.small} {
    font-size: 8rem;
  }

  ${styleUtils.media.orientation.portrait} {
    width: 45%;
  }
`;

type ContentProps = {
  textColor: string;
};

export const Content = styled.p<ContentProps>`
  ${styleUtils.margin.vertical.top};
  ${styleUtils.misc.frame};

  color: ${props => props.textColor};
  font-size: 1.3rem;
  text-transform: uppercase;

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.margin.vertical.topDouble};

    width: 100%;

    ${styleUtils.media.tablet.small} {
      ${styleUtils.margin.vertical.topNone};

      width: 45%;
    }
  }
`;

type SectionProps = {
  backgroundImage: FluidImage | null;
  backgroundColor: string | null;
  hasExtraMarginTop: boolean;
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  /* Flex Container */
  align-items: ${props => props.contentPositionX};
  flex-direction: column;
  justify-content: ${props => props.contentPositionY};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.bothQuadruple};

    min-width: 40rem;

    ${props =>
      props.isClipped &&
      css`
        && {
          padding-left: ${CLIPPED_ANGLE}rem;
        }
      `}

    ${props =>
      props.isNeighborClipped &&
      css`
        && {
          padding-right: ${CLIPPED_ANGLE}rem;
        }
      `}
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.both};
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.both};

    /* Flex Container */
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    ${styleUtils.media.tablet.small} {
      /* Flex Container */
      align-items: center;
      flex-direction: row;
    }

    ${props => props.hasExtraMarginTop && styleUtils.padding.vertical.topDouble}
  }
`;
