import React from 'react';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import SectionItemProductImage from '../../../../components/Section/SectionItems/SectionItemProductImage';
import { getLogo } from '../../utils';

// Types
import { SectionItemProductImageContainerFragment } from './__generated__/SectionItemProductImageContainerFragment';
import { SectionWidth } from '../../../../../types/sections';

export const fragment = graphql`
  fragment SectionItemProductImageContainerFragment on CMS_Section {
    logo {
      ...SectionLogoPictureFragment
    }
  }
`;

type Props = {
  contentPositionY: string;
  isCritical: boolean;
  isSectionContentExtraWide: boolean;
  section: SectionItemProductImageContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionItemProductImageContainer = ({ contentPositionY, isCritical, isSectionContentExtraWide, section, sectionWidth }: Props) => {
  const [ref, isInView] = useInView({ threshold: 0, triggerOnce: true });

  const logo = getLogo(section);

  if (!logo) {
    return null;
  }

  return (
    <SectionItemProductImage
      contentPositionY={contentPositionY}
      isCritical={isCritical}
      isInView={isInView}
      isSectionContentExtraWide={isSectionContentExtraWide}
      logo={logo}
      ref={ref}
      sectionWidth={sectionWidth}
    />
  );
};

export default SectionItemProductImageContainer;
