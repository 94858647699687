import React from 'react';

import MediaImageFluid from '../../../MediaImage/MediaImageFluid';

import * as S from './styles';

// Types
import { FluidImage } from '../../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { HTMLAttributes } from 'react';
import { SectionWidth } from '../../../../../types/sections';

type Props = {
  alt?: string;
  contentPositionY: string;
  isCritical: boolean;
  isInView: boolean;
  isSectionContentExtraWide: boolean;
  logo: FluidImage;
  sectionWidth: SectionWidth;
} & HTMLAttributes<HTMLDivElement>;

const SectionItemContentLogo = React.forwardRef<HTMLDivElement, Props>(
  ({ alt, contentPositionY, isCritical, isSectionContentExtraWide, logo, sectionWidth, ...rest }: Props, ref) => {
    return (
      <S.Wrapper
        contentPositionY={contentPositionY}
        isSectionContentExtraWide={isSectionContentExtraWide}
        sectionWidth={sectionWidth}
        ref={ref}
        {...rest}
      >
        <MediaImageFluid alt={alt} loading={isCritical ? 'eager' : undefined} fadeIn={!isCritical} image={logo} />
      </S.Wrapper>
    );
  },
);

export default SectionItemContentLogo;
