import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import CollectionPageSectionRugListRug from '../../../../components/Section/SectionRugList/SectionRugListRug';
import buildCollectionOverviewRugLink from '../../../../utils/url/buildCollectionOverviewRugLink';
import buildRugName from '../../../../utils/rug/buildRugName';

// Types
import { SectionRugListRugContainerFragment } from './__generated__/SectionRugListRugContainerFragment';

export const fragment = graphql`
  fragment SectionRugListRugContainerFragment on CMS_Rug {
    award
    extra
    id
    name
    collection_jk {
      collectionPage {
        slug
      }
    }
    picture {
      ...RugListPictureFragment
    }
  }
`;

type Props = {
  isCritical: boolean;
  rug: SectionRugListRugContainerFragment;
  textColor: string;
};

const SectionRugListRugContainer = ({ isCritical, rug, textColor }: Props) => {
  const picture = idx(rug, _ => _.picture.file.childImageSharp.fluid);
  const slug = idx(rug, _ => _.collection_jk.collectionPage.slug);

  if (!picture) {
    return null;
  }

  const rugName = buildRugName({ extra: rug.extra, name: rug.name });
  const url = !!slug ? buildCollectionOverviewRugLink({ rugId: rug.id, slug }) : null;

  return (
    <CollectionPageSectionRugListRug
      award={rug.award}
      isCritical={isCritical}
      name={rugName}
      picture={picture}
      textColor={textColor}
      url={url}
    />
  );
};

export default SectionRugListRugContainer;
