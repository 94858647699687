import React from 'react';

import theme from '../../../utils/styling/theme';

// Types

type Props = {
  className?: string;
};

const MapPin = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 90 110" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.54 6.93h77.81V75.8H6.54z" fill={theme.color.white} />

      <path
        d="M34.07 22.79l-2.95 10.57h6.23l-3-10.57zm4.68 25.48l-3 10.57H42l-3-10.57zM0 0v89.65h23.41V110l16.52-20.35H90V0zm64.38 27.31h9.82v9.81h-9.82zM13.86 39.38l1.71-1.1a3.69 3.69 0 0 0 3.07 1.81c1.39 0 2.5-.68 2.5-3V21.2h2.27v15.25c0 3.87-1.65 5.57-4.72 5.57a5.9 5.9 0 0 1-4.83-2.64zM27 67.2l-6.13-10.91-3.33 4.05v6.86h-2.3V46.69h2.28v10.65h.06l8.51-10.65h2.7l-6.35 7.73 7.12 12.78zm1.6-25.49h-2.38l6.31-20.51h3.38l6.31 20.51h-2.39l-1.9-6.42h-7.39zM44.46 67.2l-1.9-6.42h-7.35l-1.9 6.42H30.9l6.3-20.51h3.39L46.9 67.2zm15.12-18.58h-6.14V67.2h-2.27V48.62H45v-1.91h14.58zm.06-6.91h-1.91l-10.36-18.3h-.06v18.3h-2.05V21.2h3.27L57.48 37h.11V21.2h2zM76.22 67.2H74v-9.77h-9.8v9.77h-2.3V46.69h2.3v8.81H74v-8.81h2.23z"
        fill={theme.color.green}
        id="logo"
      />
    </svg>
  );
};

export default MapPin;
