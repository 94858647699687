import React from 'react';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import SectionItemContentLogo from '../../../../components/Section/SectionItems/SectionItemContentLogo';
import { getLogo } from '../../utils';

// Types
import { SectionItemContentLogoContainerFragment } from './__generated__/SectionItemContentLogoContainerFragment';
import { SectionWidth } from '../../../../../types/sections';

export const fragment = graphql`
  fragment SectionItemContentLogoContainerFragment on CMS_Section {
    logo {
      ...SectionLogoPictureFragment
    }
  }
`;

type Props = {
  contentPositionY: string;
  isCritical: boolean;
  isSectionContentExtraWide: boolean;
  section: SectionItemContentLogoContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionItemContentLogoContainer = ({ contentPositionY, isCritical, isSectionContentExtraWide, section, sectionWidth }: Props) => {
  const [ref, isInView] = useInView({ threshold: 0, triggerOnce: true });

  const logo = getLogo(section);

  if (!logo) {
    return null;
  }

  return (
    <SectionItemContentLogo
      contentPositionY={contentPositionY}
      isCritical={isCritical}
      isInView={isInView}
      isSectionContentExtraWide={isSectionContentExtraWide}
      logo={logo}
      ref={ref}
      sectionWidth={sectionWidth}
    />
  );
};

export default SectionItemContentLogoContainer;
