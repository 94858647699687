import styled from '../../../../../utils/styling/styled';

import styleUtils from '../../../../../utils/styling/styleUtils';

export const RegionName = styled.span`
  ${styleUtils.font.weight.bold};
  ${styleUtils.font.shadow.default};
  ${styleUtils.margin.vertical.bottomHalf};

  color: ${props => props.theme.color.greyHalfLight};
  text-transform: uppercase;
`;

export const StoreLists = styled.div`
  /* Flex Container */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

type WrapperProps = {
  isVisible: boolean;
};

export const Wrapper = styled.div<WrapperProps>`
  ${styleUtils.padding.horizontal.bothHalf};

  /* Flex Container */
  display: flex;
  flex-direction: column;

  border-left: 1px solid ${props => props.theme.color.white40};
  bottom: 100%;
  left: 0;
  opacity: ${props => (!props.isVisible ? 0 : 1)};
  pointer-events: ${props => (!props.isVisible ? 'none' : 'all')};
  position: absolute;
  transition: opacity 300ms ease-in-out;
  z-index: 2;
`;
