import React from 'react';

import Heading from '../../../Heading';
import removeNewlines from '../../../../utils/string/removeNewlines';
import splitNewlineToBreak from '../../../../utils/string/splitNewlineToBreak';

import * as S from './styles';

// Types

type Props = {
  contentPositionY: string;
  hasBackgroundImage: boolean;
  text: string;
  textAlign: string;
  textColor: string;
};

const SectionItemContactFormHeadline = ({ contentPositionY, hasBackgroundImage, text, textAlign, textColor }: Props) => {
  const splitText = splitNewlineToBreak(text, textAlign === 'end');
  const textWithoutNewlines = removeNewlines(text);

  return (
    <S.Wrapper contentPositionY={contentPositionY}>
      {!!textWithoutNewlines && <Heading isVisuallyHidden={true}>{textWithoutNewlines}</Heading>}

      <S.Headline aria-hidden={!!textWithoutNewlines} hasBackgroundImage={hasBackgroundImage} textAlign={textAlign} textColor={textColor}>
        {splitText}
      </S.Headline>
    </S.Wrapper>
  );
};

export default SectionItemContactFormHeadline;
