import React from 'react';

import * as S from './styles';

import worldMap from './assets/world_map.png';

// Types
import { ReactNode } from 'react';

type Props = {
  australia: ReactNode;
  eastAsia: ReactNode;
  easternEurope: ReactNode;
  hasActiveRegion: boolean;
  middleEast: ReactNode;
  northAmerica: ReactNode;
  southAmerica: ReactNode;
  westernEurope: ReactNode;
};

const GlobalDistributionWorldMap = ({
  australia,
  eastAsia,
  easternEurope,
  hasActiveRegion,
  middleEast,
  northAmerica,
  southAmerica,
  westernEurope,
}: Props) => {
  return (
    <S.WrapperWrapper>
      <S.Wrapper>
        {/* TODO: Asset Prefix */}
        <S.WorldMap alt="World Map" src={worldMap} hasActiveRegion={hasActiveRegion} />

        {/* Order Matters */}
        {australia}
        {eastAsia}
        {middleEast}
        {easternEurope}
        {westernEurope}
        {southAmerica}
        {northAmerica}
      </S.Wrapper>
    </S.WrapperWrapper>
  );
};

export default GlobalDistributionWorldMap;
