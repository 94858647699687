import styled from '../../../../utils/styling/styled';

import Link from '../../../Link';
import styleUtils from '../../../../utils/styling/styleUtils';
import { setTextShadow } from '../../utils';

// Types
import { SectionWidth } from '../../../../../types/sections';

const decideWidth = (props: WrapperProps) => {
  switch (props.sectionWidth) {
    case 25:
      return '80%';

    case 50:
      return '100%';

    case 75:
      if (props.isSectionContentExtraWide) {
        return '50%';
      }

      return '30%';

    case 100:
      if (props.isSectionContentExtraWide) {
        return '40%';
      }

      return '30%';
  }
};

type SectionWrapperProps = {
  hasBackgroundImage: boolean;
  textColor: string;
};

export const SectionWrapper = styled.div<SectionWrapperProps>`
  ${setTextShadow};
  ${styleUtils.margin.vertical.topHalf};

  color: ${props => props.textColor};
  font-size: 0.9rem;
`;

type DateWrapperProps = {
  hasBackgroundImage: boolean;
  textColor: string;
};

export const DateWrapper = styled.div<DateWrapperProps>`
  ${setTextShadow};
  ${styleUtils.margin.vertical.bottomHalf};

  color: ${props => props.textColor};
  font-size: 0.9rem;
`;

export const ContactRow = styled.p``;

export const ContactRowLink = styled(Link)`
  ${styleUtils.animation.navigationListItem};

  text-transform: lowercase;
`;

type AddressProps = {
  hasBackgroundImage: boolean;
  textAlign: string;
  textColor: string;
};

export const Address = styled.p<AddressProps>`
  ${setTextShadow};

  color: ${props => props.textColor};
  font-size: 1rem;
  line-height: 1.3;
  text-align: ${props => props.textAlign};
  white-space: pre-wrap;
`;

type WrapperProps = {
  contentPositionY: string;
  isSectionContentExtraWide: boolean;
  sectionWidth: SectionWidth;
};

export const Wrapper = styled.address<WrapperProps>`
  ${styleUtils.margin.vertical.top};
  ${styleUtils.misc.frame};

  z-index: 0;

  ${styleUtils.media.orientation.landscape} {
    width: ${decideWidth};
  }

  ${styleUtils.media.orientation.portrait} {
    width: 100%;
  }
`;
