import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';

export const Textarea = styled.textarea`
  ${styleUtils.margin.vertical.topQuarter};
  ${styleUtils.padding.horizontal.bothQuarter};
  ${styleUtils.padding.vertical.bothQuarter};
  ${styleUtils.padding.vertical.bottomHalf};

  &::placeholder {
    color: ${props => props.theme.color.black40};
  }

  border-right: 0;
  border: 1px solid ${props => props.theme.color.black40};
  color: black;
  outline-color: ${props => props.theme.color.brand};
  width: 100%;
`;

export const Form = styled.form`
  /* Flex Container */
  display: flex;
  flex-direction: column;
`;

export const Margin = styled.div`
  left: 1px;
`;

export const StyledLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

export const Wrapper = styled.div`
  /* Flex Container */
  display: flex;
  flex-direction: column;

  pointer-events: all;
  position: relative;

  ${styleUtils.media.orientation.landscape} {
    width: 100%;
  }
`;
