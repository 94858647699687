import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';

// Types
import { CMS_ENUM_STORE_TYPE } from '../../../../types/global';
import { StoreWithDistance } from './SectionStoreLocator';
import { SectionStoreLocatorContainerQuery_cms_stores } from '../../../containers/Section/SectionStoreLocator/__generated__/SectionStoreLocatorContainerQuery';

const DISTANCE_CUTOFF_IN_METERS = 500000;
const PREFFERED_DISTANCE_THRESHOLD_IN_METERS = 200000;

export const sortStores = (stores: StoreWithDistance[], country: string | null) => {
  const sortedStoresWithoutPreference = stores.sort((storeA, storeB) => storeA.distance - storeB.distance);

  const nextSortedStores = sortedStoresWithoutPreference.sort((storeA, storeB) => {
    if (notNullOrUndefined(country)) {
      if (notNullOrUndefined(storeA.country)) {
        // Kanada: Immer JKD Vancouver oben anzeigen
        if (country === 'Canada') {
          if (storeA.nameDisplay.includes('Vancouver')) {
            return -1;
          }

          if (storeB.nameDisplay.includes('Vancouver')) {
            return 1;
          }
        }

        // USA --> Immer JKD New York oben anzeigen
        if (country === 'United States') {
          if (storeA.nameDisplay.includes('New York')) {
            return -1;
          }

          if (storeB.nameDisplay.includes('New York')) {
            return 1;
          }
        }
      }
    }

    if (storeA.type === storeB.type) {
      return 0;
    }

    // wenn JK Showroom in Umkreis von 200km um den Suchpunkt, dann diese(n) oben anzeigen
    if (storeA.type === CMS_ENUM_STORE_TYPE.SHOWROOM_OR_STORE && storeA.distance < PREFFERED_DISTANCE_THRESHOLD_IN_METERS) {
      return -1;
    }

    // wenn JK Showroom in Umkreis von 200km um den Suchpunkt, dann diese(n) oben anzeigen
    if (storeB.type === CMS_ENUM_STORE_TYPE.SHOWROOM_OR_STORE && storeB.distance < PREFFERED_DISTANCE_THRESHOLD_IN_METERS) {
      return 1;
    }

    return 0;
  });

  const filteredStores = nextSortedStores.filter(store => {
    // Alle Verkaufspunkte im Umkreis von 500 km um den Suchpunkt
    if (store.distance <= DISTANCE_CUTOFF_IN_METERS) {
      return true;
    }

    if (notNullOrUndefined(country)) {
      // Zusätzlich alle Verkaufspunkte aus dem Land des Suchpunktes
      if (country === store.country) {
        return true;
      }
    }

    return false;
  });

  // Es gibt keinen Verkaufspunkt im Land --> Immer als erstes JKD Bochum anzeigen
  if (filteredStores.every(store => store.country !== country)) {
    const bochum = stores.find(store => store.nameDisplay.includes('Bochum'));

    if (notNullOrUndefined(bochum)) {
      filteredStores.unshift(bochum);
    }
  }

  return { filteredStores, nextSortedStores };
};

export const getCountry = (result: google.maps.GeocoderResult) => {
  const filteredComponents = result.address_components.filter(
    component => component.types.includes('country') && component.types.includes('political'),
  );

  if (filteredComponents.length === 0) {
    return null;
  }

  const component = filteredComponents[0];

  if (!notNullOrUndefined(component)) {
    return null;
  }

  const country = component.long_name;

  if (!notNullOrUndefined(country)) {
    return null;
  }

  return country;
};

export const getOnlyShowrooms = (stores: SectionStoreLocatorContainerQuery_cms_stores[]) => {
  return stores
    .filter(store => store.type === CMS_ENUM_STORE_TYPE.SHOWROOM_OR_STORE)
    .sort((storeA, storeB) => {
      if (storeA.nameDisplay.includes('Bochum')) {
        return -1;
      }

      if (storeB.nameDisplay.includes('Bochum')) {
        return 1;
      }

      return 0;
    });
};
