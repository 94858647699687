import React from 'react';
import { graphql } from 'gatsby';

import SectionFullScreenWalkthrough from '../../../components/Section/SectionFullScreenWalkthrough';

// Types
import { SectionFullScreenWalkthroughContainerFragment } from './__generated__/SectionFullScreenWalkthroughContainerFragment';

export const fragment = graphql`
  fragment SectionFullScreenWalkthroughContainerFragment on CMS_Section {
    headline
  }
`;

type Props = {
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  section: SectionFullScreenWalkthroughContainerFragment;
};

const SectionFullScreenWalkthroughContainer = ({ isHiddenOnMobile, isNeighborClipped }: Props) => {
  return <SectionFullScreenWalkthrough isHiddenOnMobile={isHiddenOnMobile} isNeighborClipped={isNeighborClipped} />;
};

export default SectionFullScreenWalkthroughContainer;
