import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import SectionVideoFullScreenVimeo from '../../../components/Section/SectionVideoFullScreenVimeo';
import { getVideoPoster } from '../utils';

// Types
import { SectionVideoFullScreenVimeoContainerFragment } from './__generated__/SectionVideoFullScreenVimeoContainerFragment';

export const fragment = graphql`
  fragment SectionVideoFullScreenVimeoContainerFragment on CMS_Section {
    headline
    headlineColor
    videoPoster {
      ...SectionBackgroundImageFragment
    }
    vimeoVideoId
  }
`;

type Props = {
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  section: SectionVideoFullScreenVimeoContainerFragment;
};

const SectionVideoFullScreenVimeoContainer = ({ isHiddenOnMobile, isNeighborClipped, section }: Props) => {
  const textColor = section.headlineColor;
  const title = section.headline || null;
  const videoPoster = getVideoPoster(section);
  const videoVimeoId = idx(section, _ => _.vimeoVideoId);

  if (!videoVimeoId) {
    return null;
  }

  return (
    <SectionVideoFullScreenVimeo
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      textColor={textColor}
      title={title}
      videoPoster={videoPoster}
      videoVimeoId={videoVimeoId}
    />
  );
};

export default SectionVideoFullScreenVimeoContainer;
