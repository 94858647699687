import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import GlobalDistributionOverlayStoreList from '../../../../../components/Section/SectionGlobalDistribution/GlobalDistributionItems/GlobalDistributionOverlay/OverlayStoreList';
import GlobalDistributionOverlay from '../../../../../components/Section/SectionGlobalDistribution/GlobalDistributionItems/GlobalDistributionOverlay';
import GlobalDistributionOverlayStoreListStore from '../../../../../components/Section/SectionGlobalDistribution/GlobalDistributionItems/GlobalDistributionOverlay/OverlayStoreList/OverlayStoreListStore';
import GlobalDistributionRegionWesternEurope from '../../../../../components/Section/SectionGlobalDistribution/GlobalDistributionRegions/GlobalDistributionRegionWesternEurope';
import notNullOrUndefined from '../../../../../utils/misc/notNullOrUndefined';
import { getShowroomPageUrl } from '../utils';

// Types
import { CMS_ENUM_SHOWROOM_REGION, CMS_ENUM_SHOWROOM_TYPE } from '../../../../../../types/global';
import { GlobalDistributionRegionWesternEuropeContainerQuery } from './__generated__/GlobalDistributionRegionWesternEuropeContainerQuery';

type Props = {
  activeRegion: CMS_ENUM_SHOWROOM_REGION | null;
  headlineColor: string;
  onMouseEnter: (nextActiveRegion: CMS_ENUM_SHOWROOM_REGION) => void;
  onMouseLeave: () => void;
  textColor: string;
};

const GlobalDistributionRegionWesternEuropeContainer = ({ activeRegion, headlineColor, onMouseEnter, onMouseLeave, textColor }: Props) => {
  const data = useStaticQuery<GlobalDistributionRegionWesternEuropeContainerQuery>(graphql`
    query GlobalDistributionRegionWesternEuropeContainerQuery {
      cms {
        showrooms(sort: "order") {
          id
          name
          region
          type
          showroomPage {
            slug
          }
        }
      }
    }
  `);

  if (!data.cms.showrooms) {
    throw Error('Something went wrong.');
  }

  const filteredShowrooms = data.cms.showrooms
    .filter(notNullOrUndefined)
    .filter(showroom => showroom.region === CMS_ENUM_SHOWROOM_REGION.WESTERN_EUROPE);

  const typeShowroom = filteredShowrooms
    .filter(showroom => showroom.type === CMS_ENUM_SHOWROOM_TYPE.SHOWROOM)
    .map(showroom => {
      const url = getShowroomPageUrl(showroom);

      return <GlobalDistributionOverlayStoreListStore key={showroom.id} name={showroom.name} textColor={headlineColor} url={url} />;
    });

  const typeStore = filteredShowrooms
    .filter(showroom => showroom.type === CMS_ENUM_SHOWROOM_TYPE.STORE)
    .map(showroom => {
      const url = getShowroomPageUrl(showroom);

      return <GlobalDistributionOverlayStoreListStore key={showroom.id} name={showroom.name} textColor={headlineColor} url={url} />;
    });

  const typeExclusivePartner = filteredShowrooms
    .filter(showroom => showroom.type === CMS_ENUM_SHOWROOM_TYPE.EXCLUSIVE_PARTNER)
    .map(showroom => {
      const url = getShowroomPageUrl(showroom);

      return <GlobalDistributionOverlayStoreListStore key={showroom.id} name={showroom.name} textColor={headlineColor} url={url} />;
    });

  const showrooms = <GlobalDistributionOverlayStoreList stores={typeShowroom} storesType="Showrooms" textColor={textColor} />;
  const stores = <GlobalDistributionOverlayStoreList stores={typeStore} storesType="Stores" textColor={textColor} />;
  const exclusivePartners = (
    <GlobalDistributionOverlayStoreList stores={typeExclusivePartner} storesType="Exclusive Partners" textColor={textColor} />
  );

  const storeLists = (
    <>
      {typeShowroom.length > 0 && showrooms}
      {typeStore.length > 0 && stores}
      {typeExclusivePartner.length > 0 && exclusivePartners}
    </>
  );

  const overlay = (
    <GlobalDistributionOverlay
      isVisible={activeRegion === CMS_ENUM_SHOWROOM_REGION.WESTERN_EUROPE}
      regionName="Western Europe"
      storeLists={storeLists}
    />
  );

  return (
    <GlobalDistributionRegionWesternEurope
      hasActiveRegion={!!activeRegion}
      isActiveRegion={activeRegion === CMS_ENUM_SHOWROOM_REGION.WESTERN_EUROPE}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      overlay={overlay}
      pinColor={headlineColor}
    />
  );
};

// TODO: Store // Showroom. Naming.

export default GlobalDistributionRegionWesternEuropeContainer;
