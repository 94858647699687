import { isSameMonth as dfIsSameMonth, isSameDay as dfIsSameDay, isSameYear as dfIsSameYear, parseISO } from 'date-fns';

import getDateFullMonthFullYear from './getDateFullMonthFullYear';
import getDay from './getDay';
import getMonth from './getMonth';
import getYear from './getYear';

type FormatEventDateInput = {
  endDate: string;
  startDate: string;
};

const formatEventDate = ({ endDate, startDate }: FormatEventDateInput) => {
  const parsedEndDate = parseISO(endDate);
  const parsedStartDate = parseISO(startDate);

  const isSameDay = dfIsSameDay(parsedEndDate, parsedStartDate);
  const isSameMonth = dfIsSameMonth(parsedEndDate, parsedStartDate);
  const isSameYear = dfIsSameYear(parsedEndDate, parsedStartDate);

  if (isSameDay) {
    return getDateFullMonthFullYear(startDate);
  }

  if (isSameMonth) {
    const endDay = getDay(endDate);
    const month = getMonth(endDate);
    const startDay = getDay(startDate);
    const year = getYear(endDate);

    return `${startDay}-${endDay} ${month} ${year}`;
  }

  if (isSameYear) {
    const endDay = getDay(endDate);
    const endMonth = getMonth(endDate);
    const startDay = getDay(startDate);
    const startMonth = getMonth(startDate);
    const year = getYear(endDate);

    return `${startDay} ${startMonth} - ${endDay} ${endMonth} ${year}`;
  }

  const endDay = getDay(endDate);
  const endMonth = getMonth(endDate);
  const endYear = getYear(endDate);
  const startDay = getDay(startDate);
  const startMonth = getMonth(startDate);
  const startYear = getYear(startDate);

  return `${startDay} ${startMonth} ${startYear} - ${endDay} ${endMonth} ${endYear}`;
};

export default formatEventDate;
