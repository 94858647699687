import React from 'react';

import * as S from './styles';

// Types

type Props = {
  className?: string;
  name: string;
  textColor: string;
  url: string | null;
};

const GlobalDistributionOverlayStoreListStore = ({ className, name, textColor, url }: Props) => {
  if (!url) {
    return (
      <S.Store className={className}>
        <S.Name textColor={textColor}>{name}</S.Name>
      </S.Store>
    );
  }

  return (
    <S.Store className={className}>
      <S.NameLink textColor={textColor} url={url}>
        {name} {'›'}
      </S.NameLink>
    </S.Store>
  );
};

export default GlobalDistributionOverlayStoreListStore;
