import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import SectionItemMap from '../../../../components/Section/SectionItems/SectionItemMap';

// Types
import { SectionItemShowroomMapContainerFragment } from './__generated__/SectionItemShowroomMapContainerFragment';

export const fragment = graphql`
  fragment SectionItemShowroomMapContainerFragment on CMS_Section {
    showroomPage {
      showroom {
        lat
        lng
      }
    }
  }
`;

type Props = {
  section: SectionItemShowroomMapContainerFragment;
};

const SectionItemShowroomMapContainer = ({ section }: Props) => {
  const lat = idx(section, _ => _.showroomPage.showroom.lat);
  const lng = idx(section, _ => _.showroomPage.showroom.lng);

  if (typeof lat !== 'number' || typeof lng !== 'number') {
    return null;
  }

  return <SectionItemMap lat={lat} lng={lng} />;
};

export default SectionItemShowroomMapContainer;
