import React from 'react';
import { graphql } from 'gatsby';

import SectionItemContentBackgroundContainer from '../SectionItems/SectionItemContentBackground';
import SectionItemContentHeadlineContainer from '../SectionItems/SectionItemContentHeadline';
import SectionItemContentTextContainer from '../SectionItems/SectionItemContentText';
import SectionRugFullHeight from '../../../components/Section/SectionRugFullHeight';
import SectionRugFullHeightRugContainer from './SectionRugFullHeightRug';
import SectionRugListRugContainer from '../SectionRugList/SectionRugListRug';
import { getBackgroundImage, normalizeContentPositionX, normalizeContentPositionY } from '../utils';

// Types
import { SectionRugFullHeightContainerFragment } from './__generated__/SectionRugFullHeightContainerFragment';

export const fragment = graphql`
  fragment SectionRugFullHeightContainerFragment on CMS_Section {
    ...SectionItemContentBackgroundContainerFragment
    ...SectionItemContentHeadlineContainerFragment
    ...SectionItemContentTextContainerFragment
    backgroundColor
    backgroundPattern
    contentPositionX
    contentPositionY
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
    fullHeightRug {
      ...SectionRugFullHeightRugContainerFragment
      id
      name
    }
    fullHeightRugPortrait: fullHeightRug {
      ...SectionRugListRugContainerFragment
      id
      name
    }
  }
`;

type Props = {
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  section: SectionRugFullHeightContainerFragment;
};

const SectionRugFullHeightContainer = ({ index, isClipped, isCritical, isHiddenOnMobile, isNeighborClipped, section }: Props) => {
  const backgroundColor = section.backgroundColor;
  const backgroundImage = getBackgroundImage(section);

  if (!section.fullHeightRug || !section.fullHeightRugPortrait) {
    return null;
  }

  const contentPositionX = normalizeContentPositionX(section.contentPositionX);
  const contentPositionY = normalizeContentPositionY(section.contentPositionY);

  const contentBackground = (
    <SectionItemContentBackgroundContainer section={section} sectionWidth={50} shouldRenderWithoutBackground={true} />
  );

  const headline = (
    <SectionItemContentHeadlineContainer contentPositionY={contentPositionY} hasBackgroundImage={!!backgroundImage} section={section} />
  );

  const text = (
    <SectionItemContentTextContainer
      contentPositionY={contentPositionY}
      hasBackgroundImage={!!backgroundImage}
      section={section}
      sectionWidth={50}
    />
  );

  const rug = (
    <SectionRugFullHeightRugContainer
      isClipped={isClipped}
      isCritical={isCritical}
      isNeighborClipped={isNeighborClipped}
      rug={section.fullHeightRug}
    />
  );

  const rugPortrait = (
    <SectionRugListRugContainer
      isCritical={isCritical}
      key={section.fullHeightRugPortrait.id}
      rug={section.fullHeightRugPortrait}
      textColor={section.textColor}
    />
  );

  return (
    <SectionRugFullHeight
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      contentBackground={contentBackground}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      headline={headline}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      rug={rug}
      rugPortrait={rugPortrait}
      text={text}
    />
  );
};

export default SectionRugFullHeightContainer;
