import React from 'react';

import * as S from './styles';

// Types
import { ReactNode } from 'react';

type Props = {
  events: ReactNode;
  isClipped: boolean;
  isNeighborClipped: boolean;
  textColor: string;
};

const SectionEventsEventsTable = ({ events, isClipped, isNeighborClipped, textColor }: Props) => {
  const eventsCount = React.Children.count(events);

  if (eventsCount === 0) {
    return (
      <S.NoEventsMessageWrapper>
        <S.NoEventsMessage textColor={textColor}>No upcoming events</S.NoEventsMessage>
      </S.NoEventsMessageWrapper>
    );
  }

  return (
    <S.Table isClipped={isClipped} isNeighborClipped={isNeighborClipped} textColor={textColor}>
      <S.TableHeader>
        <S.TableHeaderRow>
          <S.TableHeaderCell>Show</S.TableHeaderCell>
          <S.TableHeaderCell>City</S.TableHeaderCell>
          <S.TableHeaderCell>Country</S.TableHeaderCell>
          <S.TableHeaderCell>Address / Booth</S.TableHeaderCell>
          <S.TableHeaderCell>Date</S.TableHeaderCell>
        </S.TableHeaderRow>
      </S.TableHeader>

      <S.TableBody>{events}</S.TableBody>
    </S.Table>
  );
};

export default SectionEventsEventsTable;
