import React from 'react';

import splitNewlineToBreak from '../../../../utils/string/splitNewlineToBreak';
import { NO_SCROLL_ID } from '../../../../config/constants';

import * as S from './styles';

// Types

type Props = {
  address: string;
  className?: string;
  continent: string;
  country: string;
  email: string | null;
  isAppointmentOnly?: boolean;
  phone: string | null;
  website: string | null;
};

const SectionItemStoreLocatorAddress = ({ address, className, continent, country, email, phone, isAppointmentOnly, website }: Props) => {
  const splitAddress = splitNewlineToBreak(address);

  return (
    <S.Wrapper className={className} contentPositionY="left" id={NO_SCROLL_ID} isSectionContentExtraWide={false} sectionWidth={100}>
      <S.Address id={NO_SCROLL_ID}>
        <li id={NO_SCROLL_ID}>{splitAddress}</li>
        <li id={NO_SCROLL_ID}>{country}</li>
        <li id={NO_SCROLL_ID}>{continent}</li>
      </S.Address>

      <S.ContactWrapper id={NO_SCROLL_ID}>
        {!!phone && <li id={NO_SCROLL_ID}>T: {phone}</li>}

        {!!email && (
          <li id={NO_SCROLL_ID}>
            <S.ContactRowLink id={NO_SCROLL_ID} url={`mailto:${email}`}>
              {email}
            </S.ContactRowLink>
          </li>
        )}

        {!!website && (
          <li id={NO_SCROLL_ID}>
            <S.ContactRowLink id={NO_SCROLL_ID} target="_blank" url={website}>
              {website}
            </S.ContactRowLink>
          </li>
        )}
      </S.ContactWrapper>

      {isAppointmentOnly && <S.SectionWrapper id={NO_SCROLL_ID}>Appointments only</S.SectionWrapper>}
    </S.Wrapper>
  );
};

export default SectionItemStoreLocatorAddress;
