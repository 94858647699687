import React from 'react';
import Link from '../../../Link';

import * as S from './styles';
import { formatEventDate } from './utils';

type Props = {
  booth: string;
  city: string;
  country: string;
  endDate: string;
  name: string;
  startDate: string;
  website: string | null;
};

const SectionEventsEventsEventPortrait = ({ booth, city, country, endDate, name, startDate, website }: Props) => {
  const formattedEventDate = formatEventDate({ endDate, startDate });

  return (
    <S.ListItem>
      <S.Title>
        <Link asExternal={true} url={website}>
          {name}
        </Link>
      </S.Title>
      <br />
      {city}, {country}
      <br />
      {booth}
      <br />
      {formattedEventDate}
      <br />
    </S.ListItem>
  );
};

export default SectionEventsEventsEventPortrait;
