import React from 'react';
import idx from 'idx';
import { graphql, useStaticQuery } from 'gatsby';

import SectionStoreLocator from '../../../components/Section/SectionStoreLocator';
import notNullOrUndefined from '../../../utils/misc/notNullOrUndefined';

// Types
import { SectionStoreLocatorContainerFragment } from './__generated__/SectionStoreLocatorContainerFragment';
import { SectionStoreLocatorContainerQuery } from './__generated__/SectionStoreLocatorContainerQuery';

export const fragment = graphql`
  fragment SectionStoreLocatorContainerFragment on CMS_Section {
    pageName
  }
`;

type Props = {
  section: SectionStoreLocatorContainerFragment;
};

const SectionStoreLocatorContainer = (_: Props) => {
  const data = useStaticQuery<SectionStoreLocatorContainerQuery>(graphql`
    query SectionStoreLocatorContainerQuery {
      cms {
        stores(limit: 9999, sort: "type") {
          address
          appointmentOnly
          continent
          country
          email
          id
          lat
          lng
          name
          nameDisplay
          phone
          type
          website
        }
      }
    }
  `);

  const stores = (idx(data, x => x.cms.stores) || []).filter(notNullOrUndefined);

  return <SectionStoreLocator stores={stores} />;
};

export default SectionStoreLocatorContainer;
