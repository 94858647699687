import React from 'react';

import splitNewlineToBreak from '../../../../utils/string/splitNewlineToBreak';

import * as S from './styles';

// Types

import { SectionWidth } from '../../../../../types/sections';

type Props = {
  address: string;
  addressColor: string;
  cell: string | null;
  className?: string;
  contentPositionY: string;
  email: string | null;
  fax: string | null;
  hasBackgroundImage: boolean;
  hours: string | null;
  phone: string | null;
  sectionWidth: SectionWidth;
  shouldNotIncludeHours: boolean;
  textAlign: string;
  textColor: string;
};

const SectionItemShowroomAddress = ({
  address,
  addressColor,
  cell,
  className,
  contentPositionY,
  email,
  fax,
  hasBackgroundImage,
  hours,
  phone,
  sectionWidth,
  shouldNotIncludeHours,
  textAlign,
  textColor,
}: Props) => {
  const splitAddress = splitNewlineToBreak(address);
  const splitHours = !!hours ? splitNewlineToBreak(hours) : null;

  return (
    <S.Wrapper className={className} contentPositionY={contentPositionY} isSectionContentExtraWide={false} sectionWidth={sectionWidth}>
      <S.Address hasBackgroundImage={hasBackgroundImage} textAlign={textAlign} textColor={addressColor}>
        {splitAddress}
      </S.Address>

      <S.SectionWrapper hasBackgroundImage={hasBackgroundImage} textColor={textColor}>
        {!!cell && <S.ContactRow>C: {cell}</S.ContactRow>}

        {!!phone && <S.ContactRow>T: {phone}</S.ContactRow>}

        {!!fax && <S.ContactRow>F: {fax}</S.ContactRow>}

        {!!email && <S.ContactRowLink url={`mailto:${email}`}>{email}</S.ContactRowLink>}
      </S.SectionWrapper>

      {!shouldNotIncludeHours && !!splitHours && (
        <S.SectionWrapper hasBackgroundImage={hasBackgroundImage} textColor={textColor}>
          <S.ContactRow>Opening Hours:</S.ContactRow>
          <S.ContactRow>{splitHours}</S.ContactRow>
        </S.SectionWrapper>
      )}
    </S.Wrapper>
  );
};

export default SectionItemShowroomAddress;
