import React from 'react';

import * as S from './styles';

const WALKTHROUGH_URL = 'https://jankath.shapespark.com/jan_kath_bochum/';

type Props = {
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
};

const SectionFullScreenWalkthrough = ({ isHiddenOnMobile, isNeighborClipped }: Props) => {
  return (
    <S.Section
      contentPositionY="center"
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      isNotClipable={true}
      sectionWidth={100}
    >
      <S.Walkthrough
        allow="gyroscope; accelerometer; xr-spatial-tracking; vr;"
        allowFullScreen={true}
        scrolling="no"
        src={WALKTHROUGH_URL}
      />
    </S.Section>
  );
};

export default SectionFullScreenWalkthrough;
