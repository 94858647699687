import React from 'react';
import { graphql } from 'gatsby';

import SectionItemContactFormHeadlineContainer from '../SectionItems/SectionItemContactFormHeadline';
import SectionPressContact from '../../../components/Section/SectionPressContact';
import { getBackgroundImage, normalizeContentPositionX, normalizeContentPositionY } from '../utils';

// Types
import { SectionPressContactContainerFragment } from './__generated__/SectionPressContactContainerFragment';
import { SectionWidth } from '../../../../types/sections';

export const fragment = graphql`
  fragment SectionPressContactContainerFragment on CMS_Section {
    ...SectionItemContentHeadlineContainerFragment
    backgroundColor
    backgroundPattern
    contentPositionX
    contentPositionY
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
  }
`;

type Props = {
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  isSectionContentExtraWide: boolean;
  section: SectionPressContactContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionPressContactContainer = ({
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  section,
  sectionWidth,
}: Props) => {
  const backgroundImage = getBackgroundImage(section);

  const contentPositionX = normalizeContentPositionX(section.contentPositionX);
  const contentPositionY = normalizeContentPositionY(section.contentPositionY);

  const headline = (
    <SectionItemContactFormHeadlineContainer contentPositionY={contentPositionY} hasBackgroundImage={!!backgroundImage} section={section} />
  );

  return (
    <SectionPressContact
      backgroundColor={section.backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      headline={headline}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={sectionWidth}
    />
  );
};

export default SectionPressContactContainer;
