import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';
import { decidePaddingLeftLandscapeSmall, decidePaddingRightLandscape, removeTopMarginIfMultipleChildren } from '../utils';

// Types
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

export const Form = styled.form`
  /* Flex Container */
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
`;

type SectionProps = {
  backgroundColor: string | null;
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${decidePaddingLeftLandscapeSmall};
  ${decidePaddingRightLandscape};
  ${removeTopMarginIfMultipleChildren};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.none};
    ${styleUtils.padding.vertical.none};

    /* Flex Container */
    align-items: flex-start;
    flex-direction: row;
    justify-content: flex-start;
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.none};
    ${styleUtils.padding.vertical.none};

    /* Flex Container */
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;

    ${styleUtils.specificity.increaseSpecificity} {
      min-height: auto !important;
    }
  }
`;

export const StoreItem = styled.li`
  width: 100%;

  &::before {
    background-color: rgba(255, 255, 255, 0.6);
    content: '';
    display: block;
    height: 4px;
    position: relative;
    width: 120px;
    top: 16px;
  }

  &:last-child {
    &::after {
      background-color: rgba(255, 255, 255, 0.6);
      content: '';
      display: block;
      height: 4px;
      position: relative;
      width: 120px;
      top: 18px;
    }
  }
`;

export const StoreList = styled.ul`
  ${styleUtils.margin.vertical.top};

  /* Flex Container */
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  // background-color: red;
  max-height: 60vh;
  overflow: scroll;
  width: 100%;

  &::-webkit-scrollbar-track {
    background-color: ${props => props.theme.color.grey};
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar {
    background-color: ${props => props.theme.color.grey};
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${props => props.theme.color.black};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
    border-radius: 10px;
  }
`;

export const Wrapper = styled.div`
  /* Flex Container */
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;

  height: 100%;
  width: 100%;
`;

export const FormWrapper = styled.div<{ isMobileVersion: boolean }>`
  flex-direction: column;
  width: 100%;

  ${styleUtils.padding.horizontal.both};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.vertical.topDouble};

    display: ${props => (props.isMobileVersion ? 'none' : 'flex')};
  }

  ${styleUtils.media.orientation.portrait} {
    ${props => (props.isMobileVersion ? styleUtils.padding.vertical.topQuadruple : styleUtils.padding.vertical.top)};
    ${props => (props.isMobileVersion ? styleUtils.padding.vertical.bottomDouble : styleUtils.padding.vertical.bottom)};
    display: ${props => (props.isMobileVersion ? 'flex' : 'none')};
  }
`;

export const Headline = styled.h3`
  ${styleUtils.font.weight.bold};

  color: #00ff00;
  line-height: 1;
  text-align: start;
  text-transform: uppercase;
  white-space: pre-wrap;
  z-index: 3;

  font-size: 3rem;

  ${styleUtils.media.desktop.small} {
    font-size: 4rem;
  }

  ${styleUtils.media.orientation.portrait} {
    width: 100%;
  }
`;

export const ListingsWrapper = styled.div<{ isMobileVersion: boolean }>`
  z-index: 1;
  ${styleUtils.padding.vertical.topDouble};

  height: 100%;
  width: 30%;

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.vertical.none};

    height: 50%;
    width: 100%;
    order: 2;
  }
`;

export const MapWrapper = styled.div`
  height: 100%;
  z-index: 1;

  width: 70%;

  ${styleUtils.media.orientation.portrait} {
    height: 30vh;
    width: 100%;
    order: 1;
  }
`;

export const ListWrapper = styled.div`
  ${styleUtils.padding.horizontal.both};
`;
