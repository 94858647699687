import React from 'react';

import * as S from './styles';

// Types
import { HTMLAttributes, ReactNode } from 'react';

type Props = {
  hasActiveRegion: boolean;
  isActiveRegion: boolean;
  overlay: ReactNode;
  pinColor: string;
} & HTMLAttributes<HTMLDivElement>;

const GlobalDistributionPin = ({ className, hasActiveRegion, isActiveRegion, overlay, pinColor, ...rest }: Props) => {
  return (
    <S.Wrapper className={className} hasActiveRegion={hasActiveRegion} isActiveRegion={isActiveRegion} {...rest}>
      {overlay}

      <S.Pin pinColor={pinColor} />
    </S.Wrapper>
  );
};

export default GlobalDistributionPin;
