import React from 'react';
import { graphql } from 'gatsby';

import SectionItemContentHeadlineContainer from '../SectionItems/SectionItemContentHeadline';
import SectionItemShowroomMapContainer from '../SectionItems/SectionItemShowroomMap';
import SectionShowroomMap from '../../../components/Section/SectionShowroomMap';
import SectionShowroomMapAddressContainer from './SectionShowroomMapAddress';
import { getBackgroundImage } from '../utils';

// Types
import { SectionShowroomMapContainerFragment } from './__generated__/SectionShowroomMapContainerFragment';
import { SectionWidth } from '../../../../types/sections';

export const fragment = graphql`
  fragment SectionShowroomMapContainerFragment on CMS_Section {
    ...SectionItemContentHeadlineContainerFragment
    ...SectionItemShowroomMapContainerFragment
    ...SectionShowroomMapAddressContainerFragment
    backgroundColor
    backgroundPattern
    contentPositionX
    contentPositionY
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
  }
`;

type Props = {
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  section: SectionShowroomMapContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionShowroomMapContainer = ({ isClipped, isCritical, isHiddenOnMobile, isNeighborClipped, section, sectionWidth }: Props) => {
  const backgroundImage = getBackgroundImage(section);

  const contentPositionX = 'flex-start';
  const contentPositionY = 'flex-start';
  const textAlign = 'start';

  const address = (
    <SectionShowroomMapAddressContainer
      contentPositionY={contentPositionY}
      hasBackgroundImage={!!backgroundImage}
      section={section}
      textAlign={textAlign}
    />
  );

  const headline = (
    <SectionItemContentHeadlineContainer
      contentPositionY={contentPositionY}
      hasBackgroundImage={!!backgroundImage}
      section={section}
      textAlign={textAlign}
    />
  );

  const map = <SectionItemShowroomMapContainer section={section} />;

  return (
    <SectionShowroomMap
      address={address}
      backgroundColor={section.backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      headline={headline}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      map={map}
      sectionWidth={sectionWidth}
    />
  );
};

export default SectionShowroomMapContainer;
