import styled from '../../../utils/styling/styled';

import ScrollSection from '../../ScrollSection';
import styleUtils from '../../../utils/styling/styleUtils';
import { CLIPPED_ANGLE } from '../../../config/constants';

type SectionProps = {
  backgroundColor: string | null;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${styleUtils.padding.vertical.both};

  /* Flex Container */
  align-items: center;

  ${styleUtils.media.orientation.portrait} {
    /* Flex Container */
    flex-direction: column;

    min-height: auto;
    overflow: hidden;
  }

  ${styleUtils.media.orientation.landscape} {
    /* Flex Container */
    flex-direction: row;
    justify-content: center;

    padding-left: ${props => (props.isClipped ? CLIPPED_ANGLE * 2 : CLIPPED_ANGLE)}rem;
    padding-right: ${props => (props.isNeighborClipped ? CLIPPED_ANGLE * 2 : CLIPPED_ANGLE)}rem;

    width: auto !important;
    min-width: auto !important;
  }
`;
