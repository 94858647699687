import React from 'react';
import { faCircle, faPlus } from '@fortawesome/free-solid-svg-icons';

import VisuallyHiddenSpan from '../../../VisuallyHiddenSpan';

import * as S from './styles';

// Types
import { HTMLAttributes } from 'react';

type Props = {
  url: string;
} & HTMLAttributes<HTMLAnchorElement>;

const RugItemButton = ({ url, ...rest }: Props) => {
  return (
    <S.StyledLink url={url} {...rest}>
      <VisuallyHiddenSpan>Rug Details</VisuallyHiddenSpan>
      <span
        className="fa-layers fa-fw fa-2x"
        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}
      >
        <S.Circle icon={faCircle} />

        <S.Plus icon={faPlus} transform="shrink-8" />

        <S.Pulse />
      </span>
    </S.StyledLink>
  );
};

export default RugItemButton;
