import React from 'react';

import Heading from '../../Heading';

import * as S from './styles';

// Types
import { CMS_ENUM_SECTION_BACKGROUNDPATTERN } from '../../../../types/global';
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type Props = {
  backgroundColor: string | null;
  backgroundImage: FluidImage | null;
  backgroundPattern: CMS_ENUM_SECTION_BACKGROUNDPATTERN | null;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  logo: ReactNode;
  name?: string | null;
  sectionWidth: SectionWidth;
  textColor: string;
  url: string | null;
};

const SectionHomeCollection = ({
  backgroundColor,
  backgroundImage,
  backgroundPattern,
  contentPositionX,
  contentPositionY,
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  logo,
  name,
  sectionWidth,
  textColor,
  url,
}: Props) => {
  return (
    <S.Section
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      sectionWidth={sectionWidth}
    >
      {!!name && <Heading isVisuallyHidden={true}>{name}</Heading>}

      {logo}

      <S.ViewCollectionLink textColor={textColor} title={!!name ? `View ${name} collection` : `View details`} url={url}>
        {!!name && <S.ViewCollectionText>View Collection</S.ViewCollectionText>}

        {!name && <S.ViewCollectionText>View Details</S.ViewCollectionText>}

        <S.ViewCollectionChevron id="svg" />
      </S.ViewCollectionLink>
    </S.Section>
  );
};

export default SectionHomeCollection;
