import React from 'react';
import { graphql } from 'gatsby';

import SectionEventsEventsEvent from '../../../../components/Section/SectionEventsEvents/SectionEventsEventsEvent';
import SectionEventsEventsEventPortrait from '../../../../components/Section/SectionEventsEvents/SectionEventsEventsEventPortrait';

// Types
import { SectionEventsEventsEventContainerFragment } from './__generated__/SectionEventsEventsEventContainerFragment';

export const fragment = graphql`
  fragment SectionEventsEventsEventContainerFragment on CMS_Event {
    booth
    city
    country
    endDate
    name
    startDate
  }
`;

type Props = {
  event: SectionEventsEventsEventContainerFragment;
  isPortraitVariant?: boolean;
};

const SectionEventsEventsEventContainer = ({ event, isPortraitVariant }: Props) => {
  if (isPortraitVariant) {
    return (
      <SectionEventsEventsEventPortrait
        booth={event.booth}
        city={event.city}
        country={event.country}
        endDate={event.endDate}
        name={event.name}
        startDate={event.startDate}
      />
    );
  }

  return (
    <SectionEventsEventsEvent
      booth={event.booth}
      city={event.city}
      country={event.country}
      endDate={event.endDate}
      name={event.name}
      startDate={event.startDate}
    />
  );
};

export default SectionEventsEventsEventContainer;
