import styled from '../../../utils/styling/styled';

import Heading from '../../Heading';
import ScrollSection from '../../ScrollSection';
import createOmitProps from '../../../utils/styling/createOmitProps';
import styleUtils from '../../../utils/styling/styleUtils';
import { decidePaddingLeftLandscape, decidePaddingRightLandscape, removeTopMarginIfMultipleChildren } from '../utils';

// Types
import { FluidImage } from '../../MediaImage/GatsbyImageWrapperFluid/GatsbyImageWrapperFluid';
import { ReactNode } from 'react';
import { SectionWidth } from '../../../../types/sections';

type ContentProps = {
  textColor: string;
};

export const Content = styled.p<ContentProps>`
  ${styleUtils.font.weight.bold};
  ${styleUtils.margin.vertical.top};
  ${styleUtils.misc.frame};

  color: ${props => props.textColor};
  width: 25rem;
`;

type HeadlineProps = {
  textColor: string;
};

export const Headline = styled(Heading, { shouldForwardProp: createOmitProps(['textColor']) })<HeadlineProps>`
  ${styleUtils.font.weight.bold};

  color: ${props => props.textColor};
  line-height: 0.9;
  margin-right: 0.5rem;
  text-transform: uppercase;
  word-break: break-word;

  font-size: 4rem;

  ${styleUtils.media.tablet.small} {
    font-size: 6rem;
  }
  ${styleUtils.media.desktop.small} {
    font-size: 8rem;
  }
`;

type SectionProps = {
  backgroundImage: FluidImage | null;
  backgroundColor: string | null;
  children: ReactNode;
  contentPositionX: string;
  contentPositionY: string;
  index: number;
  isClipped?: boolean;
  isNeighborClipped?: boolean;
  sectionWidth: SectionWidth;
};

export const Section = styled(ScrollSection)<SectionProps>`
  ${decidePaddingRightLandscape};
  ${props => props.isClipped && decidePaddingLeftLandscape}
  ${removeTopMarginIfMultipleChildren};

  /* Flex Container */
  align-items: ${props => props.contentPositionX};
  flex-direction: column;
  justify-content: ${props => props.contentPositionY};

  ${styleUtils.media.orientation.landscape} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.bothQuadruple};
  }

  ${styleUtils.media.orientation.portrait} {
    ${styleUtils.padding.horizontal.both};
    ${styleUtils.padding.vertical.both};
  }
`;
