import React from 'react';
import idx from 'idx';
import { graphql } from 'gatsby';

import SectionHomeEventTeaser from '../../../components/Section/SectionHomeEventTeaser';
import SectionItemCollectionLogoContainer from '../SectionItems/SectionItemCollectionLogo';
import splitNewlineToBreak from '../../../utils/string/splitNewlineToBreak';
import { getBackgroundImage, normalizeContentPositionX, normalizeContentPositionY } from '../utils';

// Types
import { SectionHomeEventTeaserContainerFragment } from './__generated__/SectionHomeEventTeaserContainerFragment';
import { SectionWidth } from '../../../../types/sections';

export const fragment = graphql`
  fragment SectionHomeEventTeaserContainerFragment on CMS_Section {
    ...SectionItemCollectionLogoContainerFragment
    backgroundColor
    backgroundPattern
    contentPositionX
    contentPositionY
    headline
    text
    backgroundImage {
      ...SectionBackgroundImageFragment
    }
    contentPageLink {
      slug
    }
    headlineColor
    textColor
  }
`;

type Props = {
  index: number;
  isClipped: boolean;
  isCritical: boolean;
  isHiddenOnMobile: boolean;
  isNeighborClipped: boolean;
  isSectionContentExtraWide: boolean;
  section: SectionHomeEventTeaserContainerFragment;
  sectionWidth: SectionWidth;
};

const SectionHomeEventTeaserContainer = ({
  index,
  isClipped,
  isCritical,
  isHiddenOnMobile,
  isNeighborClipped,
  isSectionContentExtraWide,
  section,
  sectionWidth,
}: Props) => {
  const backgroundColor = section.backgroundColor;
  const backgroundImage = getBackgroundImage(section);
  const headline = section.headline;
  const text = section.text;

  if ((!backgroundColor && !backgroundImage) || !headline || !text) {
    return null;
  }

  const contentPageSlug = idx(section, _ => _.contentPageLink.slug) || null;
  const contentPositionX = normalizeContentPositionX(section.contentPositionX);
  const contentPositionY = normalizeContentPositionY(section.contentPositionY);
  const textColor = section.textColor;

  const splitHeadline = splitNewlineToBreak(headline);
  const splitText = splitNewlineToBreak(text);

  const logo = (
    <SectionItemCollectionLogoContainer
      alt={text}
      contentPositionY={contentPositionY}
      isCritical={isCritical}
      isSectionContentExtraWide={isSectionContentExtraWide}
      section={section}
      sectionWidth={sectionWidth}
    />
  );

  return (
    <SectionHomeEventTeaser
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      backgroundPattern={section.backgroundPattern}
      contentPositionX={contentPositionX}
      contentPositionY={contentPositionY}
      index={index}
      isClipped={isClipped}
      isCritical={isCritical}
      isHiddenOnMobile={isHiddenOnMobile}
      isNeighborClipped={isNeighborClipped}
      logo={logo}
      sectionWidth={sectionWidth}
      splitHeadline={splitHeadline}
      splitText={splitText}
      textColor={textColor}
      url={!!contentPageSlug ? `/${contentPageSlug}` : null}
    />
  );
};

export default SectionHomeEventTeaserContainer;
