import React from 'react';

import * as S from './styles';

type Props = {
  productLink: string | null;
  slug: string | null;
};

const SectionItemProductBuyButton = ({ productLink, slug }: Props) => {
  if (typeof productLink !== 'string' && typeof slug !== 'string') {
    return null;
  }

  if (typeof slug === 'string') {
    // @ts-ignore
    return <S.Button href={`/${slug}`}>More</S.Button>;
  }

  return (
    // @ts-ignore
    <S.Button href={productLink} target="_blank" rel="noopener noreferrer">
      Shop
    </S.Button>
  );
};

export default SectionItemProductBuyButton;
