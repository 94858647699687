import React from 'react';
import { Generic, JSONLD } from 'react-structured-data';

import { STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM } from './constants';

const HomePageBreadcrumb = () => {
  const schema = {
    itemListElement: [STRUCTURED_DATA_HOMEPAGE_BREADCRUMB_ITEM],
  };

  return (
    <JSONLD dangerouslyExposeHtml={true}>
      <Generic jsonldtype="BreadcrumbList" schema={schema} />
    </JSONLD>
  );
};

export default HomePageBreadcrumb;
