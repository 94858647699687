import React from 'react';

import splitNewlineToBreak from '../../../../utils/string/splitNewlineToBreak';

import * as S from './styles';

type Props = {
  hasBackgroundImage: boolean;
  text: string;
  textColor: string;
};

const SectionEventsEventsHeadline = ({ hasBackgroundImage, text, textColor }: Props) => {
  const splitText = splitNewlineToBreak(text);

  return (
    <S.Wrapper>
      <S.Headline hasBackgroundImage={hasBackgroundImage} textAlign="center" textColor={textColor}>
        {splitText}
      </S.Headline>
    </S.Wrapper>
  );
};

export default SectionEventsEventsHeadline;
