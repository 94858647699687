import styled from '../../../../utils/styling/styled';

import styleUtils from '../../../../utils/styling/styleUtils';
import { setTextShadow } from '../../utils';

// Types

type HeadlineProps = {
  hasBackgroundImage: boolean;
  textAlign: string;
  textColor: string;
};

export const Headline = styled.p<HeadlineProps>`
  ${setTextShadow};
  ${styleUtils.font.weight.bold};

  color: ${props => props.textColor};
  line-height: 1;
  text-transform: uppercase;
  white-space: pre-wrap;

  font-size: 2rem;
  text-align: start;
`;

type WrapperProps = {
  contentPositionY: string;
};

export const Wrapper = styled.div<WrapperProps>`
  z-index: 0;

  ${styleUtils.media.orientation.portrait} {
    order: -1;

    ${styleUtils.media.tablet.small} {
      order: 0;
    }
  }
`;
