import styled from '../../../../utils/styling/styled';

import Link from '../../../Link';
import styleUtils from '../../../../utils/styling/styleUtils';

// Types
import { SectionWidth } from '../../../../../types/sections';

export const SectionWrapper = styled.div`
  ${styleUtils.margin.vertical.topHalf};

  color: white;
  font-size: 0.9rem;
`;

export const ContactWrapper = styled.ul`
  ${styleUtils.margin.vertical.topHalf};

  color: white;
  font-size: 0.9rem;
`;

export const ContactRowLink = styled(Link)`
  ${styleUtils.animation.navigationListItem};

  text-transform: lowercase;
`;

export const Address = styled.ul`
  ${styleUtils.font.weight.bold};

  color: white;
  font-size: 1rem;
  line-height: 1.3;
  text-align: start;
  text-transform: uppercase;
  white-space: pre-wrap;
`;

type WrapperProps = {
  contentPositionY: string;
  isSectionContentExtraWide: boolean;
  sectionWidth: SectionWidth;
};

export const Wrapper = styled.address<WrapperProps>`
  // ${styleUtils.margin.vertical.topHalf};

  position: relative;
  z-index: 1;

  ${styleUtils.media.orientation.landscape} {
    width: 100%;
  }

  ${styleUtils.media.orientation.portrait} {
    width: 100%;
  }
`;
